import { connect } from "react-redux";

import Review from "../components/Review";

import { getReviews, closeAlertReview } from '../actions/reviewActions';

const mapStateToProps = state => ({
    user: state.auth,
    reviewRed: state.reviewRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getReviews: (dispatch) =>
        dispatch(getReviews(dispatch)),
    closeAlertReview: (dispatch) =>
        dispatch(closeAlertReview(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
