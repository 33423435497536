import * as types from "../types/actionTypes";

const initialState = {
    shop: {},
    stripe_account_link: null,
    errors: {},
    load_subscription: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SHOP:
            return {
                ...state,
                shop: action.payload[0],
                stripe_account_link: null,
                errors: false,
                load_subscription: false
            };
        case types.ADD_SUBSCRIPTION:
            return {
                ...state,
                stripe_account_link: action.payload.stripe_account_link,
                load_subscription: false
            };
        case types.FAILED_ADD_SUBSCRIPTION:
            return {
                ...state,
                load_subscription: false
            };
        case types.LOAD_SUBSCRIPTION:
            return {
                ...state,
                load_subscription: true
            };
        default:
          return state;
  }
};