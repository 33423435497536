import { connect } from "react-redux";

import ShopDeliveryOption from "../components/ShopDeliveryOption";

import { getShopDeliveryOptions, addShopDeliveryOption, closeAlertShopDeliveryOption } from '../actions/shopDeliveryOptionActions';

const mapStateToProps = state => ({
    user: state.auth,
    shopDeliveryOptionRed: state.shopDeliveryOptionRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShopDeliveryOptions: (dispatch) =>
        dispatch(getShopDeliveryOptions(dispatch)),
    addShopDeliveryOption: (item, dispatch) =>
        dispatch(addShopDeliveryOption(item, dispatch)),
    closeAlertShopDeliveryOption: (dispatch) =>
        dispatch(closeAlertShopDeliveryOption(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopDeliveryOption);
