import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getProfile(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/profile/`)
            .then((res) => { return dispatch({ type: types.GET_PROFILE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_PROFILE, payload: error.response.data })})
    };
}

function addProfile(item, dispatch) {
    dispatch({ type: types.LOADING_PROFILE })
    return async function () {
        axiosAPI
            .put(`${url}/profile/${item.id}/`, item)
            .then((res) => { return dispatch({ type: types.ADD_PROFILE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_PROFILE, payload: error.response.data }) })
    };
}

function closeAlertProfile(dispatch) {
    return dispatch({ type: types.CLOSE_ALERT_PROFILE })
}

export {
    getProfile,
    addProfile,
    closeAlertProfile
};
