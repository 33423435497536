import * as types from "../types/actionTypes";

const initialState = {
    conversations: [],
    consumers: [],
    message: [],
    errors: {},
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONVERSATIONS:
            return {
                ...state,
                conversations: action.payload,
                errors: {},
                refresh: false
            };
        case types.GET_MESSAGE:
            return {
                ...state,
                message: action.payload,
                errors: {},
                refresh: false
            };
        case types.ADD_MESSAGE:
            return {
                ...state,
                refresh: true
            };
        case types.FAILED_ADD_MESSAGE:
            return {
                ...state,
                errors: action.payload
            };
        case types.CLOSE_ALERT_MESSAGE:
            return {
                ...state,
                errors: {}
            };
        default:
          return state;
  }
};