import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getShop(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_subscription/`)
            .then((res) => { console.log('krnfoij'); return dispatch({ type: types.GET_SHOP, payload: res.data }) })
            .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_GET_SHOP, payload: error })})
    };
}

function addSubscription(shop_id, dispatch) {
    dispatch({ type: types.LOAD_SUBSCRIPTION })
    return async function () {
        axiosAPI
            .put(`${url}/shop_subscription/${shop_id}/`)
            .then((res) => { console.log('krnfoij'); return dispatch({ type: types.ADD_SUBSCRIPTION, payload: res.data }) })
            .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_ADD_SUBSCRIPTION, payload: error }) })
    };
}

export {
    getShop,
    addSubscription
};
