import * as types from "../../types/actionTypes";
import axios from "axios";
import axiosAPI from "../../components/api/axiosApi";

import { backendUrl } from "../backendUrl";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getTokenReset(data, dispatch) {
    dispatch({ type: types.CLEAN_RESET });
    dispatch({ type: types.LOADING_RESET });
    return async function () {
        axios
            .post(`${url}/passwordreset/`, data)
            .then((res) => { return dispatch({ type: types.GET_TOKEN_RESET, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_TOKEN_RESET, payload: error.response.data }) })
    };
}

function resetPassword(data, dispatch) {
    dispatch({ type: types.LOADING_RESET })
    return async function () {
        axios
            .post(`${url}/passwordreset/confirm/`, data)
            .then((res) => { return dispatch({ type: types.PASSWORD_RESET, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_PASSWORD_RESET, payload: error.response.data }) })
    };
}

export {
    getTokenReset,
    resetPassword
};
