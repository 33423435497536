import MUIDataTable from "mui-datatables";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const options = {
    filterType: 'dropdown',
    selectableRows: 'none',
    selectableRowsHeader: true,
    print: false,
    viewColumns: false,
    textLabels: {
        body: {
            noMatch: "Désolé, aucun résultat trouvé.",
            toolTip: "Trier",
            columnHeaderTooltip: column => `Trier selon ${column.label}`
        },
        pagination: {
            next: "Suivant",
            previous: "Précédent",
            rowsPerPage: "Lignes par page:",
            displayRows: "sur",
        },
        toolbar: {
            search: "Rechercher",
            downloadCsv: "Télécharger en CSV",
            print: "Imprimer",
            viewColumns: "Voir les colonnes",
            filterTable: "Filtrer la table",
        },
        filter: {
            all: "Tout",
            title: "Filtrer",
            reset: "Annuler",
        },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
        const alteredData = data.map((row, index) => ({
            index,
            data: row.data.map((field, idx) => {
                const mapper = columns[idx].toStringOverride
                return mapper ? mapper(field, data[index]) : field
                }),
            }))
        return "\uFEFF" + buildHead(columns) + buildBody(alteredData)
    }
  };

export default function MuiTable(props) {
    return (
        <MUIDataTable
            data={props.data}
            columns={props.columns}
            options={{...props.options, ...options, ...{downloadOptions: {filename: props.filename ? props.filename : 'Download.csv'}}}}
            title={props.title ? props.title : null}
        />
    )
}