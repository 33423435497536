import { connect } from "react-redux";

import ShopPartnerPage from "../components/ShopPartnerPage";

import {
    getShopPartner,
    getShopOption,
    addShopPartner,
    closeAlertShopPartner } from '../actions/shopPartnerActions';

const mapStateToProps = state => ({
    shopPartnerRed: state.shopPartnerRed,
    headerRed: state.headerRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShopPartner: (id, dispatch) =>
        dispatch(getShopPartner(id, dispatch)),
    addShopPartner: (item, dispatch) =>
        dispatch(addShopPartner(item, dispatch)),
    getShopOption: (dispatch) =>
        dispatch(getShopOption(dispatch)),
    closeAlertShopPartner: (dispatch) =>
        dispatch(closeAlertShopPartner(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPartnerPage);
