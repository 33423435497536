import { connect } from "react-redux";

import Terms from "../components/Terms";

import {
    getCGU
} from "../actions/termsActions.js";

const mapStateToProps = state => ({
    terms: state.terms,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getCGU: (dispatch) =>
        dispatch(getCGU(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
