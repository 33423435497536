// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginTop: 'auto',
          marginBottom: 'auto'
        },
        displayedRows: {
          marginTop: 'auto',
          marginBottom: 'auto'
        }, 
      },
    }
  }
}
