import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MuiTable from '../utils/MuiTable';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ShopPartnerPage(props) {

    const isInitialMount = React.useRef(true);
    const [shopPartner, setShopPartner] = React.useState();
    const [youAre, setYouAre] = React.useState(false);
    const [youAgreed, setYouAgreed] = React.useState(false);
    const [heIs, setHeIs] = React.useState(false);
    const [heAgreed, setHeAgreed] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        props.getShopOption(props.dispatch);
        props.getShopPartner(props.match.params.shop_partner_id, props.dispatch);
    }, []);

    React.useEffect(() => {
        if (props.shopPartnerRed.success_add === true) {
            props.history.push('/partner')
        } else if (props.shopPartnerRed.success_add === false) {
            if (props.shopPartnerRed.errors.null) {
                setOpen(true);
            }
        }
    }, [props.shopPartnerRed.success_add]);

    const handleCloseAlertShopPartner = () => {
        setOpen(false)
    }

    React.useEffect(() => {
        if (props.shopPartnerRed.partner.from_shop) {
            if (props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id) {
                setYouAre(props.shopPartnerRed.partner.from_accepted)
                setYouAgreed(props.shopPartnerRed.partner.from_agreed)
                setHeIs(props.shopPartnerRed.partner.to_accepted)
                setHeAgreed(props.shopPartnerRed.partner.to_agreed)
            } else {
                setYouAre(props.shopPartnerRed.partner.to_accepted)
                setYouAgreed(props.shopPartnerRed.partner.to_agreed)
                setHeIs(props.shopPartnerRed.partner.from_accepted)
                setHeAgreed(props.shopPartnerRed.partner.from_agreed)
            }
        }
    }, [props.shopPartnerRed.partner]);

    const handleShopPartner = () => {
        if (props.match.params.shop_partner_id === 'add') {
            var item = {
                'to_shop': (shopPartner ? shopPartner.id : null),
                'to_accepted': false,
                'from_accepted': youAre,
                'from_agreed': youAgreed
            }
        } else {
            if (props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id) {
                var item = {
                    'id': props.match.params.shop_partner_id,
                    'to_accepted': heIs,
                    'from_accepted': youAre,
                    'from_agreed': youAgreed,
                    'to_agreed': heAgreed
                }
            } else {
                var item = {
                    'id': props.match.params.shop_partner_id,
                    'from_accepted': heIs,
                    'to_accepted': youAre,
                    'to_agreed': youAgreed
                }
            }
        }
        props.addShopPartner(item, props.dispatch)
    }

    return (
        props.shopPartnerRed.fetching ?
            (<Box sx={{ width: '90%', pt: '100px', mx: 'auto' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
        (<Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="none" variant="h4" color="inherit" href="/partner" style={{ textDecoration: 'none' }}>
                        Partenaires
                        </Link>
                        {props.headerRed.shop.id && props.shopPartnerRed.partner.from_shop ?
                            <Typography variant="h4" color="text.primary">
                                {props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name}
                            </Typography> : null}
                        {props.match.params.shop_partner_id === 'add' ?
                            <Typography variant="h4" color="text.primary">
                                Nouveau partenaire
                            </Typography> : null}
                </Breadcrumbs>
                </Box>
                <Snackbar
                    autoHideDuration={6000}
                    onClose={handleCloseAlertShopPartner}
                    open={open}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertShopPartner}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertShopPartner} severity="error" sx={{ width: '100%', color: '#fff' }}>
                        {props.shopPartnerRed.errors.null ? props.shopPartnerRed.errors.null.message  : ''}
                    </Alert>
                </Snackbar>
                <Box sx={{
                    marginTop: 8,
                    mx: 'auto',
                    width: '90%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                >
                    {props.match.params.shop_partner_id === 'add' ?
                        <Grid container direction="row" alignItems="center">
                        
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            noOptionsText="Aucune boutique"
                                            fullWidth={true}
                                            onChange={(event, newValue) => setShopPartner(newValue)}
                                            getOptionLabel={(option) => option.name}
                                            options={props.shopPartnerRed.shop_options}
                                            renderInput={(params) => <TextField {...params} style={{ width: '100%' }} label="Boutique" error={props.shopPartnerRed.errors.to_shop ? true : false} helperText={props.shopPartnerRed.errors.to_shop ? props.shopPartnerRed.errors.to_shop.message : null} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid
                                            container
                                            direction="column"
                                            spacing={2}
                                            sx={{ mx: 'auto', my: 'auto', alignItems: 'center' }}
                                        >
                                            <FormGroup>
                                                <FormControlLabel control={<Switch checked={youAgreed} onChange={() => setYouAgreed(!youAgreed)} />} label={`Je souhaite être son point relais.`} />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                        </Grid> :
                        <Grid container direction="row">
                            {props.shopPartnerRed.partner.from_shop ?
                            <React.Fragment>
                                <Typography variant="body1" color="text.primary">
                                    {props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name} fait parti de vos partenaires. Vous pouvez modifier les modalités de votre partenariat ci-dessous.
                                </Typography>
                                <Grid item xs={12} mt={5}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography variant="h4" sx={{ textAlign: 'center' }}>Vous</Typography>
                                            <Paper sx={{ padding: 5, mr: 5, }} elevation={2}>
                                                <FormControlLabel
                                                    control={<Switch checked={youAre} onChange={() => setYouAre(!youAre)} />}
                                                    label={youAre ? `Vous avez accepté le partenariat avec ${props.shopPartnerRed.partner.from_shop && props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name}.` : `Vous n'avez pas encore accepté le partenariat avec ${props.shopPartnerRed.partner.from_shop && props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name}.`} />
                                                <FormControlLabel
                                                    control={<Checkbox disabled={!youAre} checked={youAgreed} onChange={() => setYouAgreed(!youAgreed)} />}
                                                    label="En cochant cette case, vous acceptez d'être un point relais de cette boutique" />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h4" sx={{ textAlign: 'center' }}>{props.shopPartnerRed.partner.from_shop && props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name}</Typography>
                                            <Paper sx={{ padding: 5, ml: 5, }} elevation={2}>
                                                <FormControlLabel
                                                    control={<Switch checked={heIs} disabled onChange={() => setHeIs(!heIs)} />}
                                                    label={heIs ? `${props.shopPartnerRed.partner.from_shop && props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name} a accepté le partenariat.` : `${props.shopPartnerRed.partner.from_shop && props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name} n'a pas encore accepté le partenariat.`} />
                                                <FormControlLabel
                                                    control={<Checkbox disabled checked={heAgreed} />}
                                                    label={heAgreed ? `${props.shopPartnerRed.partner.from_shop && props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name} a accepté de faire partie de vos points relais.` : `${props.shopPartnerRed.partner.from_shop && props.shopPartnerRed.partner.from_shop.id === props.headerRed.shop.id ? props.shopPartnerRed.partner.to_shop.name : props.shopPartnerRed.partner.from_shop.name} n'a pas encore accepté de faire partie de vos points relais.`} />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>  : null}
                        </Grid>
                        }
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            href='/partner'
                            variant="outlined"
                            disabled={props.shopPartnerRed.processing}
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={() => handleShopPartner()}
                            disabled={props.shopPartnerRed.processing}
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
        </Box>)
    );
}

