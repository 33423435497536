import * as types from "../types/actionTypes";
import axiosAPI from "../components/api/axiosApi";
import { backendUrl } from "./backendUrl";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function buildShopGetStripeKey(dispatch) {
    return function() {
        axiosAPI
            .get(`${url}/build_shop_stripe_key/`)
            .then((res) => { console.log(res); return dispatch({ type: types.GET_STRIPE_PUBLISHABLE_KEY , payload: res.data})})
            .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_GET_STRIPE_PUBLISHABLE_KEY, payload: error.response.data})})
  };
}


function buildShopStep1(item, dispatch) {
    dispatch({ type: types.BUILD_SHOP_STEP1 });
    dispatch({ type: types.LOADING_BUILD_SHOP });
    return function() {
        axiosAPI
            .post(`${url}/build_shop/`, item)
            .then((res) => { console.log(res); return dispatch({ type: types.BUILD_SHOP_SUCCESS_STEP1 , payload: res.data})})
            .catch((error) => { console.log(error); return dispatch({ type: types.BUILD_SHOP_FAILED_STEP1, payload: error.response.data})})
  };
}

function buildShopStep2(item, dispatch) {
    dispatch({ type: types.BUILD_SHOP_STEP2 });
    dispatch({ type: types.LOADING_BUILD_SHOP });
    return function () {
        axiosAPI
            .patch(`${url}/build_shop/${item.id}/`, item)
            .then((res) => { return dispatch({ type: types.BUILD_SHOP_SUCCESS_STEP2, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.BUILD_SHOP_FAILED_STEP2, payload: error.response.data }) })
    };
}

function getShop(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/build_shop/`)
            .then((res) => { return dispatch({ type: types.USER_SHOP, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.UNAUTHENTICATED }) })
    };
}

export {
    buildShopGetStripeKey,
    buildShopStep1,
    buildShopStep2,
    getShop
};
