import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getConversationNotifications(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/conversation_notif/`)
            .then((res) => { return dispatch({ type: types.GET_CONVERSATION_NOTIFICATIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CONVERSATION_NOTIFICATIONS, payload: error.response.data })})
    };
}

function getReviewNotifications(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/review_notif/`)
            .then((res) => { return dispatch({ type: types.GET_REVIEW_NOTIFICATIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_REVIEW_NOTIFICATIONS, payload: error.response.data }) })
    };
}

function getOrderNotifications(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/order_notif/`)
            .then((res) => { return dispatch({ type: types.GET_ORDER_NOTIFICATIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_ORDER_NOTIFICATIONS, payload: error }) })
    };
}

function handleDrawerState(state, dispatch) {
    if (state) {
        return dispatch({
            type: types.DRAWER_OPEN
        })
    } else {
        return dispatch({
            type: types.DRAWER_CLOSE
        })
    }
}

function getShop(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_HEADER, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_HEADER, payload: error.response.data }) })
    };
}

export {
    getConversationNotifications,
    getReviewNotifications,
    getOrderNotifications,
    handleDrawerState,
    getShop
};
