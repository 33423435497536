import React, { useState, useRef } from "react";
import Cropper from "react-easy-crop";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function ImageCropper({ image, onCropDone, onCropCancel, aspectRatio, cropSize }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const zoomRef = useRef(zoom);


    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const handleWheel = (e) => {
        e.preventDefault();
        let newZoom = zoomRef.current - e.deltaY * 0.001; // Ajustez ce facteur pour contrôler la vitesse de zoom
        if (newZoom < 1) newZoom = 1;
        if (newZoom > 3) newZoom = 3;
        setZoom(newZoom);
        zoomRef.current = newZoom;
    };


    return (
        <Box sx={{
            width: '90%', mx: 'auto'
        }}>
            <Box onWheel={handleWheel}>
                <Cropper
                    image={image}
                    aspect={aspectRatio}
                    crop={crop}
                    zoom={zoom}
                    cropSize={cropSize}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    style={{
                        containerStyle: {
                            my: 'auto',
                            backgroundColor: "#fff",
                        },
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    onClick={onCropCancel}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Retour
                </Button>
                <Button
                    onClick={() => {
                        onCropDone(croppedArea);
                    }}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                >
                    Valider
                </Button>
            </Box>
        </Box>
    );
}

export default ImageCropper;