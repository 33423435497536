import * as types from "../types/actionTypes";

const initialState = {
    shop: {},
    stripe_account_link: null,
    errors: {},
    load_account: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SHOP:
            return {
                ...state,
                shop: action.payload[0],
                stripe_account_link: null,
                errors: false,
                load_account: false
            };
        case types.ADD_ACCOUNT:
            return {
                ...state,
                stripe_account_link: action.payload.stripe_account_link,
                load_account: false
            };
        case types.FAILED_ADD_ACCOUNT:
            return {
                ...state,
                load_account: false
            };
        case types.LOAD_ACCOUNT:
            return {
                ...state,
                load_account: true
            };
        default:
          return state;
  }
};