import * as types from "../types/actionTypes";

const initialState = {
    stripeKey: '',
    errorsStep1: false,
    step1: false,
    successStep1: false,
    errorsStep2: false,
    step2: false,
    successStep2: false,
    shopCategoryOptions: {},
    shop: {},
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_STRIPE_PUBLISHABLE_KEY:
            return {
                ...state,
                stripeKey: action.payload.publishable_key,
            };
        case types.BUILD_SHOP_STEP1:
            return {
                ...state,
                errorsStep1: false,
                step1: false,
                successStep1: false,
                loading: false
            };
        case types.BUILD_SHOP_SUCCESS_STEP1:
            return {
                ...state,
                errorsStep1: false,
                shop: action.payload,
                step1: true,
                successStep1: true,
                loading: false
          };
        case types.BUILD_SHOP_FAILED_STEP1:
            return {
                ...state,
                errorsStep1: action.payload,
                step1: true,
                successStep1: false,
                loading: false
            };
        case types.BUILD_SHOP_STEP2:
            return {
                ...state,
                errorsStep2: false,
                step2: false,
                successStep2: false,
                loading: false
            };
        case types.BUILD_SHOP_SUCCESS_STEP2:
            return {
                ...state,
                errorsStep2: false,
                step2: true,
                successStep2: true,
                loading: false
            };
        case types.BUILD_SHOP_FAILED_STEP2:
            return {
                ...state,
                errorsStep2: action.payload,
                step2: true,
                successStep2: false,
                loading: false
            };
        case types.GET_SHOP_CATEGORY_OPTIONS:
            var options = {};
            action.payload.map((c) => options[c.id] = c.name)
            return {
                ...state,
                shopCategoryOptions: options,
                errorsStep1: {}
            };
        case types.USER_SHOP:
            if (action.payload[0]) {
                var shop_data = action.payload[0]
            } else {
                var shop_data = {}
            }
            return {
                ...state,
                shop: shop_data,
            };
        case types.LOADING_BUILD_SHOP:
            return {
                ...state,
                loading: true
            };
        default:
          return state;
  }
};