import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getShopDeliveryOptions(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_delivery_option/`)
            .then((res) => { console.log('krnfoij'); return dispatch({ type: types.GET_SHOP_DELIVERY_OPTIONS, payload: res.data }) })
            .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_GET_SHOP_DELIVERY_OPTIONS, payload: error.response.data })})
    };
}

function getShopDeliveryOption(id, dispatch) {
    dispatch({ type: types.FETCHING });
    return async function () {
        if (id !== 'add') {
            axiosAPI
                .get(`${url}/shop_delivery_option/${id}`)
                .then((res) => { console.log('krnfoij'); return dispatch({ type: types.GET_SHOP_DELIVERY_OPTION, payload: res.data }) })
                .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_GET_SHOP_DELIVERY_OPTION, payload: error.response.data }) })
        } else {
            return dispatch({ type: types.GET_EMPTY_SHOP_DELIVERY_OPTION })
        }
    };
}

function addShopDeliveryOption(item, dispatch) {
    dispatch({ type: types.PROCESSING });
    if (item.id) {
        return async function () {
            axiosAPI
                .patch(`${url}/shop_delivery_option/${item.id}/`, item,)
                .then((res) => { console.log('krnfoij'); return dispatch({ type: types.ADD_SHOP_DELIVERY_OPTION, payload: res.data }) })
                .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_ADD_SHOP_DELIVERY_OPTION, payload: error.response.data }) })
        };
    } else {
        return async function () {
            axiosAPI
                .post(`${url}/shop_delivery_option/`, item,)
                .then((res) => { console.log('krnfoij'); return dispatch({ type: types.ADD_SHOP_DELIVERY_OPTION, payload: res.data }) })
                .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_ADD_SHOP_DELIVERY_OPTION, payload: error.response.data }) })
        };
    }
}

function getShop(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop/`)
            .then((res) => { console.log('krnfoij'); return dispatch({ type: types.GET_SHOP, payload: res.data }) })
            .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_GET_SHOP, payload: error.response.data }) })
    };
}

function closeAlertShopDeliveryOption(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_SHOP_DELIVERY_OPTION
    })
}

export {
    getShopDeliveryOptions,
    getShopDeliveryOption,
    addShopDeliveryOption,
    closeAlertShopDeliveryOption,
    getShop
};
