import { connect } from "react-redux";

import ShopActivityDetail from "../components/ShopActivityDetail";

import { getShopActivity, addShopActivity, deleteShopActivity } from '../actions/shopActivityActions';

const mapStateToProps = state => ({
    user: state.auth,
    shopActivityRed: state.shopActivity,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShopActivity: (id, dispatch) =>
        dispatch(getShopActivity(id, dispatch)),
    addShopActivity: (item, dispatch) =>
        dispatch(addShopActivity(item, dispatch)),
    deleteShopActivity: (item_id, dispatch) =>
        dispatch(deleteShopActivity(item_id, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopActivityDetail);
