import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MuiTable from '../utils/MuiTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinearProgress from '@mui/material/LinearProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Product(props) {

    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getShopPartners(props.dispatch);
        }
    }, []);

    const handleCloseAlertShopPartner = () => {
        props.closeAlertShopPartner(props.dispatch)
    }
 
    const columns = [
        {
            name: "id",
            label: "Nom",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[1].name === props.headerRed.shop.name) {
                        return <div>{tableMeta.rowData[2].name}</div>;
                    } else {
                        return <div>{tableMeta.rowData[1].name}</div>;
                    }
                }
            }
        },
        {
            name: "from_shop",
            label: "From Shop",
            options: {
                display: 'excluded',
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{value.name}</div>;
                }
            }
        },
        {
            name: "to_shop",
            label: "To Shop",
            options: {
                display: 'excluded',
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{value.name}</div>;
                }
            }
        },
        {
            name: "from_accepted",
            label: "From Accepted",
            options: {
                display: 'excluded',
            }
        },
        {
            name: "to_accepted",
            label: "To Accepted",
            options: {
                display: 'excluded',
            }
        },
        {
            name: "from_agreed",
            label: "From Agreed",
            options: {
                display: 'excluded',
            }
        },
        {
            name: "to_agreed",
            label: "To Agreed",
            options: {
                display: 'excluded',
            }
        },
        {
            name: "to_shop",
            label: "Je suis point relais",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[1].name === props.headerRed.shop.name) {
                        return <div>{tableMeta.rowData[5] ? <CheckBoxIcon color="primary" /> : <CloseIcon color="primary" />}</div>;
                    } else {
                        return <div>{tableMeta.rowData[6] ? <CheckBoxIcon color="primary" /> : <CloseIcon color="primary" />}</div>;
                    }
                }
            }
        },
        {
            name: "Elle est point relais",
            label: "Elle est point relais",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[1].name === props.headerRed.shop.name) {
                        return <div>{tableMeta.rowData[6] ? <CheckBoxIcon color="primary" /> : <CloseIcon color="primary" />}</div>;
                    } else {
                        return <div>{tableMeta.rowData[5] ? <CheckBoxIcon color="primary" /> : <CloseIcon color="primary" />}</div>;
                    }
                }
            }
        },
        {
            name: "Partenariat",
            label: "Partenariat",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if ((tableMeta.rowData[3] === tableMeta.rowData[4]) && (tableMeta.rowData[4] === true)) {
                        return <div>Actif</div>;
                    } else {
                        return <div>Inactif</div>;
                    }
                }
            }
        },
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                download: false,
                setCellProps: () => ({ align: "right" }),
                customHeadRender: () => <th key={12} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <IconButton aria-label="delete" href={`/partner/${value}`} color="primary">
                        <MoreVertIcon />
                    </IconButton>;
                }
            }
        }]

    const options = {
        download: false,
        filter: false,
        search: false
    };

    return (
        <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Partenaires</Typography>
                </Breadcrumbs>
                <Snackbar
                    autoHideDuration={6000}
                    onClose={handleCloseAlertShopPartner}
                    open={props.shopPartnerRed.success_add}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertShopPartner}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertShopPartner} severity="success" sx={{ width: '100%', color: '#fff' }}>
                        Le partenariat a bien été créé/modifié.
                    </Alert>
                </Snackbar>
                <Box sx={{
                    display: 'flex', justifyContent: 'flex-end', mb: 4, width: '100%', mx: 'auto',
                }}>
                    <Button
                        href='/partner/add'
                        variant="contained"
                        sx={{ mt: 3, mr: 0 }}
                    >
                        Ajouter un partenaire
                    </Button>
                </Box>
                <Paper>
                    <MuiTable
                        data={props.shopPartnerRed.partners}
                        columns={columns}
                        options={options}
                        filename={'BOUTIGOU-Partenaires.csv'}
                    />
                </Paper>
            </Box>
        </Box>
    );
}

