import { connect } from "react-redux";

import Profile from "../components/Profile";

import { getProfile, addProfile, closeAlertProfile } from '../actions/profileActions';

import { logoutAction, whoAmI } from '../actions/auth/authActions';

const mapStateToProps = state => ({
    user: state.auth,
    profileRed: state.profileRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getProfile: (dispatch) =>
        dispatch(getProfile(dispatch)),
    addProfile: (item, dispatch) =>
        dispatch(addProfile(item, dispatch)),
    closeAlertProfile: (dispatch) =>
        dispatch(closeAlertProfile(dispatch)),
    logoutAction: (dispatch, push) =>
        dispatch(logoutAction(dispatch, push)),
    whoAmI: (token, dispatch) =>
        dispatch(whoAmI(token, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
