import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import MUIDataTable from "mui-datatables";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

export default function ShopDeliveryOption(props) {

    const isInitialMount = React.useRef(true);
    const [isShop, setIsShop] = React.useState(false);
    const [name, setName] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [address_secondary, setAddress_secondary] = React.useState(null);
    const [city, setCity] = React.useState(null);
    const [zipcode, setZipcode] = React.useState(null);
    const [country, setCountry] = React.useState(null);
    const [data, setData] = React.useState({});
    const [dataRefresh, setDataRefresh] = React.useState(false)

    const days = {
        1: 'Lundi',
        2: 'Mardi',
        3: 'Mercredi',
        4: 'Jeudi',
        5: 'Vendredi',
        6: 'Samedi',
        7: 'Dimanche',
    };

    React.useEffect(() => {
        if (isInitialMount.current) {
            props.getShop(props.dispatch);
            props.getShopDeliveryOption(props.match.params.shop_delivery_option_id, props.dispatch);
            isInitialMount.current = false;
        };
    }, []);

    React.useEffect(() => {
        if (JSON.stringify(props.shopDeliveryOptionRed.shopDeliveryOption) != JSON.stringify({})) {
            setName(props.shopDeliveryOptionRed.shopDeliveryOption.name);
            setAddress(props.shopDeliveryOptionRed.shopDeliveryOption.address);
            setAddress_secondary(props.shopDeliveryOptionRed.shopDeliveryOption.address_secondary);
            setCity(props.shopDeliveryOptionRed.shopDeliveryOption.city);
            setZipcode(props.shopDeliveryOptionRed.shopDeliveryOption.zipcode);
            setCountry(props.shopDeliveryOptionRed.shopDeliveryOption.country);
            setData(props.shopDeliveryOptionRed.shopDeliveryOption.slots);
            setIsShop(props.shopDeliveryOptionRed.shopDeliveryOption.is_shop);
        }
    }, [props.shopDeliveryOptionRed.shopDeliveryOption]);

    const handleIsShop = () => {
        if (!isShop) {
            setName("A la boutique");
            setAddress(props.shopDeliveryOptionRed.shop.address);
            setAddress_secondary(props.shopDeliveryOptionRed.shop.address_secondary);
            setCity(props.shopDeliveryOptionRed.shop.city);
            setZipcode(props.shopDeliveryOptionRed.shop.zipcode);
            setCountry(props.shopDeliveryOptionRed.shop.country);
        }
        setIsShop(!isShop)
    }

    React.useEffect(() => {
        setDataRefresh(false)
    }, [dataRefresh]);

    const numberToDate = (value) => {
        var seconds = value % 60;
        var minutes = Math.floor(value / 60);
        if (seconds < 10) {
            var secondsString = `0${seconds}`.toString()
        } else {
            var secondsString = seconds.toString()
        };
        if (minutes < 10) {
            var minutesString = `0${minutes}`.toString()
        } else {
            var minutesString = minutes.toString()
        }
        return `${minutesString}:${secondsString}`.toString()
    };

    const dateToNumber = (value, day, slot, type) => {
        var seconds = parseInt(value.split(':')[1]);
        var minutes = parseInt(value.split(':')[0]);
        if (type === 'from') {
            data[day][data[day].indexOf(slot)].from = minutes * 60 + seconds
        } else {
            data[day][data[day].indexOf(slot)].to = minutes * 60 + seconds
        }
        setData(data);
        setDataRefresh(true);
    };

    const handleDeleteSlot = (day, slot) => {
        var interData = data[day];
        delete interData[data[day].indexOf(slot)];
        data[day] = interData;
        setDataRefresh(true);
    };

    const handleChangeDay = (old_day, day, slot) => {
        var interData = data;
        delete interData[old_day][data[old_day].indexOf(slot)];

        if ((!interData[old_day][0]) && (interData[old_day].lentgh === 1)) {
            delete interData[old_day]
        }
        if (interData[day]) {
            interData[day].push(slot)
        } else {
            interData[day] = [slot]
        };
        setData(interData);
        setDataRefresh(true);
    };

    React.useEffect(() => {
        if (props.shopDeliveryOptionRed.success_add === true) {
            props.history.push('/delivery_option')
        }
    }, [props.shopDeliveryOptionRed.success_add]);

    const handleShopDelivery = () => {
        var interData = {};
        if (data['1']) {
            if (data['1'].filter(elm => elm).length > 0) {
                interData['1'] = data['1'].filter(elm => elm)
            }
        };
        if (data['2']) {
            if (data['2'].filter(elm => elm).length > 0) {
                interData['2'] = data['2'].filter(elm => elm)
            }
        };
        if (data['3']) {
            if (data['3'].filter(elm => elm).length > 0) {
                interData['3'] = data['3'].filter(elm => elm)
            }
        };
        if (data['4']) {
            if (data['4'].filter(elm => elm).length > 0) {
                interData['4'] = data['4'].filter(elm => elm)
            }
        };
        if (data['5']) {
            if (data['5'].filter(elm => elm).length > 0) {
                interData['5'] = data['5'].filter(elm => elm)
            }
        };
        if (data['6']) {
            if (data['6'].filter(elm => elm).length > 0) {
                interData['6'] = data['6'].filter(elm => elm)
            }
        };
        if (data['7']) {
            if (data['7'].filter(elm => elm).length > 0) {
                interData['7'] = data['7'].filter(elm => elm)
            }
        };
        const item = {
            'name': name,
            'address': address,
            'address_secondary': address_secondary,
            'zipcode': zipcode,
            'city': city,
            'country': country,
            'slots': interData,
            'is_shop': isShop
        };
        if (props.shopDeliveryOptionRed.shopDeliveryOption.id) {
            item['id'] = props.shopDeliveryOptionRed.shopDeliveryOption.id;
        };
        props.addShopDeliveryOption(item, props.dispatch)
    }

    const handleAddSlot = () => {
        data['0'] = [{ 'from': 480, 'to': 1080 }] ;
        setDataRefresh(true);
    }

    return (
        <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant="h4" style={{ textDecoration: 'none' }} color="inherit" href="/delivery_option">
                        Points de collecte
                    </Link>
                    <Typography variant="h4" color="text.primary">{props.match.params.shop_delivery_option_id !== 'add' ? props.shopDeliveryOptionRed.shopDeliveryOption.name : 'Nouveau point de collecte'}</Typography>
                </Breadcrumbs>
                <Box sx={{mt: 4}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={isShop} onChange={() => handleIsShop()} />}
                                    label="Il s'agit de ma boutique"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="name"
                                value={name || ''}
                                onChange={(e) => setName(e.target.value)}
                                label="Nom du point de collecte"
                                name="name"
                                disabled={isShop}
                                helperText={props.shopDeliveryOptionRed.errors.name ? props.shopDeliveryOptionRed.errors.name.message : null}
                                error={props.shopDeliveryOptionRed.errors.name ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="address"
                                value={address || ''}
                                onChange={(e) => setAddress(e.target.value)}
                                label="Adresse de la boutique"
                                name="address"
                                disabled={isShop}
                                helperText={props.shopDeliveryOptionRed.errors.address ? props.shopDeliveryOptionRed.errors.address.message : null}
                                error={props.shopDeliveryOptionRed.errors.address ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="address_secondary"
                                value={address_secondary || ''}
                                onChange={(e) => setAddress_secondary(e.target.value)}
                                label="Complement"
                                name="address_secondary"
                                disabled={isShop}
                                helperText={props.shopDeliveryOptionRed.errors.address_secondary ? props.shopDeliveryOptionRed.errors.address_secondary.message : null}
                                error={props.shopDeliveryOptionRed.errors.address_secondary ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="zipcode"
                                value={zipcode || ''}
                                onChange={(e) => setZipcode(e.target.value)}
                                required
                                fullWidth
                                id="zipcode"
                                label="Code postal"
                                disabled={isShop}
                                helperText={props.shopDeliveryOptionRed.errors.zipcode ? props.shopDeliveryOptionRed.errors.zipcode.message : null}
                                error={props.shopDeliveryOptionRed.errors.zipcode ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                id="city"
                                label="Ville"
                                name="city"
                                value={city || ''}
                                onChange={(e) => setCity(e.target.value)}
                                disabled={isShop}
                                helperText={props.shopDeliveryOptionRed.errors.city ? props.shopDeliveryOptionRed.errors.city.message : null}
                                error={props.shopDeliveryOptionRed.errors.city ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                id="country"
                                label="Pays"
                                name="country"
                                value={country || ''}
                                onChange={(e) => setCountry(e.target.value)}
                                disabled={isShop}
                                helperText={props.shopDeliveryOptionRed.errors.country ? props.shopDeliveryOptionRed.errors.country.message : null}
                                error={props.shopDeliveryOptionRed.errors.country ? true : false}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{mt: 15}}>
                        {props.shopDeliveryOptionRed.errors.slots ? (<Alert severity="error">{props.shopDeliveryOptionRed.errors.slots.message}</Alert>) : null}
                    </Box>
                    <Grid container spacing={1} mt={4} mb={1}>
                        <Typography sx={{display: 'flex', alignItems: 'center'}}>Horaires</Typography>
                        <Tooltip title="Ajouter un créneau">
                            <IconButton onClick={() => handleAddSlot()}>
                                <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {Object.keys(data).map((day) => 
                        data[day].map((slot) => (
                            <Grid container mb={1} spacing={1} style={{ textAlign: "center" }}>
                                <Grid item xs={12} sm={3}>
                                    <Select
                                        labelId="days"
                                        id="days"
                                        value={day}
                                        label="Jours"
                                        fullWidth
                                        onChange={(e) => handleChangeDay(day, e.target.value, slot)}
                                    >
                                        {Object.keys(days).map((d) => (<MenuItem value={d}>{days[d]}</MenuItem>)
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="time"
                                        label="Début"
                                        type="time"
                                        fullWidth
                                        onChange={(e) => dateToNumber(e.target.value, day, slot, 'from')}
                                        value={numberToDate(slot.from) || "8:00"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="time"
                                        label="Fin"
                                        type="time"
                                        value={numberToDate(slot.to) || "18:00" }
                                        onChange={(e) => dateToNumber(e.target.value, day, slot, 'to')}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} my='auto'>
                                    <Box sx={{ textAlign: 'left'}}>
                                        <Tooltip title="Supprimer ce créneau">
                                            <IconButton onClick={() => handleDeleteSlot(day, slot)} size="large">
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                
                            </Grid>
                        ))
                    )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        href='/delivery_option'
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Retour
                    </Button>
                    <Button
                        onClick={() => handleShopDelivery()}
                        variant="contained"
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Valider
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

