import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

export default function Help(props) {

    return (
        <Box sx={{ pt: '100px', pb: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                mb: 1,
                width: '90%',
                mx: 'auto'
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Besoin d'aide</Typography>
                </Breadcrumbs>
                <Container maxWidth={false} disableGutters sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h3" sx={{ pt: 5 }}>
                                        Contactez-nous par téléphone :
                                    </Typography>
                                    <Paper elevation={0} sx={{ p: 2, textAlign: "center" }}>
                                        <Chip
                                            label="(+33) 0637964108"
                                            color="primary"
                                            variant={'outlined'}
                                            icon={<ContactPhoneIcon fontSize="large"/>}
                                            sx={{
                                                fontSize: '22px',
                                                fontWeight: 'bold!important',
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 2,
                                                paddingRight: 2
                                              }}
                                        />
                                    </Paper>
                                    <Typography variant="h3" sx={{ pt: 5 }}>
                                        Contactez-nous par email :
                                    </Typography>
                                    <Paper elevation={0} sx={{ p: 2, textAlign: "center" }}>
                                        <Chip
                                            label="supportpro@boutigou.fr"
                                            color="primary"
                                            variant={'outlined'}
                                            icon={<AlternateEmailIcon fontSize="large"/>}
                                            sx={{
                                                fontSize: '22px',
                                                fontWeight: 'bold!important',
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                paddingLeft: 2,
                                                paddingRight: 2
                                              }}
                                        />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

