import { connect } from "react-redux";

import Account from "../components/Account";

import { getAccount, addAccount } from '../actions/accountActions';

const mapStateToProps = state => ({
    user: state.auth,
    accountRed: state.accountRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getAccount: (dispatch) =>
        dispatch(getAccount(dispatch)),
    addAccount: (shop_id, dispatch) =>
        dispatch(addAccount(shop_id, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
