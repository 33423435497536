 import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AppWidgetSummary from './AppWidgetSummary';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');
require('globalize/lib/cultures/globalize.culture.fr');


export default function HomePage(props) {

    const isInitialMount = React.useRef(true);
    const [openEvent, setOpenEvent] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState({});
    const localizer = momentLocalizer(moment);
    const [activeStep, setActiveStep] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [openStep, setOpenStep] = React.useState({});
    
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
    };

    const handleNext = (index) => {
        if ((index == 0) && (!props.homePageRed.shop_onboarding.shop_profile)) {
            setOpenStep(steps[index])
            setOpen(true)
        } else if ((index == 1) && (!props.homePageRed.shop_onboarding.shop_delivery_option)) {
            setOpenStep(steps[index])
            setOpen(true)
        } else if ((index == 2) && (!props.homePageRed.shop_onboarding.shop_account_set_up)) {
            setOpenStep(steps[index])
            setOpen(true)
        } else if ((index == 3) && (!props.homePageRed.shop_onboarding.shop_subscription)) {
            setOpenStep(steps[index])
            setOpen(true)
        
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    React.useEffect(() => {
        if (props.homePageRed.shop_onboarding.shop_subscription) {
            setActiveStep(4)
        }
        if (props.homePageRed.shop_onboarding.shop_account_set_up) {
            setActiveStep(3)
        }
        if (props.homePageRed.shop_onboarding.shop_delivery_option) {
            setActiveStep(2)
        }
        if (props.homePageRed.shop_onboarding.shop_profile) {
            setActiveStep(1)
        }    
    }, [props.homePageRed.shop_onboarding]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const steps = [
      {
        description: `Vous devez compléter votre profil boutique en y ajoutant des photos et une description en `,
        link: 'shop'
      },
      {
        description: 'Vous devez renseigner au moins un point de collecte (par exemple à votre boutique) en ',
        link: 'delivery_option'

      },
      {
        description: 'Vous devez configurer votre compte en ',
        link: 'account'
      },
      {
        description: 'Vous devez payer votre abonnement à Boutigou en  ',
        link: 'subscription'
      },
    ];


    React.useEffect(() => {
        if (isInitialMount.current) {
            props.getShop(props.dispatch);
            isInitialMount.current = false;
        }
    }, []);

    React.useEffect(() => {
        if (!props.homePageRed.shop.state) {
            props.getShopOnboarding(props.dispatch);
        } else {
            props.getShopEvents(props.dispatch);
            props.getShopHome(props.dispatch);
        }
    }, [props.homePageRed.shop]);

    React.useEffect(() => {
        if (props.homePageRed.successRequestDeployment === true) {
            props.getShop(props.dispatch);
            props.getShopOnboarding(props.dispatch);
        }
    }, [props.homePageRed.successRequestDeployment]);

    const handleShopDeployment = () => {
        const item = {
            'id': props.homePageRed.shop.id,
            'request_deployment': true
        };
        props.requestShopDeployment(item, props.dispatch);
    }

    const handleSelectEvent = (e) => {
        setSelectedEvent(e);
        setOpenEvent(true)
    }

    const handleCloseEvent = () => {
        setSelectedEvent({});
        setOpenEvent(false)
    }

    return (
        <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                mb: 1,
                width: '90%',
                mx: 'auto'
            }}>
                {props.homePageRed.processing ? <LinearProgress /> : null}
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Accueil</Typography>
                </Breadcrumbs>
            </Box>
            <Container maxWidth={false} disableGutters sx={{ mt: 4, mb: 4, width: '90%' }}>
                {(props.headerRed.shop ? !props.headerRed.shop.state : false) ?
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="body">
                                        {!props.homePageRed.shop_onboarding.shop_ready ? 'Il vous reste quelques étapes à finaliser avant de pouvoir réaliser votre première vente' : null}
                                    </Typography>
                                    <Box sx={{
                                        mb: 1,
                                        width: '90%',
                                        mx: 'auto'
                                    }}>
                                      <Stepper activeStep={activeStep} orientation="vertical">
                                        {steps.map((step, index) => (
                                          <Step key={step.link}>
                                            <StepLabel>
                                              <Typography>{step.description}<Link href={step.link}>cliquant ici</Link></Typography>
                                            </StepLabel>
                                            <StepContent>
                                              <Box sx={{ mb: 2 }}>
                                                <div>
                                                  <Button
                                                    variant="contained"
                                                    onClick={() => handleNext(index)}
                                                    sx={{ mt: 1, mr: 1 }}
                                                  >
                                                    {index === steps.length - 1 ? 'Valider' : 'Continuer'}
                                                  </Button>
                                                  <Button
                                                    disabled={index === 0}
                                                    variant="outlined"
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                  >
                                                    Retour
                                                  </Button>
                                                </div>
                                              </Box>
                                            </StepContent>
                                          </Step>
                                        ))}
                                      </Stepper>
                                      {activeStep === steps.length && (
                                        <Paper square elevation={0} sx={{ p: 3 }}>
                                            <Typography>
                                                {props.homePageRed.shop_onboarding.shop_ready == true && !props.homePageRed.shop.request_deployment ? 'Vous pouvez cliquer ci-dessous pour mettre en ligne votre boutique.' : null}
                                                {props.homePageRed.shop_onboarding.shop_ready == true && props.homePageRed.shop.request_deployment ? 'Votre demande de publication de votre boutique a bien été prise en compte et sera traitée dans les plus brefs délais. Vous serez informé par courriel.' : null}
                                            </Typography>
                                            {props.homePageRed.shop_onboarding.shop_ready == true && !props.homePageRed.shop.request_deployment ?
                                                <Box sx={{
                                                    mb: 1,
                                                    width: '90%',
                                                    mx: 'auto'
                                                }}>
                                                    <Button
                                                        onClick={() => handleShopDeployment()}
                                                        variant="contained"
                                                        sx={{ mt: 3, ml: 1, width: '100%', }}
                                                    >
                                                        Mettre en ligne
                                                    </Button>
                                                </Box> : null}
                                        </Paper>
                                      )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid> : null}
                {(props.headerRed.shop ? props.headerRed.shop.state : false) ?
                <React.Fragment>
                    <Box pt={1} pb={2}>
                        <Calendar
                            localizer={localizer}
                            culture={'fr'}
                            min={new Date(0, 0, 0, 7, 0, 0)}
                            max={new Date(0, 0, 0, 20, 0, 0)}
                            defaultView={'day'}
                            formats={{
                                dayHeaderFormat: (date, culture, localizer) =>
                                    localizer.format(date, 'dddd Do MMMM', culture),
                                dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
                                    'Du ' +
                                    localizer.format(start, 'dddd Do MMMM', culture) +
                                    ' au ' +
                                    localizer.format(end, 'dddd Do MMMM', culture),
                            }}
                            views={['day', 'week']}
                            messages={
                                {
                                    week: 'La semaine',
                                    work_week: 'Semaine de travail',
                                    day: 'Jour',
                                    month: 'Mois',
                                    previous: 'Précédent',
                                    next: 'Suivant',
                                    today: `Aujourd'hui`,
                                    agenda: 'Ordre du jour',
                                    showMore: (total) => `+${total} plus`,
                                }}
                            style={{}}
                            events={props.homePageRed.shop_events}
                            onSelectEvent={(e) => handleSelectEvent(e)}
                        />
                    </Box>
                        <Grid container spacing={3} pb={10}>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary title="Nombre de commandes" total={props.homePageRed.shop_home.shop_order_count || 0} color="error"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary title="Total des ventes" total={`${Math.round(props.homePageRed.shop_home.shop_order_total)/100 || 0} €`} color="info"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary title="Nombre de produits en vente" total={props.homePageRed.shop_home.shop_product_count || 0} color="warning"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary title="Panier moyen" total={`${Math.round(props.homePageRed.shop_home.shop_order_avg)/100 || 0} €`} color="error"/>
                        </Grid>
                    </Grid>
                    <Modal
                        open={openEvent}
                        onClose={handleCloseEvent}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            minWidth: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            borderRadius: 4,
                            boxShadow: 24,
                            p: 4,
                        }}>
                            <Typography id="modal-modal-title" variant="h4" component="h2">
                                {selectedEvent.title}
                            </Typography>
                            <Typography id="modal-modal-title" variant="body1" component="h2">
                                Client: {selectedEvent.consumer}
                            </Typography>
                            <Typography id="modal-modal-title" variant="body2" component="h2">
                                Actions: {selectedEvent.description}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={handleCloseEvent}
                                    variant="outlined"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Fermer
                                </Button>
                                <Button
                                    onClick={() => props.history.push(`/order/${selectedEvent.order}`)}
                                    variant="contained"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Voir la commande
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </React.Fragment>
                : null}
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    minWidth: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    borderRadius: 4,
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        {openStep.description}<Link href={openStep.link}>cliquant ici</Link>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Fermer
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}



/*<Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Nombre de commandes" total={98} icon={'ant-design:android-filled'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Nombre de clients" total={152} color="info" icon={'ant-design:apple-filled'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Nombre de produits" total={56} color="warning" icon={'ant-design:windows-filled'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Panier moyen" total={96} color="error" icon={'ant-design:bug-filled'} />
                    </Grid>
                </Grid>*/