import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import StoreIcon from '@mui/icons-material/Store';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import EventIcon from '@mui/icons-material/Event';
import MessageIcon from '@mui/icons-material/Message';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PlaceIcon from '@mui/icons-material/Place';
import HandshakeIcon from '@mui/icons-material/Handshake';

const textColor = 'text.primary';
const buttonSelectedColor = 'primary.main';
const iconSelectedColor = 'white';

const ListItemButtonStyle = (open, theme, isSelected) => (
    {   zIndex: 1201,
        py: 1,
        ...(open && {
            '&.Mui-selected': {
                color: buttonSelectedColor,
            }
        }),
    }
)

const ListItemIconStyle = (open, theme, isSelected) => (
    {
        minWidth: 36,
        mr: open ? 3 : 'auto',
        color: isSelected ? iconSelectedColor : textColor, 
        borderRadius: 1.5,
        width: 36,
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
        ...(isSelected && {
            bgcolor: 'primary.lighter',
        })
    }
)

export const MainListItems = (props) => (
    <React.Fragment>
        <ListItemButton
            href={'/'}
            selected={window.location.pathname === '/'}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname === '/')}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname === '/')}
            >
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname === '/' ? buttonSelectedColor : textColor }}>
                        Accueil
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/order'}
            selected={window.location.pathname.includes('/order')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/order'))}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/order'))}
            >
                <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/order') ? buttonSelectedColor : textColor }}>
                        Commandes
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/product'}
            selected={window.location.pathname.includes('/product')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/product'))}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/product'))}
            >
                <Inventory2Icon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/product') ? buttonSelectedColor : textColor }}>
                        Produits
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/activity'}
            selected={window.location.pathname.includes('/activity')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/activity'))}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/activity'))}
            >
                <EventIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/activity') ? buttonSelectedColor : textColor }}>
                        Activités
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/shop'}
            selected={window.location.pathname.includes('/shop')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/shop'))}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/shop'))}
            >
                <StoreIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/shop') ? buttonSelectedColor : textColor }}>
                        Boutique
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/delivery_option'}
            selected={window.location.pathname.includes('/delivery_option')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/delivery_option'))}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/delivery_option'))}
            >
                <PlaceIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/delivery_option') ? buttonSelectedColor : textColor }}>
                        Points de collecte
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/partner'}
            selected={window.location.pathname.includes('/partner')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/partner'))}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/partner'))}
            >
                <HandshakeIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/partner') ? buttonSelectedColor : textColor }}>
                        Partenaires
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/message'}
            selected={window.location.pathname.includes('/message')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/message'))}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/message'))}
            >
                <MessageIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/message') ? buttonSelectedColor : textColor }}>
                        Messages
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/review'}
            selected={window.location.pathname.includes('/review')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/review'))}
        >
            <ListItemIcon
                sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/review'))}
            >
                <BarChartIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/review') ? buttonSelectedColor : textColor }}>
                        Avis
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
    </React.Fragment>
);


export const SecondaryListItems = (props) => (
    <React.Fragment>
        <ListSubheader component="div" inset>
            Autres
        </ListSubheader>
        <ListItemButton
            href={'/subscription'}
            selected={window.location.pathname.includes('/subscription')}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname.includes('/subscription'))}
        >
            <ListItemIcon sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname.includes('/subscription'))}>
            <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname.includes('/subscription') ? buttonSelectedColor : textColor }}>
                        Abonnement
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>
        <ListItemButton
            href={'/terms'}
            selected={window.location.pathname === '/terms'}
            sx={ListItemButtonStyle(props.open, props.theme ,window.location.pathname === '/terms')}
        >
            <ListItemIcon sx={ListItemIconStyle(props.open, props.theme ,window.location.pathname === '/terms')}>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="h6" sx={{ color: window.location.pathname === '/terms' ? buttonSelectedColor : textColor }}>
                        CGU
                    </Typography>
                }
                sx={{ opacity: props.open ? 1 : 0 }}
            />
        </ListItemButton>        
    </React.Fragment>
);