import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import gif_loading from '../assets/gif_loading.gif';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Account(props) {

    const isInitialMount = React.useRef(true);
    const [open, setOpen] = React.useState(false);
    const [successAccount, setSuccessAccount] = React.useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);


    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getAccount(props.dispatch);
        };
        if (params.get("success")) {
            setSuccessAccount(true);
        }
        if (params.get("refresh")) {
            setOpen(false);
            props.history.push('/account')
        }
    }, []);

    React.useEffect(() => {
    }, [successAccount]);

    React.useEffect(() => {
        if (!params.get("refresh") && !params.get("success")) {
            if (props.accountRed.stripe_account_link !== null) {
                window.location.href = props.accountRed.stripe_account_link
            }
        }
    }, [props.accountRed.stripe_account_link]);
    
    const handleAccount = () => {
        setOpen(true)
        props.addAccount(props.accountRed.shop.id, props.dispatch);
    }

    const handlesuccessAccount = () => {
        setSuccessAccount(false);
        setOpen(false);
        props.history.push('/account')
    }


    if (props.accountRed.shop.stripe_account_link === null) {
        return (
            <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <Box sx={{
                    mb: 1,
                    width: '90%',
                    mx: 'auto'
                }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h4" color="text.primary">Mon compte</Typography>
                    </Breadcrumbs>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 250,
                                mb: 4
                            }}
                            alt="Loading..."
                            src={gif_loading}
                        />
                    </Backdrop>
                    <Snackbar
                        open={successAccount}
                        key={'top' + 'center'}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        action={
                            <React.Fragment>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    sx={{ p: 0.5 }}
                                    onClick={handlesuccessAccount}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    >
                        <Alert onClose={handlesuccessAccount} severity="success" sx={{ width: '100%', color: '#fff' }}>
                            Votre compte a été configuré avec succès.
                        </Alert>
                    </Snackbar>
                    <Container maxWidth={false} disableGutters sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="body">
                                            Afin de sécuriser votre compte, nous utilisons la plateforme Stripe pour stocker vos informations sensibles.
                                        </Typography>
                                        <Typography variant="body1">
                                            Vous pouvez configurer votre compte en cliquant sur le bouton ci-dessous. Vous allez être redirigé vers la plateforme Stripe sur laquelle vous allez renseigner vos informations.
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button
                                                href='/'
                                                variant="outlined"
                                                sx={{ mt: 3, ml: 1 }}
                                            >
                                                Revenir à l'accueil
                                            </Button>
                                            <Button
                                                onClick={() => handleAccount() }
                                                variant="contained"
                                                sx={{ mt: 3, ml: 1 }}
                                            >
                                                Configurer mon compte
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <Box sx={{
                    mb: 1,
                    width: '90%',
                    mx: 'auto'
                }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h4" color="text.primary">Mon compte</Typography>
                    </Breadcrumbs>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 250,
                                mb: 4
                            }}
                            alt="Loading..."
                            src={gif_loading}
                        />
                    </Backdrop>
                    <Snackbar
                        open={successAccount}
                        key={'top' + 'center'}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        action={
                            <React.Fragment>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    sx={{ p: 0.5 }}
                                    onClick={handlesuccessAccount}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    >
                        <Alert onClose={handlesuccessAccount} severity="success" sx={{ width: '100%', color: '#fff' }}>
                            Votre compte a été configuré avec succès.
                        </Alert>
                    </Snackbar>
                    {props.accountRed.load_account ? (<LinearProgress />) : null}
                    <Container maxWidth={false} disableGutters sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="body">
                                            Afin de sécuriser votre compte, nous utilisons la plateforme Stripe pour stocker vos informations sensibles.
                                        </Typography>
                                        <Typography variant="body1">
                                            Vous avez déjà configuré votre compte. Vous pouvez effectuer des modifications (IBAN, numéro de téléphone...) en cliquant sur le bouton ci-dessous. Vous allez être redirigé vers la plateforme Stripe sur laquelle vous allez renseigner vos informations.
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button
                                                href='/'
                                                variant="outlined"
                                                sx={{ mt: 3, ml: 1 }}
                                            >
                                                Revenir à l'accueil
                                            </Button>
                                            <Button
                                                onClick={() => handleAccount()}
                                                variant="contained"
                                                sx={{ mt: 3, ml: 1 }}
                                            >
                                                Modifier mon compte
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        );
    }
}

