import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MuiTable from '../utils/MuiTable';
import Rating from '@mui/material/Rating';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinearProgress from '@mui/material/LinearProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Product(props) {

    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getReviews(props.dispatch);
        }
    }, []);

    const handleCloseAlertReview = () => {
        props.closeAlertReview(props.dispatch);
    }
 
    const columns = [
        {
            name: "consumer",
            label: "Client",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{value.username}</div>;
                },
                toStringOverride: (rawDataValue) => {
                    return rawDataValue.username
                }
            }
        },
        {
            name: "content",
            label: "Avis",
        },
        {
            name: "rate",
            label: "Note",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return < Rating name="half-rating-read" value={value} precision={1} readOnly />;
                }
            }
        },
        {
            name: "response",
            label: "Réponse",
        },
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                download: false,
                setCellProps: () => ({ align: "right" }),
                customHeadRender: () => <th key={4} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <IconButton aria-label="delete" onClick={() => { handleCloseAlertReview(); props.history.push(`/review/${value}`) }} color="primary">
                        <MoreVertIcon />
                    </IconButton>;
                }
            }
        }]

    const options = {
        
    };

    return (
        <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Avis</Typography>
                </Breadcrumbs>
            </Box>
            <Snackbar
                open={props.reviewRed.success_add}
                autoHideDuration={6000}
                onClose={handleCloseAlertReview}
                key={'top' + 'center'}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={handleCloseAlertReview}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert onClose={handleCloseAlertReview} severity="success" sx={{ width: '100%', color: '#fff' }}>
                    Votre réponse a bien été envoyée.
                </Alert>
            </Snackbar>
            <Paper sx={{ width: '90%', mx: 'auto' }}>
                    <MuiTable
                    data={props.reviewRed.reviews}
                    columns={columns}
                    options={options}
                    filename={'BOUTIGOU-Avis.csv'}
                />
            </Paper>
        </Box>
    );
}

