import * as types from "../../types/actionTypes";

const initialState = {
    authenticated: false,
    errors: {},
    user: null,
    not_allowed: false,
    shop: {},
    validate: null,
    validated: false,
    validate_errors: {},
    processingValidate: false,
    processingSignUp: false,
    cgu: {}
};

function authReducer(state = initialState, action) {
    switch (action.type) {
      case types.CLEAN_AUTH:
        return {
          ...initialState
          };
    case types.AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        not_allowed: false,
      };
    case types.UNAUTHENTICATED:
      return {
        ...initialState
        };
    case types.USER_SHOP:
        return {
            ...state,
            shop: action.payload,
        };
    case types.WHO_AM_I:
      if (action.payload.id === null) {
        return {
          ...state,
          authenticated: false,
          errors: {},
          user: null,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          authenticated: true,
          user: action.payload,
          isLoading: false,
        };
      };
    case types.FAILED_LOGIN:
      return {
        ...state,
        errors: true,
        isLoading: false,
      };
    case types.CLOSE_LOADING_SPINNER:
      return {
        ...state,
        isLoading: false
      };
    case types.NOT_ALLOWED:
      return {
        not_allowed: true
          };
    case types.SIGN_UP:
          return {
              ...state,
              validate: action.payload,
              errors: {},
              processingSignUp: false
          };
    case types.FAILED_SIGN_UP:
        return {
            ...state,
            errors: action.payload,
            validate: null,
            processingSignUp: false
          };
    case types.NEED_VALIDATE:
          return {
              ...state,
              validate: action.payload,
              validate_errors: {}
          };
      case types.VALIDATE:
          localStorage.setItem("access_token", action.payload.access);
          localStorage.setItem("refresh_token", action.payload.refresh);
          return {
              ...state,
              validated: true,
              validate_errors: {},
              user: action.payload.user,
              processingValidate: false
          };
      case types.FAILED_VALIDATE:
          return {
              ...state,
              validate_errors: action.payload,
              validated: false,
              processingValidate: false
          };
      case types.VALIDATE_RESEND:
          return {
              ...state,
              validate: action.payload,
              validate_errors: {},
              processingValidate: false
          };
      case types.FAILED_VALIDATE_RESEND:
          return {
              ...state,
              validate_errors: action.payload,
              validated: false,
              processingValidate: false
          };
      case types.PROCESSING_VALIDATE:
        return {
          ...state,
          processingValidate: true
        };
      case types.PROCESSING_SIGN_UP:
        return {
          ...state,
          processingSignUp: true
        };
      case types.GET_CGU:
        return {
          ...state,
          cgu: action.payload[0]
        };
      default:
        return state;
  }
}

export default authReducer;
