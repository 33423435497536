import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';
import gif_loading from '../assets/gif_loading.gif';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ShopPagePaymentMethod(props) {

    const isInitialMount = React.useRef(true);
    const [checked, setChecked] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (isInitialMount.current) {
            props.getShopPaymentMethodOptions(props.dispatch);
            setChecked((props.shopRed.shop.payment_methods || []).map((l) => l.id));
            isInitialMount.current = false;
        }
    }, []);


    React.useEffect(() => {
        if (props.shopRed.success_payment_method_add === true) {
            props.history.push('/shop')
        } else if (props.shopRed.success_payment_method_add === false) {
            setOpen(true)
        }
    }, [props.shopRed.success_payment_method_add]);

    const handleShopPaymentMethod = () => {
        const item = {
            'id': props.shopRed.shop.id,
            'payment_methods': JSON.stringify(checked)
        };
        props.addShopPaymentMethod(item, props.dispatch)
    }

    const handleCloseAlertShopPayment = () => {
        setOpen(false)
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        isInitialMount.current ?
            (<Box sx={{ width: '90%', pt: '100px', mx: 'auto' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
        (<Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant="h4" underline="hover" color="inherit" href="/shop" style={{ textDecoration: 'none' }}>
                        Boutique
                    </Link>
                    <Typography variant="h4" color="text.primary" >Edition des moyens de paiement acceptés</Typography>
                </Breadcrumbs>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
                    open={props.shopRed.processing}
                >
                    <Box
                        component="img"
                        sx={{
                            height: 250,
                            mb: 4
                        }}
                        alt="Loading..."
                        src={gif_loading}
                    />
                </Backdrop>
                <Snackbar
                    autoHideDuration={6000}
                    onClose={handleCloseAlertShopPayment}
                    open={open}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertShopPayment}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertShopPayment} severity="error" sx={{ width: '100%', color: '#fff' }}>
                        {props.shopRed.errors.null ? props.shopRed.errors.null.message  : ''}
                    </Alert>
                </Snackbar>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {(props.shopRed.shopPaymentMethodOptions || []).map((value) => {
                                    const labelId = `checkbox-list-label-${value.id}`;

                                    return (
                                        <ListItem
                                            key={value.id}
                                            disablePadding
                                        >
                                            <ListItemButton role={undefined} onClick={handleToggle(value.id)} dense disabled={value.type === false}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checked.indexOf(value.id) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        disabled={value.type === false}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value.type === false ? `En ligne via ${value.name}` : `Sur place via ${value.name}`} />
                                            </ListItemButton>
                                        </ListItem>
                                        );
                                })}
                            </List>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            href='/shop'
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                            disabled={props.shopRed.processing}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={handleShopPaymentMethod}
                            disabled={props.shopRed.processing}
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>)
    );
}

