import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MuiTable from '../utils/MuiTable';
import TextField from '@mui/material/TextField';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinearProgress from '@mui/material/LinearProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');
import gif_loading from '../assets/gif_loading.gif';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Order(props) {

    const isInitialMount = React.useRef(true);
    const [openValidate, setOpenValidate] = React.useState(false);
    const [validate, setValidate] = React.useState();
    const [openDelivered, setOpenDelivered] = React.useState(false);
    const [validation_code, setDeliveryCode] = React.useState('');
    const handleOpenValidate = () => setOpenValidate(true);
    const handleCloseValidate = () => setOpenValidate(false);
    const handleOpenDelivered = () => setOpenDelivered(true);
    const handleCloseDelivered = () => setOpenDelivered(false);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getOrder(props.match.params.order_id, props.dispatch);
            props.getOrderNotifications(props.dispatch)
        }
    }, []);

    React.useEffect(() => {
        if (props.orderRed.success_validate === true) {
            props.getOrder(props.match.params.order_id, props.dispatch);
        }
    }, [props.orderRed.success_validate]);

    React.useEffect(() => {
        if (props.orderRed.success_delivered === true) {
            props.getOrder(props.match.params.order_id, props.dispatch);
            setOpenDelivered(false)
        }
    }, [props.orderRed.success_delivered]);

    React.useEffect(() => {
        if (props.orderRed.contactCustomerId !== null) {
            window.location.href = `/message/${props.orderRed.contactCustomerId}`
        }
    }, [props.orderRed.contactCustomerId]);

    const handleCloseAlertOrder = () => {
        props.closeAlertOrder(props.dispatch)
    }

    const handleValidate = () => {
        if (validate === true) {
            var item = {
                'id': props.match.params.order_id,
                'accepted': validate,
                'refused': !validate
            }
        } else if (validate === false) {
            var item = {
                'id': props.match.params.order_id,
                'accepted': validate,
                'refused': !validate
            }
        }
        props.validateOrder(item, props.dispatch);
        setOpenValidate(false)
    }

    const handleDelivered = () => {
        const item = {
            'id': props.match.params.order_id,
            'validation_code': parseInt(validation_code, 10)
        };
        props.deliveredOrder(item, props.dispatch)
    };

    const handleContact = () => {
        props.contactOrderCustomer(props.orderRed.order.consumer_pk, props.dispatch)
    };

    const columns = [
        {
            name: "product__name",
            label: "Nom",
        },
        {
            name: "quantity",
            label: "Quantité",
        },
        {
            name: "price_unit",
            label: "Prix (unitaire)",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return `${value/100} €`;
                }
            }
        },
        {
            name: "product__unit__name",
            label: "Unité",
        },
        {
            name: "product",
            label: "#",
            options: {
                filter: false,
                setCellProps: () => ({ align: "right" }),
                customHeadRender: () => <th key={4} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <IconButton aria-label="delete" onClick={() => { props.history.push(`/product/${value}`) }} color="primary">
                        <MoreVertIcon />
                    </IconButton>;
                }
            }
        }]

    const options = {
        download: false,
        filter: false,
        search: false
    };

    return (
        props.orderRed.processing ?
            (<Box sx={{ width: '90%', pt: '100px', mx: 'auto' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
        (<Box sx={{ pt: '100px', pb: 5 }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="none" variant="h4" color="inherit" href="/order" style={{ textDecoration: 'none' }}>
                        Commandes
                    </Link>
                    <Typography variant="h4" color="text.primary">{props.match.params.order_id}</Typography>
                </Breadcrumbs>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
                open={props.orderRed.loading}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="Loading..."
                    src={gif_loading}
                />
            </Backdrop>
            <Card sx={{ width: '90%', mx: 'auto' }}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography gutterBottom variant="h5">
                                Commande {props.match.params.order_id} de {props.orderRed.order.consumer}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Date de création: {moment(props.orderRed.order.order.created_at).format('Do MMMM YYYY, H:mm')}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Date de dernière mise à jour: {moment(props.orderRed.order.order.updated_at).format('Do MMMM YYYY, H:mm')}
                            </Typography>
                            <Button
                                onClick={handleContact}
                                variant="contained"
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Contacter {props.orderRed.order.consumer}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign="right">
                            <Typography gutterBottom variant="h5">
                                Statuts de la commande
                            </Typography>
                            <Typography variant="body2" color="text.secondary" p={1}>
                                Global: <Chip label={props.orderRed.order.order.status} color='info' sx={{ mx: '1px', padding: '1px' }} />
                            </Typography>
                            <Typography variant="body2" color="text.secondary" p={1}>
                                Personnel: {!props.orderRed.order.suborder.accepted && !props.orderRed.order.suborder.refused ? <Chip label="En attente" color='info' sx={{ mx: '1px', padding: '1px' }} /> : props.orderRed.order.suborder.accepted ? <Chip label="Acceptée" color='success' sx={{ mx: '1px', padding: '1px' }} /> : <Chip label="Refusée" color='error' sx={{ mx: '1px', padding: '1px' }} /> } 
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{ width: '90%', mx: 'auto', mt: 5 }}>
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        Paiment
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {props.orderRed.order.order.payment_method_type === false ? `Le client a payé en ligne via ${props.orderRed.order.order ?  props.orderRed.order.order.payment_method_name : ''}` : null}
                            {props.orderRed.order.order.payment_method_type === true ? `Le client paiera sur place via ${props.orderRed.order.order ?  props.orderRed.order.order.payment_method_name : ''}` : null}
                        </Typography>
                </CardContent>
            </Card>
            <Card sx={{ width: '90%', mx: 'auto', mt: 5 }}>
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        Mes actions
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {props.orderRed.order.to_delivery_action}
                        </Typography>
                        {!props.orderRed.order.suborder.accepted && !props.orderRed.order.suborder.refused ?
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={() => { setValidate(false); handleOpenValidate() }}
                                    variant="outlined"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Refuser la commande
                                </Button>
                                <Button
                                    onClick={() => { setValidate(true); handleOpenValidate() }}
                                    variant="contained"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Valider la commande
                                </Button>
                            </Box> : null}
                        {props.orderRed.order.suborder.accepted && !props.orderRed.order.to_delivery_end && props.orderRed.order.to_delivery_master_shop ?
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={handleOpenDelivered}
                                    variant="contained"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Marquer la commande comme livrée
                                </Button>
                            </Box> : null}
                </CardContent>
            </Card>
            <Box sx={{ width: '90%', mx: 'auto', mt: 5 }}>
                <MuiTable
                    title={<Typography gutterBottom variant="h5">Produits</Typography>}
                    data={props.orderRed.order.products}
                    columns={columns}
                    options={options}
                />
            </Box>
            <Modal
                open={openValidate}
                onClose={handleCloseValidate}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    borderRadius: 4,
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        {validate ? 'Je souhaite valider la commande' : 'Je souhaite refuser la commande' }
                    </Typography>
                    {validate ?
                        <Typography variant="body1" color="text.secondary">
                            {props.orderRed.order.to_delivery_action}
                        </Typography> : null}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleCloseValidate}
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={handleValidate}
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Confirmer
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openDelivered}
                onClose={handleCloseDelivered}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    borderRadius: 4,
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Je souhaite marquer la commande comme livrée
                    </Typography>
                    <Typography id="modal-modal-title" variant="body1" component="h2" mb={3}>
                        Saisissez ci-dessous le code communiqué par le client
                    </Typography>
                    <TextField
                        id="send-message"
                        label="Code..."
                        fullWidth
                        mt={10}
                        type="number"
                        value={validation_code}
                        onChange={(event) => { setDeliveryCode(event.target.value) }}
                        helperText={props.orderRed.errorsDelivered.validation_code ? props.orderRed.errorsDelivered.validation_code.message : null}
                        error={props.orderRed.errorsDelivered.validation_code ? true : false}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleCloseDelivered}
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={handleDelivered}
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Confirmer
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>)
    );
}

