import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getShopPartners(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_partner/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_PARTNERS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_PARTNERS, payload: error })})
    };
}

function getShopPartner(id, dispatch) {
    dispatch({ type: types.FETCHING });
    return async function () {
        if (id !== 'add') {
            axiosAPI
                .get(`${url}/shop_partner/${id}/`)
                .then((res) => { return dispatch({ type: types.GET_SHOP_PARTNER, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_PARTNER, payload: error }) })
        } else {
            return dispatch({ type: types.GET_EMPTY_SHOP_PARTNER })
        }
    };
}

function addShopPartner(item, dispatch) {
    if (item.id) {
        dispatch({ type: types.PROCESSING })
        return async function () {
            axiosAPI
                .put(`${url}/shop_partner/${item.id}/`, item)
                .then((res) => { return dispatch({ type: types.ADD_SHOP_PARTNER, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_ADD_SHOP_PARTNER, payload: error.response.data }) })
        };
    } else {
        dispatch({ type: types.PROCESSING })
        return async function () {
            axiosAPI
                .post(`${url}/shop_partner/`, item)
                .then((res) => { return dispatch({ type: types.ADD_SHOP_PARTNER, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_ADD_SHOP_PARTNER, payload: error.response.data }) })
        };
    }
}

function getShopOption(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_partner_option/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_OPTIONS, payload: error }) })
    };
}

function closeAlertShopPartner(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_SHOP_PARTNER
    })
}

export {
    getShopPartners,
    getShopPartner,
    addShopPartner,
    getShopOption,
    closeAlertShopPartner
};
