import * as types from "../types/actionTypes";

const initialState = {
    shop: {},
    errors: {},
    shopCategoryOptions: {},
    shopPaymentMethodOptions: [],
    processing: false,
    success_add: null,
    success_address_add: null,
    success_payment_method_add: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SHOP:
            return {
                ...state,
                shop: action.payload[0],
                errors: false,
                processing: false
            };
        case types.ADD_SHOP:
            return {
                ...state,
                processing: false,
                success_add: true,
            };
        case types.FAILED_ADD_SHOP:
            return {
                ...state,
                processing: false,
                success_add: false,
                errors: action.payload
            };
        case types.ADD_SHOP_ADDRESS:
            return {
                ...state,
                processing: false,
                success_address_add: true,
            };
        case types.FAILED_ADD_SHOP_ADDRESS:
            return {
                ...state,
                processing: false,
                success_address_add: false,
                errors: action.payload
            };
        case types.GET_SHOP_CATEGORY_OPTIONS:
            var options = {};
            action.payload.map((c) => options[c.id] = c.name)
            return {
                ...state,
                shopCategoryOptions: options,
                errors: {}
            };
        case types.GET_SHOP_PAYMENT_METHOD_OPTIONS:
            return {
                ...state,
                shopPaymentMethodOptions: action.payload,
                errors: {}
            };
        case types.PROCESSING:
            return {
                ...state,
                processing: true
            };
        case types.CLOSE_ALERT_SHOP:
            return {
                ...state,
                success_add: null,
                success_address_add: null,
                success_payment_method_add: null
            };
        case types.ADD_SHOP_PAYMENT_METHOD:
            return {
                ...state,
                processing: false,
                success_payment_method_add: true,
            };
        case types.FAILED_ADD_SHOP_PAYMENT_METHOD:
            return {
                ...state,
                processing: false,
                success_payment_method_add: false,
                errors: action.payload
            };
        default:
          return state;
  }
};