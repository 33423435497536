import { connect } from "react-redux";

import Product from "../components/Product";

import { getProducts, closeAlertProduct } from '../actions/productActions';

const mapStateToProps = state => ({
    user: state.auth,
    productRed: state.productRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getProducts: (dispatch) =>
        dispatch(getProducts(dispatch)),
    closeAlertProduct: (dispatch) =>
        dispatch(closeAlertProduct(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
