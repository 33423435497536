import * as types from "../types/actionTypes";

const initialState = {
    partners: [],
    partner: {},
    shop_options: [],
    errors: {},
    processing: false,
    success_add: null,
    fetching: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SHOP_PARTNERS:
            return {
                ...state,
                success_add: null,
                partners: action.payload,
                errors: false
            };
        case types.GET_SHOP_PARTNER:
            return {
                ...state,
                partner: action.payload,
                success_add: null,
                processing: false,
                errors: false,
                fetching: false
            };
        case types.GET_EMPTY_SHOP_PARTNER:
            return {
                ...state,
                partner: {},
                success_add: null,
                processing: false,
                errors: false,
                fetching: false
            };
        case types.ADD_SHOP_PARTNER:
            return {
                ...state,
                errors: {},
                processing: false,
                success_add: true,
            };
        case types.FAILED_ADD_SHOP_PARTNER:
            return {
                ...state,
                errors: action.payload,
                success_add: false,
                processing: false,
            };
        case types.PROCESSING:
            return {
                ...state,
                processing: true,
                success_add: null
            };
        case types.GET_SHOP_OPTIONS:
            return {
                ...state,
                shop_options: action.payload
            };
        case types.CLOSE_ALERT_SHOP_PARTNER:
            return {
                ...state,
                success_add: null
            };
        case types.FETCHING:
            return {
                ...state,
                fetching: true
            };
        default:
          return state;
  }
};