import * as types from "../types/actionTypes";

const initialState = {
    reviews: [],
    review: {},
    errors: {},
    processing: false,
    success_add: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REVIEWS:
            return {
                ...state,
                reviews: action.payload,
                errors: {}
            };
        case types.GET_REVIEW:
            return {
                ...state,
                review: action.payload,
                success_add: null,
                processing: false,
                errors: {}
            };
        case types.ADD_REVIEW:
            return {
                ...state,
                errors: {},
                processing: false,
                success_add: true,
            };
        case types.FAILED_ADD_REVIEW:
            return {
                ...state,
                errors: action.payload,
                processing: false,
            };
        case types.PROCESSING:
            return {
                ...state,
                processing: true
            };
        case types.CLOSE_ALERT_REVIEW:
            return {
                ...state,
                success_add: null
            };
        default:
          return state;
  }
};