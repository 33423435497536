import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import img_nodata from '../assets/nodata.png';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Message(props) {

    const isInitialMount = React.useRef(true);
    const divRef = React.useRef(null);
    const [message, setMessage] = React.useState('');
    const height = React.useRef();
    const [currentHeight, setCurrentHeight] = React.useState('23px');

    React.useEffect(() => {
        if (props.match.params.conversation_id) {
            divRef.current.scrollIntoView({ behavior: 'instant' });
        }
    });

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getConversations(props.dispatch);
        }
    }, [props.messageRed.conversations]);

    React.useEffect(() => {
        if (props.match.params.conversation_id) {
            props.getMessage(props.match.params.conversation_id, props.dispatch);
            props.getConversationNotifications(props.dispatch);
            setMessage('');
        }
    }, [props.match.params.conversation_id]);

    React.useEffect(() => {
        if (props.match.params.conversation_id) {
            props.getMessage(props.match.params.conversation_id, props.dispatch);
            props.getConversationNotifications(props.dispatch);
            setMessage('');
        }
    }, [props.headerRed.conversationNotifs.length]);

    React.useEffect(() => {
        if (props.match.params.conversation_id) {
            props.getMessage(props.match.params.conversation_id, props.dispatch);
            setMessage('');
        }
    }, [props.messageRed.refresh]);

    const handleSendMessage = (message) => {
        props.addMessage({
            'conversation': props.match.params.conversation_id,
            'content': message
        }, props.dispatch)
    }

    const handleCloseAlertMessage = () => {
        props.closeAlertMessage(props.dispatch)
    }

    return (
        <Box sx={{ height: '100vh' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{ width: '90%', mx: 'auto', mb: 1, pt: '100px', display: 'flex', height: '100%', }}>
                <Snackbar
                    open={props.messageRed.errors.content}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertMessage}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertMessage} severity="error" sx={{ width: '100%', color: '#fff' }}>
                        Vous ne pouvez pas envoyer de message vide.
                    </Alert>
                </Snackbar>
                <Grid container component={Paper} elevation={5} sx={{ flexGrow: 1, mb: 5 }} >
                <Grid item xs={2} sx={{ boxShadow: 3 }}>
                    <List>
                        {props.messageRed.conversations.length === 0 ?
                            <ListItemButton
                                key={0}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h6"
                                        >
                                            Aucune conversation
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        : null}
                        {props.messageRed.conversations.map((conversation, key) =>
                        (<React.Fragment>
                            <ListItemButton
                                key={conversation.id}
                                href={`/message/${conversation.id}`}
                                sx={(conversation.id == props.match.params.conversation_id) ? { backgroundColor: 'primary.main' } : null}
                            >
                                <ListItemIcon sx={{ mr: 1, display: { xs: 'none', sm: 'block',  }}} backgroundColor='primary'>
                                    <Avatar p={15}>{Array.from(conversation.consumer.username)[0]}</Avatar>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h6"
                                        >
                                            {conversation.consumer.username}
                                        </Typography>
                                    }
                                />
                                {conversation.is_seen_shop ? '' : (<NotificationsActiveIcon color='primary' sx={{ display: { xs: 'none', sm: 'block',}}}/>) }
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>)
                        )}
                    </List>
                </Grid>
                {!props.match.params.conversation_id ?
                    <Grid container xs={10} alignItems="center" sx={{ height: '100%', mx: 'auto' }}>
                        <Card maxWidth={false} sx={{ mx: 'auto', border: "none", boxShadow: "none" }} elevation={0}>
                             <CardMedia
                                component="img"
                                height={350}
                                image={img_nodata}
                                alt="Paella dish"
                                sx={{ objectFit: "contain" }}
                              />
                              <Typography sx={{ textAlign: 'center' }} variant="h6">Sélectionnez une conversation.</Typography>
                        </Card>
                    </Grid>
                :
                    <Grid item xs={10} sx={{ height: '100%' }}>
                        <List sx={{ overflowY: 'auto', height: currentHeight === '23px' ? '90%' : '80%'}}>
                            {props.messageRed.message.map((msg, key) =>
                            (<ListItem key={key}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <ListItemText align={msg.is_shop ? 'right' : 'left'} primaryTypographyProps={{ component: 'p', style: { whiteSpace: 'pre-line' },  }} primary={msg.content}></ListItemText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ListItemText align={msg.is_shop ? 'right' : 'left'} secondary={moment(msg.created_at).format('Do MMMM YYYY, H:mm')}></ListItemText>
                                    </Grid>
                                </Grid >
                            </ListItem >))}
                            <div ref={divRef} />
                        </List >
                        <Divider />
                        <Grid container
                              sx={{ mx: 'auto', height: currentHeight === '23px' ? '10%' : '20%'}}
                        >
                            <Grid item xs={11} m='auto'>
                                <TextField
                                    id="send-message"
                                    label="Ecrire..."
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    inputRef={height}
                                    value={message}
                                    onChange={(event) => { setCurrentHeight(height.current.style.height); setMessage(event.target.value) }}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleSendMessage(message)}
                                                    edge="end"
                                                >
                                                    <SendIcon color='primary' />
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                   }
            </Grid>
            </Box>
        </Box>
    );
}

