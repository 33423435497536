import { connect } from "react-redux";

import ShopDeliveryOptionDetails from "../components/ShopDeliveryOptionDetails";

import { getShopDeliveryOption, addShopDeliveryOption, getShop } from '../actions/shopDeliveryOptionActions';

const mapStateToProps = state => ({
    user: state.auth,
    shopDeliveryOptionRed: state.shopDeliveryOptionRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShopDeliveryOption: (id, dispatch) =>
        dispatch(getShopDeliveryOption(id, dispatch)),
    addShopDeliveryOption: (item, dispatch) =>
        dispatch(addShopDeliveryOption(item, dispatch)),
    getShop: (dispatch) =>
        dispatch(getShop(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopDeliveryOptionDetails);
