import { connect } from "react-redux";

import Message from "../components/Message";

import { getConversations, getMessage, addMessage, closeAlertMessage } from '../actions/messageActions';

import {
    getConversationNotifications,
} from '../actions/headerActions';

const mapStateToProps = state => ({
    user: state.auth,
    messageRed: state.messageRed,
    headerRed: state.headerRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getConversations: (dispatch) =>
        dispatch(getConversations(dispatch)),
    getMessage: (conversation_id, dispatch) =>
        dispatch(getMessage(conversation_id, dispatch)),
    addMessage: (item, dispatch) =>
        dispatch(addMessage(item, dispatch)),
    getConversationNotifications: (dispatch) =>
        dispatch(getConversationNotifications(dispatch)),
    closeAlertMessage: (dispatch) =>
        dispatch(closeAlertMessage(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
