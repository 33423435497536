import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import logo_login from '../../assets/logo.png';
import gif_loading from '../../assets/gif_loading.gif';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.boutigou.com/" target="_blank" rel="noopener">
                Boutigou
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignIn(props) {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (event) => {
        setLoading(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        props.loginAction({ username: data.get('email'), password: data.get('password') }, props.dispatch).then(response => {
            if (response.non_field_errors) {
                setErrorMessage({ password: response.non_field_errors[0] });
                setLoading(false)
            } else if (typeof response.access !== 'undefined') {
                setLoading(false)
                props.authenticateAction(
                    response,
                    props.dispatch,
                    props.location.pathname,
                    props.history.push
                );
            } else if (response.code === 406) {
                setLoading(false)
                props.needValidateAction(response, props.dispatch);
                props.history.push('/validate')
            } else {
                setLoading(false)
                setErrorMessage({ email: "Compte inconnu", password: "Compte inconnu" });
            }
        });
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
            maxWidth="xs"
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="Loading..."
                    src={gif_loading}
                />
            </Backdrop>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    
                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: 133,
                        mb: 4
                    }}
                    alt="Boutigou"
                    src={logo_login}
                />
                <Typography component="h1" variant="h5">
                    Connexion
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Adresse email"
                        name="email"
                        autoComplete="email"
                        error={errorMessage.email}
                        helperText={errorMessage.email}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Mot de passe"
                        type="password"
                        id="password"
                        error={errorMessage.password}
                        helperText={errorMessage.password}
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Connexion
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/reset" variant="body2">
                                Mot de passe oublié?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/register" variant="body2">
                                {"Pas de compte? Enregistrez-vous."}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{
                position: 'fixed',
                bottom: 0,
            }} />
        </Grid>
    );
}