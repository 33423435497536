import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Hours from "react-hours";
import PaymentIcon from '@mui/icons-material/Payment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Shop(props) {

    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            props.getShop(props.dispatch);
            isInitialMount.current = false;
        }
    }, []);

    const handleCloseAlertShop = () => {
        props.closeAlertShop(props.dispatch)
    }

    return (
        <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Boutique</Typography>
                </Breadcrumbs>
                <Snackbar
                    autoHideDuration={6000}
                    onClose={handleCloseAlertShop}
                    open={props.shopRed.success_add || props.shopRed.success_address_add}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertShop}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertShop} severity="success" sx={{ width: '100%', color: '#fff' }}>
                        {props.shopRed.success_add ? 'Votre boutique a bien été mise à jour.' : null}
                        {props.shopRed.success_address_add ? "L'adresse de votre boutique a bien été mise à jour." : null}
                        {props.shopRed.success_payment_method_add ? "La liste des moyens de paiement acceptés a bien été mise à jour." : null}
                    </Alert>
                </Snackbar>
                <Container maxWidth={false} disableGutters sx={{ mt: 4, pb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardMedia
                                        image={props.shopRed.shop.cover.replace('http://django', '')}
                                        title="Background image"
                                        component="img"
                                />
                                <CardHeader
                                    avatar={<Avatar sx={{ width: 100, height: 100 }} src={props.shopRed.shop.profile.replace('http://django', '')} />}
                                    title={`${props.shopRed.shop.name} | ${props.shopRed.shop.category.name}`}
                                    action={
                                        <Tooltip title="Editer ma boutique">
                                            <IconButton onClick={() => { handleCloseAlertShop();  props.history.push(`/shop/${props.shopRed.shop.id}`) }}>
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                                <CardContent>
                                    <Typography variant="body2">{props.shopRed.shop.description}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card py='auto'>
                                <CardHeader
                                    sx={{ pb: 2 }}
                                    avatar={<Avatar sx={{ height: 30, width: 30 }} ><LocationOnIcon /></Avatar>}
                                    title={`${props.shopRed.shop.address}, ${props.shopRed.shop.zipcode} ${props.shopRed.shop.city}, ${props.shopRed.shop.country}`}
                                    action={
                                        <Tooltip title="Editer l'adresse de ma boutique">
                                            <IconButton onClick={() => { handleCloseAlertShop();  props.history.push(`/shop/address/${props.shopRed.shop.id}`) }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card py='auto'>
                                <CardHeader
                                    sx={{ pb: 2 }}
                                    avatar={<Avatar sx={{ height: 30, width: 30 }} ><PaymentIcon /></Avatar>}
                                    title="Moyens de paiement acceptés"
                                    action={
                                        <Tooltip title="Editer la liste des moyens de paiement acceptés">
                                            <IconButton onClick={() => { handleCloseAlertShop();  props.history.push(`/shop/payment_method/${props.shopRed.shop.id}`) }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                                <CardContent>
                                    <List style={{ marginLeft: 30 }}>
                                        { props.shopRed.shop.payment_methods.map((op) =>
                                        <ListItem disablePadding key={op.id}>
                                            <ListItemIcon>
                                                <PaymentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={op.type === false ? `En ligne via ${op.name}` : `Sur place via ${op.name}`} />
                                        </ListItem>)}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

