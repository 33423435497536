import * as types from "../types/actionTypes";

const initialState = {
    activities: [],
    activity: {},
    errors: {},
    processing: false,
    success_add: null,
    success_delete: null,
    success_edit: null,
    fetching: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SHOP_ACTIVITIES:
            return {
                ...state,
                activities: action.payload,
                errors: {},
                fetching: false,
                activity: {},
            };
        case types.GET_SHOP_ACTIVITY:
            return {
                ...state,
                success_add: null,
                processing: false,
                activity: action.payload,
                errors: {},
                fetching: false,
            };
        case types.GET_EMPTY_SHOP_ACTIVITY:
            return {
                ...state,
                success_add: null,
                activity: {},
                errors: {},
                processing: false,
                fetching: false,
            };
        case types.ADD_SHOP_ACTIVITY:
            return {
                ...state,
                errors: {},
                processing: false,
                success_add: true,
            };
        case types.FAILED_ADD_SHOP_ACTIVITY:
            return {
                ...state,
                errors: action.payload,
                processing: false
            };
        case types.DELETE_SHOP_ACTIVITY:
            return {
                ...state,
                errors: {},
                processing: false,
                success_delete: true,
            };
        case types.FAILED_DELETE_SHOP_ACTIVITY:
            return {
                ...state,
                errors: action.payload,
                processing: false
            };
        case types.PROCESSING:
            return {
                ...state,
                processing: true
            };
        case types.CLOSE_ALERT_SHOP_ACTIVITY:
            return {
                ...state,
                success_add: null,
                success_delete: null,
                success_edit: null,
            };
        case types.FETCHING:
            return {
                ...state,
                fetching: true
            };
        default:
            return state;
  }
};