import { connect } from "react-redux";

import ShopPage from "../components/ShopPage";

import { whoAmI, logoutAction } from '../actions/auth/authActions';
import { getShop, addShop, getShopCategoryOptions } from '../actions/shopActions';

const mapStateToProps = state => ({
    user: state.auth,
    shopRed: state.shopRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
  whoAmI: (token, dispatch) =>
        dispatch(whoAmI(token, dispatch)),
    logoutAction: (dispatch, push) =>
        dispatch(logoutAction(dispatch, push)),
    getShop: (dispatch) =>
        dispatch(getShop(dispatch)),
    addShop: (item, dispatch) =>
        dispatch(addShop(item, dispatch)),
    getShopCategoryOptions: (dispatch) =>
        dispatch(getShopCategoryOptions(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
