import { connect } from "react-redux";

import Order from "../components/Order";

import { getOrders, closeAlertOrder } from '../actions/orderActions';

const mapStateToProps = state => ({
    orderRed: state.orderRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getOrders: (dispatch) =>
        dispatch(getOrders(dispatch)),
    closeAlertOrder: (dispatch) =>
        dispatch(closeAlertOrder(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);
