import { connect } from "react-redux";

import Help from "../components/Help";

const mapStateToProps = state => ({
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Help);
