import * as types from "../types/actionTypes";

const initialState = {
    conversationNotifs: [],
    reviewNotifs: [],
    orderNotifs: [],
    errors: false,
    isDrawerOpen: false,
    shop: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CONVERSATION_NOTIFICATIONS:
            return {
                ...state,
                conversationNotifs: action.payload,
                errors: false,
            };
        case types.GET_REVIEW_NOTIFICATIONS:
            return {
                ...state,
                reviewNotifs: action.payload,
                errors: false,
            };
        case types.GET_ORDER_NOTIFICATIONS:
            return {
                ...state,
                orderNotifs: action.payload,
                errors: false,
            };
        case types.DRAWER_OPEN:
            return {
                ...state,
                isDrawerOpen: true
            };
        case types.DRAWER_CLOSE:
            return {
                ...state,
                isDrawerOpen: false
            };
        case types.GET_SHOP_HEADER:
            if (action.payload[0]) {
                var shop_data = action.payload[0]
            } else {
                var shop_data = {}
            }
            return {
                ...state,
                shop: shop_data,
            };
        case types.FAILED_GET_SHOP_HEADER:
            return {
                ...state,
                errors: action.payload,
            };
        default:
          return state;
  }
};