import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getProducts(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/product/`)
            .then((res) => { return dispatch({ type: types.GET_PRODUCTS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_PRODUCTS, payload: error.response.data })})
    };
}

function getProduct(id, dispatch) {
    dispatch({ type: types.FETCHING });
    return async function () {
        if (id !== 'add') {
            axiosAPI
                .get(`${url}/product/${id}`)
                .then((res) => { return dispatch({ type: types.GET_PRODUCT, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_GET_PRODUCT, payload: error.response.data }) })
        } else {
            return dispatch({ type: types.GET_EMPTY_PRODUCT })
        }
    };
}

function addProduct(item, dispatch) {
    dispatch({ type: types.PROCESSING })
    if (item.id) {
        return async function () {
            axiosAPI
                .put(`${url}/product/${item.id}/`, item, { headers: { "Content-Type": "multipart/form-data" } })
                .then((res) => { return dispatch({ type: types.ADD_PRODUCT, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_ADD_PRODUCT, payload: error.response.data }) })
        };
    } else {
        return async function () {
            axiosAPI
                .post(`${url}/product/`, item, { headers: { "Content-Type": "multipart/form-data" } })
                .then((res) => { return dispatch({ type: types.ADD_PRODUCT, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_ADD_PRODUCT, payload: error.response.data }) })
        };
    }
}

function getProductCategoryOptions(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/product_category/`)
            .then((res) => { return dispatch({ type: types.GET_PRODUCT_CATEGORY_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_PRODUCT_CATEGORY_OPTIONS, payload: error.response.data }) })
    };
}

function getLabelOptions(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/label/`)
            .then((res) => { return dispatch({ type: types.GET_LABEL_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_LABEL_OPTIONS, payload: error.response.data }) })
    };
}

function getProductUnitOptions(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/product_unit/`)
            .then((res) => { return dispatch({ type: types.GET_UNIT_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_UNIT_OPTIONS, payload: error.response.data }) })
    };
}

function closeAlertProduct(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_PRODUCT
    })
}


export {
    getProducts,
    getProduct,
    addProduct,
    getProductCategoryOptions,
    getLabelOptions,
    getProductUnitOptions,
    closeAlertProduct
};
