import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import HelpIcon from '@mui/icons-material/Help';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MailIcon from '@mui/icons-material/Mail';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SellIcon from '@mui/icons-material/Sell';
import ButtonBase from '@mui/material/ButtonBase'
import Chip from '@mui/material/Chip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { SecondaryListItems, MainListItems } from "./listItems";
import { Drawer } from "./Drawer";
import { AppBar } from "./AppBar";



export default function Header(props) {
    const theme = useTheme();
    let location = useLocation();
    const [openAv, setOpenAv] = React.useState(false);
    const [openDisplayShop, setOpenDisplayShop] = React.useState(false)
    const anchorRef = React.useRef(null);

    React.useEffect(() => {
        setInterval(() => {
            if ((props.history.location.pathname !== '/login') && (props.history.location.pathname !== '/reset') && (props.history.location.pathname !== '/register') && (props.history.location.pathname !== '/validate') && (props.history.location.pathname !== '/build_shop')) {
                props.getConversationNotifications(props.dispatch);
                props.getReviewNotifications(props.dispatch);
                props.getOrderNotifications(props.dispatch)
            }
        }, 5000);
    }, [])

    React.useEffect(() => {
        if (props.authenticated === false) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            if (!['/login', '/register', '/reset', '/validate'].includes(window.location.pathname) ) {
                window.location.href = "/login";
            }
        }
    }, [])

    React.useEffect(() => {
        if ((typeof (props.headerRed.shop.id) === 'undefined'
            || props.headerRed.shop.prestate === false)
            && props.history.location.pathname !== '/build_shop' 
            && props.history.location.pathname !== '/login'
            && props.history.location.pathname !== '/register'
            && props.history.location.pathname !== '/reset'
        ) {
            window.location.href = '/build_shop'
        }
    }, [props.headerRed.shop])

    const handleToggle = () => {
        setOpenAv((prevOpen) => !prevOpen);
    };

    const handleDisplayShop = () => {
        setOpenDisplayShop(true)
    };

    const handleCloseDisplayShop = () => {
        setOpenDisplayShop(false)
    };
    
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenAv(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenAv(false);
        } else if (event.key === "Escape") {
            setOpenAv(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(openAv);
    React.useEffect(() => {
        if (prevOpen.current === true && openAv === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = openAv;
    }, [openAv]);

    const toggleDrawer = () => {
        props.handleDrawerState(!props.headerRed.isDrawerOpen, props.dispatch);
    };

    React.useEffect(() => {
        if ((window.location.pathname !== '/login') && (window.location.pathname !== '/reset') && (window.location.pathname !== '/validate') && (window.location.pathname !== '/register')) {
            props.getShop(props.dispatch);
        }
    }, [location]);

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';
    
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1 
        }
    };

    return (props.authenticated && (window.location.pathname !== '/login') && (window.location.pathname !== '/reset') && (window.location.pathname !== '/validate') && (window.location.pathname !== '/register') && (window.location.pathname !== '/build_shop')) ? (
        <div style={{ position: "fixed", zIndex: 1000 }}>
            <CssBaseline />
            <AppBar position="fixed" open={props.headerRed.isDrawerOpen} {...appBar }>
                <Toolbar
                    sx={{
                        pr: "24px",
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            color: 'text.primary',
                            bgcolor: 'grey.100',
                            marginRight: "36px",
                            ...(props.headerRed.isDrawerOpen && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                        <Chip
                            label={props.headerRed.shop && props.headerRed.shop.state === true ? 'En ligne' : 'Hors ligne'}
                            icon={props.headerRed.shop && props.headerRed.shop.state === true ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            color="primary"
                            variant={props.headerRed.shop && props.headerRed.shop.state === true ? 'outlined' : 'filled'}
                        />
                        <Chip
                            label={"Besoin d'aide"}
                            onDelete={() => window.location.href = '/help'}
                            onClick={() => window.location.href = '/help'}
                            deleteIcon={<HelpIcon />}
                            color="primary"
                            variant={'filled'}
                        />
                        <IconButton
                            ref={anchorRef}
                            id="composition-button"
                            disableRipple
                            aria-controls={openAv ? "composition-menu" : undefined}
                            aria-expanded={openAv ? "true" : undefined}
                            aria-haspopup="true"
                            href={'/order'}
                            variant="contained"
                            sx={{ ml: '20px', color: 'text.primary', bgcolor: props.headerRed.isDrawerOpen ? iconBackColorOpen : iconBackColor }}
                        >
                            <Tooltip title="Voir mes commandes">
                                <Badge badgeContent={props.headerRed.orderNotifs.length} color="primary">
                                    <SellIcon color='primary' />
                                </Badge>
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            ref={anchorRef}
                            id="composition-button"
                            disableRipple
                            aria-controls={openAv ? "composition-menu" : undefined}
                            aria-expanded={openAv ? "true" : undefined}
                            aria-haspopup="true"
                            href={'/message'}
                            variant="contained"
                            sx={{ ml: '20px', color: 'text.primary', bgcolor: props.headerRed.isDrawerOpen ? iconBackColorOpen : iconBackColor }}
                        >
                            <Tooltip title="Voir mes messages">
                                <Badge badgeContent={props.headerRed.conversationNotifs.length} color="primary">
                                    <MailIcon color='primary' />
                                </Badge>
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            ref={anchorRef}
                            id="composition-button"
                            disableRipple
                            aria-controls={openAv ? "composition-menu" : undefined}
                            aria-expanded={openAv ? "true" : undefined}
                            aria-haspopup="true"
                            href={'/review'}
                            variant="contained"
                            sx={{ ml: '20px', color: 'text.primary', bgcolor: props.headerRed.isDrawerOpen ? iconBackColorOpen : iconBackColor }}
                        >
                            <Tooltip title="Voir mes avis">
                                <Badge badgeContent={props.headerRed.reviewNotifs.length} color="primary">
                                    <RateReviewIcon color='primary' />
                                </Badge>
                            </Tooltip>
                        </IconButton>
                        <ButtonBase
                            sx={{
                                ml: '20px',
                                p: 0.25,
                                bgcolor: props.headerRed.isDrawerOpen ? iconBackColorOpen : 'transparent',
                                borderRadius: 1,
                                '&:hover': { bgcolor: 'secondary.lighter' }
                            }}
                            aria-label="open profile"
                            ref={anchorRef}
                            aria-controls={openAv ? "composition-menu" : undefined}
                            aria-expanded={openAv ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            id="composition-button"
                        >
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                                <Avatar alt="profile user" color='primary' sx={{ width: 32, height: 32, color: 'primary.main' }} />
                                <Typography variant="subtitle1">{props.user.user.username}</Typography>
                            </Stack>
                        </ButtonBase>
                        
                        <Popper
                            open={openAv}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom-start"
                                                ? "left top"
                                                : "left bottom",
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={openAv}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem onClick={() => {window.location.href = '/account'}}>Mon compte</MenuItem>
                                                <MenuItem onClick={() => {window.location.href = '/profile'}}>Mon profil</MenuItem>
                                                <MenuItem onClick={() => props.logoutAction(props.dispatch, props.history.push)}>Déconnexion</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={props.headerRed.isDrawerOpen}
                style={{ height: window.innerHeight }}
            >
                <Toolbar
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <MainListItems open={props.headerRed.isDrawerOpen} theme={theme}/>
                    <Divider sx={{ my: 1 }} />
                    <SecondaryListItems open={props.headerRed.isDrawerOpen} theme={theme}/>
                </List>
            </Drawer>
            <Modal
                open={openDisplayShop}
                onClose={handleCloseDisplayShop}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>
                </Box>
            </Modal>
        </div>
    ) : null;
}
