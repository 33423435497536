import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import gif_loading from '../assets/gif_loading.gif';
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";


export default function ShopPage(props) {

    const isInitialMount = React.useRef(true);
    const [name, setName] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [address_secondary, setAddress_secondary] = React.useState(null);
    const [city, setCity] = React.useState(null);
    const [zipcode, setZipcode] = React.useState(null);
    const [country, setCountry] = React.useState(null);

 
    React.useEffect(() => {
        if (isInitialMount.current) {
            setAddress(props.shopRed.shop.address);
            setAddress_secondary(props.shopRed.shop.address_secondary);
            setCity(props.shopRed.shop.city);
            setZipcode(props.shopRed.shop.zipcode);
            setCountry(props.shopRed.shop.country);
            isInitialMount.current = false;
        }
    }, []);


    React.useEffect(() => {
        if (props.shopRed.success_address_add === true) {
            props.history.push('/shop')
        }
    }, [props.shopRed.success_address_add]);

    const handleShopAddress = () => {
        const item = {
            'id': props.shopRed.shop.id,
            'address': address,
            'address_secondary': address_secondary,
            'zipcode': zipcode,
            'city': city,
            'country': country
        };
        props.addShopAddress(item, props.dispatch)
    }

    return (
        isInitialMount.current ?
            (<Box sx={{ width: '90%', pt: '100px', mx: 'auto' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
        (<Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant="h4" underline="hover" color="inherit" href="/shop" style={{ textDecoration: 'none' }}>
                        Boutique
                    </Link>
                    <Typography variant="h4" color="text.primary" >Edition de l'adresse</Typography>
                </Breadcrumbs>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
                    open={props.shopRed.processing}
                >
                    <Box
                        component="img"
                        sx={{
                            height: 250,
                            mb: 4
                        }}
                        alt="Loading..."
                        src={gif_loading}
                    />
                </Backdrop>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="address"
                                value={address || ''}
                                onChange={(e) => setAddress(e.target.value)}
                                label="Adresse de la boutique"
                                name="address"
                                helperText={props.shopRed.errors.address ? props.shopRed.errors.address.message : null}
                                error={props.shopRed.errors.address ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="address_secondary"
                                value={address_secondary || ''}
                                onChange={(e) => setAddress_secondary(e.target.value)}
                                label="Complément"
                                name="address_secondary"
                                helperText={props.shopRed.errors.address_secondary ? props.shopRed.errors.address_secondary.message : null}
                                error={props.shopRed.errors.address_secondary ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="given-name"
                                name="zipcode"
                                value={zipcode || ''}
                                onChange={(e) => setZipcode(e.target.value)}
                                required
                                fullWidth
                                id="zipcode"
                                label="Code postal"
                                helperText={props.shopRed.errors.zipcode ? props.shopRed.errors.zipcode.message : null}
                                error={props.shopRed.errors.zipcode ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                id="city"
                                label="Ville"
                                name="city"
                                value={city || ''}
                                onChange={(e) => setCity(e.target.value)}
                                helperText={props.shopRed.errors.city ? props.shopRed.errors.city.message : null}
                                error={props.shopRed.errors.city ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                id="country"
                                label="Pays"
                                name="country"
                                value={country || ''}
                                onChange={(e) => setCountry(e.target.value)}
                                helperText={props.shopRed.errors.country ? props.shopRed.errors.country.message : null}
                                error={props.shopRed.errors.country ? true : false}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            href='/shop'
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                            disabled={props.shopRed.processing}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={handleShopAddress}
                            disabled={props.shopRed.processing}
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>)
    );
}

