import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import gif_loading from '../assets/gif_loading.gif';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Account(props) {

    const isInitialMount = React.useRef(true);
    const [username, setUserName] = React.useState(null);
    const [first_name, setFirstName] = React.useState(null);
    const [last_name, setLastName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);


    React.useEffect(() => {
        if (isInitialMount.current) {
            props.getProfile(props.dispatch);
            isInitialMount.current = false;
        };
    }, []);

    React.useEffect(() => {
        if (JSON.stringify(props.profileRed.profile) != JSON.stringify({})) {
            setUserName(props.profileRed.profile.username);
            setFirstName(props.profileRed.profile.first_name);
            setLastName(props.profileRed.profile.last_name);
            setEmail(props.profileRed.profile.email);
        }
    }, [props.profileRed.profile]);

    React.useEffect(() => {
        if (props.profileRed.success) {
            props.getProfile(props.dispatch);
            if ((email !== null) && (email !== props.profileRed.profile.email)) {
                props.logoutAction(props.dispatch, props.history.push)
            } else {
                props.whoAmI(localStorage.getItem("access_token"), props.dispatch);
            }
        }
    }, [props.profileRed.success]);

    const handleProfile = () => {
        const item = {
            'id': props.profileRed.profile.id,
            'username': username,
            'first_name': first_name,
            'last_name': last_name,
            'email': email
        };
        props.addProfile(item, props.dispatch)
    }

    const handleCloseAlertProfile = () => {
        props.closeAlertProfile(props.dispatch)
    }

    return (
        isInitialMount.current ?
            (<Box sx={{ width: '90%', mx: 'auto', pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
            (<Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <Box sx={{
                    mb: 1,
                    width: '90%',
                    mx: 'auto'
                }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props.profileRed.loading}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 250,
                                mb: 4
                            }}
                            alt="loading"
                            src={gif_loading}
                        />
                    </Backdrop>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h4" color="text.primary">Mon profil</Typography>
                    </Breadcrumbs>
                    <Snackbar
                        open={props.profileRed.success}
                        key={'top' + 'center'}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        action={
                            <React.Fragment>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    sx={{ p: 0.5 }}
                                    onClick={() => console.log('rrff')}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                    >
                        <Alert onClose={handleCloseAlertProfile} severity="success" sx={{ width: '100%', color: '#fff' }}>
                            Votre profil a été mis à jour avec succès.
                        </Alert>
                    </Snackbar>
                    <Alert severity="info" variant="outlined">Si vous modifiez votre adresse mail, un code vous sera envoyé à cette même adresse afin de la valider. Vous serez déconnecté puis invité à renseigner ce code.</Alert>
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="username"
                                    label="Nom d'utilisateur"
                                    name="username"
                                    value={username || ''}
                                    onChange={(e) => setUserName(e.target.value)}
                                    helperText={props.profileRed.errors.username ? props.profileRed.errors.username.message : null}
                                    error={props.profileRed.errors.username ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    id="first_name"
                                    label="Prénom"
                                    name="first_name"
                                    value={first_name || ''}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    helperText={props.profileRed.errors.first_name ? props.profileRed.errors.first_name.message : null}
                                    error={props.profileRed.errors.first_name ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="last_name"
                                    required
                                    fullWidth
                                    id="last_name"
                                    label="Nom"
                                    value={last_name || ''}
                                    onChange={(e) => setLastName(e.target.value)}
                                    helperText={props.profileRed.errors.last_name ? props.profileRed.errors.last_name.message : null}
                                    error={props.profileRed.errors.last_name ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    id="email"
                                    label="Courriel"
                                    name="email"
                                    value={email || ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    helperText={props.profileRed.errors.email ? props.profileRed.errors.email.message : null}
                                    error={props.profileRed.errors.email ? true : false}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                href='/'
                                variant="outlined"
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Revenir à l'accueil
                            </Button>
                            <Button
                                onClick={handleProfile}
                                variant="contained"
                                sx={{ mt: 3, ml: 1 }}
                                disabled={props.profileRed.loading}
                            >
                                Valider
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>)
    );
}

