import { connect } from "react-redux";

import ShopActivity from "../components/ShopActivity";

import { getShopActivities, closeAlertShopActivity } from '../actions/shopActivityActions';

const mapStateToProps = state => ({
    user: state.auth,
    shopActivityRed: state.shopActivity,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShopActivities: (dispatch) =>
        dispatch(getShopActivities(dispatch)),
    closeAlertShopActivity: (dispatch) =>
        dispatch(closeAlertShopActivity(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopActivity);
