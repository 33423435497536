import * as types from "../types/actionTypes";

const initialState = {
    shop: {},
    shop_onboarding: {},
    shop_home: {},
    processing: false,
    successRequestDeployment: null,
    shop_events: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SHOP:
            return {
                ...state,
                shop: action.payload[0],
                errors: false,
                processing: false
            };
        case types.GET_SHOP_ONBOARDING:
            return {
                ...state,
                processing: false,
                shop_onboarding: action.payload,
            };
        case types.PROCESSING:
            return {
                ...state,
                processing: true,
                successRequestDeployment: null,
            };
        case types.REQUEST_SHOP_DEPLOYMENT:
            return {
                ...state,
                processing: false,
                successRequestDeployment: true
            };
        case types.GET_SHOP_EVENTS:
            const transformedData = action.payload.map(item => ({
                id: item.id,
                title: `Commande ${item.sub_order.id}`,
                order: item.sub_order.id,
                consumer: item.consumer,
                consumer_pk: item.consumer_pk,
                description: item.description,
                start: new Date(`${item.date} ${item.begin}`),
                end: new Date(`${item.date} ${item.end}`)
            }));
            return {
                ...state,
                shop_events: transformedData
            };
        case types.GET_SHOP_HOME:
            return {
                ...state,
                shop_home: action.payload
            };
        default:
          return state;
  }
};