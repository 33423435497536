import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MuiTable from '../utils/MuiTable';
import Rating from '@mui/material/Rating';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@mui/styles';
import MuiAlert from '@mui/material/Alert';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Order(props) {

    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getOrders(props.dispatch);
        }
    }, []);

    const handleCloseAlertOrder = () => {
        props.closeAlertOrder(props.dispatch)
    }

    const useStyles = makeStyles({
        blinkingText: {
            animation: '$blinkingAnimation 3s infinite',
        },
        '@keyframes blinkingAnimation': {
            '0%': {
                opacity: 1,
            },
            '50%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
    });

    const classes = useStyles();

    const columns = [
        {
            name: "id",
            label: "Numéro de commande",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[9] === true) {
                        return (value);
                    } else {
                        return (
                        <Typography className={classes.blinkingText} color="primay" variant="h6">
                            {value}
                        </Typography>
                    );
                    }
                }
            }
        },
        {
            name: "consumer",
            label: "Client",
        },
        {
            name: "accepted",
            label: "Accepted",
            options: {
                display: 'excluded',
                filter: false,
                download: false
            }
        },
        {
            name: "refused",
            label: "Refused",
            options: {
                display: 'excluded',
                filter: false,
                download: false
            }
        },
        {
            name: "accepted",
            label: "Mon statut",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[2] === true) {
                        return (<Chip key={value} label="Acceptée" color='success' sx={{ mx: '1px', padding: '1px' }} />);
                    } else if (tableMeta.rowData[3] === true) {
                        return (<Chip key={value} label="Refusée" color='error' sx={{ mx: '1px', padding: '1px' }} />);
                    } else {
                        return (<Chip key={value} label="En attente" color='info' sx={{ mx: '1px', padding: '1px' }} />);
                    }
                },
                filterOptions: {
                    renderValue: val => {
                      if (val) {
                        return "Acceptée";
                      }
                      return "En attente";
                    }
                },
                toStringOverride: (rawDataValue, rowData) => {
                    if (rowData.data[2] == true) {
                        return "Acceptée";
                    } else if (rowData.data[3] == true) {
                        return "Refusée";
                    };
                    return "En attente";
                }
            }
        },
        {
            name: "global_status",
            label: "Statut Général",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value === 'En attente') {
                        return (<Chip key={value} label={value} color='info' sx={{ mx: '1px', padding: '1px' }} />);
                    } else if (value === 'Echec') {
                        return (<Chip key={value} label={value} color='error' sx={{ mx: '1px', padding: '1px' }} />);
                    } else if (value === 'Validée') {
                        return (<Chip key={value} label={value} color='success' sx={{ mx: '1px', padding: '1px' }} />);
                    } else if (value === 'Complétée') {
                        return (<Chip key={value} label={value} color='success' sx={{ mx: '1px', padding: '1px' }} />);
                    } else {
                        return (<Chip key={value} label={value} color='error' sx={{ mx: '1px', padding: '1px' }} />);
                    }
                },
            }
        },
        {
            name: "created_at",
            label: "Date de création",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{moment(value).format('Do MMMM YYYY, H:mm')}</div>;
                }
            }
        },
        {
            name: "updated_at",
            label: "Date de modification",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{moment(value).format('Do MMMM YYYY, H:mm')}</div>;
                },
                filterOptions: {
                    renderValue: val => {
                      return moment(val).format('Do MMMM YYYY');
                    }
                }
            }
        },
        {
            name: "amount",
            label: "Montant (euros)",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return `${value/100} €`;
                },
                toStringOverride: (rawDataValue) => {
                    return rawDataValue/100
                }
            }
        },
        {
            name: "is_seen",
            label: "Is Seen",
            options: {
                display: 'excluded',
                filter: false,
                download: false
            }
        },
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                download: false,
                setCellProps: () => ({ align: "right" }),
                customHeadRender: () => <th key={10} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <IconButton aria-label="delete" onClick={() => { handleCloseAlertOrder(); props.history.push(`/order/${value}`) }} color="primary">
                        <MoreVertIcon />
                    </IconButton>;
                }
            }
        }]

    const options = {
        
    };

    return (
        <Box sx={{ pt: '100px', pb: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Commandes</Typography>
                </Breadcrumbs>
            </Box>
            <Snackbar
                open={props.orderRed.success_add}
                key={'top' + 'center'}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={handleCloseAlertOrder}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert onClose={handleCloseAlertOrder} severity="success" sx={{ width: '100%', color: '#fff' }}>
                    Votre modification a bien été prise en compte.
                </Alert>
            </Snackbar>
            <Paper sx={{ width: '90%', mx: 'auto' }}>
                <MuiTable
                    data={props.orderRed.orders}
                    columns={columns}
                    options={options}
                    filename={'BOUTIGOU-Commandes.csv'}
            />
            </Paper>
        </Box>
    );
}

