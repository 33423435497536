import React from "react";
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { store, persistor } from "./store";
import { AuthenticatedRoute, PrivateRoute } from "./customRoutes/ProtectedRoutes";
import HomePage from "./containers/HomePageContainer";
import Login from "./containers/auth/LoginContainer";
import Register from "./containers/auth/RegisterContainer";
import Validate from "./containers/auth/ValidateContainer";
import PasswordReset from "./containers/auth/PasswordResetContainer";

import Header from './containers/HeaderContainer';
import BuildShop from './containers/BuildShopContainer';
import Shop from './containers/ShopContainer';
import ShopPage from './containers/ShopPageContainer';
import ShopPageAddress from './containers/ShopPageAddressContainer';
import ShopPagePaymentMethod from './containers/ShopPagePaymentMethodContainer';
import Product from './containers/ProductContainer';
import ProductPage from './containers/ProductPageContainer';
import Review from './containers/ReviewContainer';
import ReviewPage from './containers/ReviewPageContainer';
import Message from './containers/MessageContainer';
import Subscription from './containers/SubscriptionContainer';
import Account from './containers/AccountContainer';
import Profile from './containers/ProfileContainer';
import ShopDeliveryOption from './containers/ShopDeliveryOptionContainer';
import ShopDeliveryOptionDetails from './containers/ShopDeliveryOptionDetailsContainer';
import ShopPartner from './containers/ShopPartnerContainer';
import ShopPartnerPage from './containers/ShopPartnerPageContainer';
import Terms from './containers/TermsContainer';
import Help from './containers/HelpContainer';
import Order from './containers/OrderContainer';
import OrderPage from './containers/OrderPageContainer';
import ShopActivity from './containers/ShopActivityContainer';
import ShopActivityDetail from './containers/ShopActivityDetailContainer';

import ThemeCustomization from './theme';
import history from "./history";

ReactDOM.render(
    <ThemeCustomization>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
            <Header history={history} />
                <div>
                    <Switch>
                    <PrivateRoute exact path="/" component={HomePage} />
                    <PrivateRoute exact path="/build_shop" component={BuildShop} />
                    <PrivateRoute exact path="/shop" component={Shop} />
                    <PrivateRoute exact path="/shop/:shop_id" component={ShopPage} />
                    <PrivateRoute exact path="/shop/address/:shop_id" component={ShopPageAddress} />
                    <PrivateRoute exact path="/shop/payment_method/:shop_id" component={ShopPagePaymentMethod} />
                    <PrivateRoute exact path="/product" component={Product} />
                    <PrivateRoute exact path="/product/:product_id" component={ProductPage} />
                    <PrivateRoute exact path="/review" component={Review} />
                    <PrivateRoute exact path="/review/:review_id" component={ReviewPage} />
                    <PrivateRoute exact path="/message" component={Message} />
                    <PrivateRoute exact path="/message/:conversation_id" component={Message} />
                    <PrivateRoute exact path="/subscription" component={Subscription} />
                    <PrivateRoute exact path="/account" component={Account} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/delivery_option" component={ShopDeliveryOption} />
                    <PrivateRoute exact path="/delivery_option/:shop_delivery_option_id" component={ShopDeliveryOptionDetails} />
                    <PrivateRoute exact path="/partner" component={ShopPartner} />
                    <PrivateRoute exact path="/partner/:shop_partner_id" component={ShopPartnerPage} />
                    <PrivateRoute exact path="/terms" component={Terms} />
                    <PrivateRoute exact path="/help" component={Help} />
                    <PrivateRoute exact path="/order" component={Order} />
                    <PrivateRoute exact path="/order/:order_id" component={OrderPage} />
                    <PrivateRoute exact path="/activity" component={ShopActivity} />
                    <PrivateRoute exact path="/activity/:activity_id" component={ShopActivityDetail} />
                    <AuthenticatedRoute exact path="/login" component={Login} />
                    <AuthenticatedRoute exact path="/register" component={Register} />
                    <AuthenticatedRoute exact path="/validate" component={Validate} />
                    <AuthenticatedRoute exact path="/reset" component={PasswordReset} />
                    <Route exact path="/signout" render={() => <Redirect to="/login" />} />
                    <Redirect from="/login/" to="/login" />
                    <Redirect from="*" to="/" />
                    </Switch>
                </div>
            </Router>
        </PersistGate>
        </Provider>
    </ThemeCustomization>,
  document.getElementById("root")
);


