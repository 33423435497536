import { connect } from "react-redux";

import Validate from "../../components/auth/Validate";
import {
    postValidateAction,
    whoAmI,
    resendValidateAction
} from "../../actions/auth/authActions.js";

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    postValidateAction: (data, dispatch) => dispatch(postValidateAction(data, dispatch)),
    resendValidateAction: (data, dispatch) => dispatch(resendValidateAction(data, dispatch)),
    whoAmI: (token, dispatch) => dispatch(whoAmI(token, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Validate);
