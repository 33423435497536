import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getReviews(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/review/`)
            .then((res) => { console.log('krnfoij'); return dispatch({ type: types.GET_REVIEWS, payload: res.data }) })
            .catch((error) => { console.log(error); return dispatch({ type: types.FAILED_GET_REVIEWS, payload: error })})
    };
}

function getReview(id, dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/review/${id}`)
            .then((res) => { return dispatch({ type: types.GET_REVIEW, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_REVIEW, payload: error }) })
    };
}

function addReview(item, dispatch) {
    if (item.id) {
        dispatch({ type: types.PROCESSING })
        return async function () {
            axiosAPI
                .put(`${url}/review/${item.id}/`, item)
                .then((res) => { return dispatch({ type: types.ADD_REVIEW, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_ADD_REVIEW, payload: error.response.data }) })
        };
    }
}

function closeAlertReview(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_REVIEW
    })
}

export {
    getReviews,
    getReview,
    addReview,
    closeAlertReview
};
