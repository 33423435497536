import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getShopActivities(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_activity/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_ACTIVITIES, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_ACTIVITIES, payload: error.response.data })})
    };
}

function getShopActivity(id, dispatch) {
    dispatch({ type: types.FETCHING });
    return async function () {
        if (id !== 'add') {
            axiosAPI
                .get(`${url}/shop_activity/${id}`)
                .then((res) => { return dispatch({ type: types.GET_SHOP_ACTIVITY, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_ACTIVITY, payload: error.response.data }) })
        } else {
            return dispatch({ type: types.GET_EMPTY_SHOP_ACTIVITY })
        }
    };
}

function addShopActivity(item, dispatch) {
    dispatch({ type: types.PROCESSING })
    if (item.id) {
        return async function () {
            axiosAPI
                .put(`${url}/shop_activity/${item.id}/`, item)
                .then((res) => { return dispatch({ type: types.ADD_SHOP_ACTIVITY, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_ADD_SHOP_ACTIVITY, payload: error.response.data }) })
        };
    } else {
        return async function () {
            axiosAPI
                .post(`${url}/shop_activity/`, item)
                .then((res) => { return dispatch({ type: types.ADD_SHOP_ACTIVITY, payload: res.data }) })
                .catch((error) => { return dispatch({ type: types.FAILED_ADD_SHOP_ACTIVITY, payload: error.response.data }) })
        };
    }
}

function deleteShopActivity(item_id, dispatch) {
    dispatch({ type: types.PROCESSING })
    return async function () {
        axiosAPI
            .delete(`${url}/shop_activity/${item_id}/`)
            .then((res) => { return dispatch({ type: types.DELETE_SHOP_ACTIVITY, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_DELETE_SHOP_ACTIVITY, payload: error.response.data }) })
    };
}

function closeAlertShopActivity(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_SHOP_ACTIVITY
    })
}


export {
    getShopActivities,
    getShopActivity,
    addShopActivity,
    deleteShopActivity,
    closeAlertShopActivity
};
