import { connect } from "react-redux";

import PasswordReset from "../../components/auth/PasswordReset";

import {
    getTokenReset,
    resetPassword
} from "../../actions/auth/passwordResetActions.js";

const mapStateToProps = state => ({
    passwordReset: state.passwordReset
});

const mapDispatchToProps = dispatch => ({
    getTokenReset: (data, dispatch) =>
        dispatch(getTokenReset(data, dispatch)),
    resetPassword: (data, dispatch) =>
        dispatch(resetPassword(data, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);