import { connect } from "react-redux";

import ShopPartner from "../components/ShopPartner";

import {
    getShopPartners,
    closeAlertShopPartner } from '../actions/shopPartnerActions';

const mapStateToProps = state => ({
    user: state.auth,
    shopPartnerRed: state.shopPartnerRed,
    headerRed: state.headerRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShopPartners: (dispatch) =>
        dispatch(getShopPartners(dispatch)),
    closeAlertShopPartner: (dispatch) =>
        dispatch(closeAlertShopPartner(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPartner);
