import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getAccount(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop/`)
            .then((res) => { return dispatch({ type: types.GET_ACCOUNT, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_ACCOUNT, payload: error.response.data })})
    };
}

function addAccount(shop_id, dispatch) {
    dispatch({ type: types.LOAD_ACCOUNT })
    return async function () {
        axiosAPI
            .put(`${url}/shop_account/${shop_id}/`)
            .then((res) => { return dispatch({ type: types.ADD_ACCOUNT, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_ACCOUNT, payload: error.response.data }) })
    };
}

export {
    getAccount,
    addAccount
};
