import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import SendIcon from '@mui/icons-material/Send';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Backdrop from '@mui/material/Backdrop';
import gif_loading from '../assets/gif_loading.gif';
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');

export default function ReviewPage(props) {

    const isInitialMount = React.useRef(true);
    const [response, setResponse] = React.useState(null);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.closeAlertReview(props.dispatch);
            props.getReview(props.match.params.review_id, props.dispatch);
        };
    }, []);

    React.useEffect(() => {
        if (props.reviewRed.success_add === true) {
            props.history.push('/review')
        }
    }, [props.reviewRed.success_add]);

    const handleReview = () => {
        const item = {
            'id': props.reviewRed.review.id,
            'response': response
        };
        props.addReview(item, props.dispatch)
    }

    return (
        isInitialMount.current ?
            (<Box sx={{ pl: '50px', pt: '100px', pr: '50px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
            (<Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <Box sx={{
                    width: '90%', mx: 'auto', mb: 1
                }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link style={{ textDecoration: 'none' }} variant="h4" color="inherit" href="/review">
                            Avis
                        </Link>
                        <Typography variant="h4" color="text.primary">Avis de {props.reviewRed.review.consumer ? props.reviewRed.review.consumer.username : null}</Typography>
                    </Breadcrumbs>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
                        open={props.reviewRed.processing}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 250,
                                mb: 4
                            }}
                            alt="Loading..."
                            src={gif_loading}
                        />
                    </Backdrop>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="h5" sx={{ pb: 2 }}>
                                {props.reviewRed.review.consumer ? props.reviewRed.review.consumer.username : null} vous a laissé un avis le {moment(props.reviewRed.review.created_at).format('Do MMMM YYYY à H[h]mm')} à propos de la commande {props.reviewRed.review.sub_order ? props.reviewRed.review.sub_order.id : null}.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={() => window.location.href = `/order/${props.reviewRed.review.sub_order.id}` }
                                    variant="contained"
                                >
                                    Voir la commande associée
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                alignItems: 'center',
                                display: 'flex',
                            }}>
                                <Typography variant="body1" component="body1">
                                    Note: 
                                </Typography>
                                <Rating
                                    name="half-rating-read"
                                    value={props.reviewRed.review.rate ? props.reviewRed.review.rate : 0}
                                    precision={1}
                                    readOnly
                                />
                                <Typography variant="body1" component="body1">
                                     ({props.reviewRed.review.rate}/5)
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="h2" sx={{ pb: 2 }}>
                                {props.reviewRed.review.content}
                            </Typography>
                        </Grid>
                        {props.reviewRed.review.response === null ?
                            (<Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="response"
                                    label="Réponse"
                                    name="response"
                                    value={response || ''}
                                    onChange={(e) => setResponse(e.target.value)}
                                    helperText={props.reviewRed.errors.response ? props.reviewRed.errors.response.message : null}
                                    error={props.reviewRed.errors.response ? true : false}
                                />
                                <Typography variant="h3" component="h2" sx={{ pb: 2 }}>
                                    {props.reviewRed.review.response}
                                </Typography>

                            </Grid>) :
                            (<><Grid item xs={12}>
                                <Typography variant="h5" component="h5" sx={{ pb: 2 }}>
                                    Vous avez répondu le {moment(props.reviewRed.review.answered_at).format('Do MMMM YYYY à H[h]mm')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                    <Typography variant="body1" component="body1" sx={{ pb: 2 }}>
                                    {props.reviewRed.review.response}
                                </Typography>
                            </Grid></>)}
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            href='/review'
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Retour
                        </Button>
                        {props.reviewRed.review.response === null ? (
                            <Button
                                onClick={() => handleReview() }
                                endIcon={<SendIcon />}
                                variant="contained"
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Répondre
                            </Button>)
                        : null}
                </Box>
            </Box>
        </Box>)
            
    );
}

