import * as types from "../types/actionTypes";

const initialState = {
    cgu: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CGU:
            return {
                ...state,
                cgu: action.payload[0]
            };
        default:
          return state;
  }
};