import { connect } from "react-redux";

import ShopPageAddress from "../components/ShopPageAddress";

import { getShop, addShopAddress } from '../actions/shopActions';

const mapStateToProps = state => ({
    user: state.auth,
    shopRed: state.shopRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShop: (dispatch) =>
        dispatch(getShop(dispatch)),
    addShopAddress: (item, dispatch) =>
        dispatch(addShopAddress(item, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageAddress);
