import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getShop(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP, payload: error.response.data })})
    };
}

function addShop(item, dispatch) {
    dispatch({ type: types.PROCESSING });
    return async function () {
        axiosAPI
            .patch(`${url}/shop/${item.id}/`, item, { headers: { "Content-Type": "multipart/form-data" } })
            .then((res) => { return dispatch({ type: types.ADD_SHOP, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_SHOP, payload: error.response.data }) })
    };
}

function addShopAddress(item, dispatch) {
    dispatch({ type: types.PROCESSING });
    return async function () {
        axiosAPI
            .patch(`${url}/shop/${item.id}/`, item, { headers: { "Content-Type": "multipart/form-data" }})
            .then((res) => { return dispatch({ type: types.ADD_SHOP_ADDRESS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_SHOP_ADDRESS, payload: error.response.data }) })
    };
}

function addShopPaymentMethod(item, dispatch) {
    dispatch({ type: types.PROCESSING });
    return async function () {
        axiosAPI
            .patch(`${url}/shop/${item.id}/`, item, { headers: { "Content-Type": "multipart/form-data" }})
            .then((res) => { return dispatch({ type: types.ADD_SHOP_PAYMENT_METHOD, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_SHOP_PAYMENT_METHOD, payload: error.response.data }) })
    };
}

function getShopCategoryOptions(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_category/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_CATEGORY_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_CATEGORY_OPTIONS, payload: error.response.data }) })
    };
}

function getShopPaymentMethodOptions(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_payment_method/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_PAYMENT_METHOD_OPTIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_PAYMENT_METHOD_OPTIONS, payload: error.response.data }) })
    };
}

function closeAlertShop(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_SHOP
    })
}

export {
    getShop,
    addShop,
    getShopCategoryOptions,
    getShopPaymentMethodOptions,
    addShopAddress,
    closeAlertShop,
    addShopPaymentMethod
};
