// AUTH ACTIONS
export const CLEAN_AUTH = "CLEAN_AUTH";
export const AUTHENTICATED = "AUTHENTICATED";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const FAILED_LOGIN = "FAILED_LOGIN";
export const SIGN_UP = "SIGN_UP";
export const FAILED_SIGN_UP = "FAILED_SIGN_UP";
export const PROCESSING_SIGN_UP = "PROCESSING_SIGN_UP";
export const NEED_VALIDATE = "NEED_VALIDATE";
export const VALIDATE = "VALIDATE";
export const FAILED_VALIDATE = "FAILED_VALIDATE";
export const VALIDATE_RESEND = "VALIDATE_RESEND";
export const FAILED_VALIDATE_RESEND = "FAILED_VALIDATE_RESEND";
export const PROCESSING_VALIDATE = "PROCESSING_VALIDATE";
export const WHO_AM_I = "WHO_AM_I";
export const WHO_AM_I_LOGGED = "WHO_AM_I_LOGGED";
export const NOT_ALLOWED = "NOT_ALLOWED";
export const USER_SHOP = "USER_SHOP";

export const CLEAN_RESET = 'CLEAN_RESET';
export const GET_TOKEN_RESET = 'GET_TOKEN_RESET';
export const FAILED_GET_TOKEN_RESET = 'FAILED_GET_TOKEN_RESET';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const FAILED_PASSWORD_RESET = 'FAILED_PASSWORD_RESET';
export const LOADING_RESET = 'LOADING_RESET';


// HEADER
export const GET_SHOP_HEADER = 'GET_SHOP_HEADER';
export const FAILED_GET_SHOP_HEADER = 'FAILED_GET_SHOP_HEADER';

// BUILD SHOP ACTIONS
export const BUILD_SHOP_STEP1 = 'BUILD_SHOP_STEP1';
export const BUILD_SHOP_SUCCESS_STEP1 = 'BUILD_SHOP_SUCCESS_STEP1';
export const BUILD_SHOP_FAILED_STEP1 = 'BUILD_SHOP_FAILED_STEP1';
export const BUILD_SHOP_STEP2 = 'BUILD_SHOP_STEP2';
export const BUILD_SHOP_SUCCESS_STEP2 = 'BUILD_SHOP_SUCCESS_STEP2';
export const BUILD_SHOP_FAILED_STEP2 = 'BUILD_SHOP_FAILED_STEP2';
export const LOADING_BUILD_SHOP = 'LOADING_BUILD_SHOP';
export const GET_STRIPE_PUBLISHABLE_KEY = 'GET_STRIPE_PUBLISHABLE_KEY';
export const FAILED_GET_STRIPE_PUBLISHABLE_KEY = 'FAILED_GET_STRIPE_PUBLISHABLE_KEY';

// HOME PAGE
export const GET_SHOP_ONBOARDING = 'GET_SHOP_ONBOARDING';
export const FAILED_GET_SHOP_ONBOARDING = 'FAILED_GET_SHOP_ONBOARDING';
export const REQUEST_SHOP_DEPLOYMENT = 'REQUEST_SHOP_DEPLOYMENT';
export const FAILED_REQUEST_SHOP_DEPLOYMENT = 'FAILED_REQUEST_SHOP_DEPLOYMENT';
export const GET_SHOP_EVENTS = 'GET_SHOP_EVENTS';
export const FAILED_GET_SHOP_EVENTS = 'FAILED_GET_SHOP_EVENTS';
export const GET_SHOP_HOME = 'GET_SHOP_HOME';
export const FAILED_GET_SHOP_HOME = 'FAILED_GET_SHOP_HOME';

// SHOP PAGE
export const GET_SHOP = 'GET_SHOP';
export const FAILED_GET_SHOP = 'FAILED_GET_SHOP';
export const ADD_SHOP = 'ADD_SHOP';
export const FAILED_ADD_SHOP = 'FAILED_ADD_SHOP';
export const CLOSE_ALERT_SHOP = 'CLOSE_ALERT_SHOP';
export const ADD_SHOP_ADDRESS = 'ADD_SHOP_ADDRESS';
export const FAILED_ADD_SHOP_ADDRESS = 'FAILED_ADD_SHOP_ADDRESS';
export const ADD_SHOP_PAYMENT_METHOD = 'ADD_SHOP_PAYMENT_METHOD';
export const FAILED_ADD_SHOP_PAYMENT_METHOD = 'FAILED_ADD_SHOP_PAYMENT_METHOD';
export const GET_SHOP_PAYMENT_METHOD_OPTIONS = 'GET_SHOP_PAYMENT_METHOD_OPTIONS';
export const FAILED_GET_SHOP_PAYMENT_METHOD_OPTIONS = 'FAILED_GET_SHOP_PAYMENT_METHOD_OPTIONS';

// PRODUCT PAGE
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const FAILED_GET_PRODUCTS = 'FAILED_GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const FAILED_GET_PRODUCT = 'FAILED_GET_PRODUCT';
export const GET_EMPTY_PRODUCT = 'GET_EMPTY_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const FAILED_ADD_PRODUCT = 'FAILED_ADD_PRODUCT';
export const CLOSE_ALERT_PRODUCT = 'CLOSE_ALERT_PRODUCT';

// REVIEW PAGE
export const GET_REVIEWS = 'GET_REVIEWS';
export const FAILED_GET_REVIEWS = 'FAILED_GET_REVIEWS';
export const GET_REVIEW = 'GET_REVIEW';
export const FAILED_GET_REVIEW = 'FAILED_GET_REVIEW';
export const ADD_REVIEW = 'ADD_REVIEW';
export const FAILED_ADD_REVIEW = 'FAILED_ADD_REVIEW';
export const CLOSE_ALERT_REVIEW = 'CLOSE_ALERT_REVIEW';

// MESSAGE PAGE
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const FAILED_GET_CONVERSATIONS = 'FAILED_GET_CONVERSATIONS';
export const GET_MESSAGE = 'GET_MESSAGE';
export const FAILED_GET_MESSAGE = 'FAILED_GET_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const FAILED_ADD_MESSAGE = 'FAILED_ADD_MESSAGE';
export const CLOSE_ALERT_MESSAGE = 'CLOSE_ALERT_MESSAGE';

// SUBSCRIPTION PAGE
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const FAILED_GET_SUBSCRIPTION = 'FAILED_GET_SUBSCRIPTION';
export const LOAD_SUBSCRIPTION = 'LOAD_SUBSCRIPTION';
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const FAILED_ADD_SUBSCRIPTION = 'FAILED_ADD_SUBSCRIPTION';

// ACCOUNT PAGE
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const FAILED_GET_ACCOUNT = 'FAILED_GET_ACCOUNT';
export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const FAILED_ADD_ACCOUNT = 'FAILED_ADD_ACCOUNT';

// SHOP DELIVERY OPTION PAGE
export const GET_SHOP_DELIVERY_OPTIONS = 'GET_SHOP_DELIVERY_OPTIONS';
export const FAILED_GET_SHOP_DELIVERY_OPTIONS = 'FAILED_GET_SHOP_DELIVERY_OPTIONS';
export const GET_SHOP_DELIVERY_OPTION = 'GET_SHOP_DELIVERY_OPTION';
export const FAILED_GET_SHOP_DELIVERY_OPTION = 'FAILED_GET_SHOP_DELIVERY_OPTION';
export const GET_EMPTY_SHOP_DELIVERY_OPTION = 'GET_EMPTY_SHOP_DELIVERY_OPTION';
export const LOAD_SHOP_DELIVERY_OPTION = 'LOAD_SHOP_DELIVERY_OPTION';
export const ADD_SHOP_DELIVERY_OPTION = 'ADD_SHOP_DELIVERY_OPTION';
export const FAILED_ADD_SHOP_DELIVERY_OPTION = 'FAILED_ADD_SHOP_DELIVERY_OPTION';
export const CLOSE_ALERT_SHOP_DELIVERY_OPTION = 'CLOSE_ALERT_SHOP_DELIVERY_OPTION';

// SHOP PARTNER PAGE
export const GET_SHOP_PARTNERS = 'GET_SHOP_PARTNERS';
export const FAILED_GET_SHOP_PARTNERS = 'FAILED_GET_SHOP_PARTNERS';
export const GET_SHOP_PARTNER = 'GET_SHOP_PARTNER';
export const FAILED_GET_SHOP_PARTNER = 'FAILED_GET_SHOP_PARTNER';
export const ADD_SHOP_PARTNER = 'ADD_SHOP_PARTNER';
export const FAILED_ADD_SHOP_PARTNER = 'FAILED_ADD_SHOP_PARTNER';
export const CLOSE_ALERT_SHOP_PARTNER = 'CLOSE_ALERT_SHOP_PARTNER';
export const GET_EMPTY_SHOP_PARTNER = 'GET_EMPTY_SHOP_PARTNER';

// ORDER PAGE
export const GET_ORDERS = 'GET_ORDERS';
export const FAILED_GET_ORDERS = 'FAILED_GET_ORDERS';
export const GET_ORDER = 'GET_ORDER';
export const FAILED_GET_ORDER = 'FAILED_GET_ORDER';
export const VALIDATE_ORDER = 'VALIDATE_ORDER';
export const FAILED_VALIDATE_ORDER = 'FAILED_VALIDATE_ORDER';
export const DELIVERED_ORDER = 'DELIVERED_ORDER';
export const FAILED_DELIVERED_ORDER = 'FAILED_DELIVERED_ORDER';
export const CLOSE_ALERT_ORDER = 'CLOSE_ALERT_ORDER';
export const CONTACT_ORDER_CUSTOMER = 'CONTACT_ORDER_CUSTOMER';
export const FAILED_CONTACT_ORDER_CUSTOMER = 'FAILED_CONTACT_ORDER_CUSTOMER';
export const LOADING_ORDER = 'LOADING_ORDER';


// PROFILE PAGE
export const GET_PROFILE = 'GET_PROFILE';
export const FAILED_GET_PROFILE = 'FAILED_GET_PROFILE';
export const ADD_PROFILE = 'ADD_PROFILE';
export const FAILED_ADD_PROFILE = 'FAILED_ADD_PROFILE';
export const LOADING_PROFILE = 'LOADING_PROFILE';
export const CLOSE_ALERT_PROFILE = 'CLOSE_ALERT_PROFILE';


// NOTIFICATIONS
export const GET_CONVERSATION_NOTIFICATIONS = 'GET_CONVERSATION_NOTIFICATIONS';
export const FAILED_GET_CONVERSATION_NOTIFICATIONS = 'FAILED_GET_CONVERSATION_NOTIFICATIONS';
export const GET_REVIEW_NOTIFICATIONS = 'GET_REVIEW_NOTIFICATIONS';
export const FAILED_GET_REVIEW_NOTIFICATIONS = 'FAILED_GET_REVIEW_NOTIFICATIONS';
export const GET_ORDER_NOTIFICATIONS = 'GET_ORDER_NOTIFICATIONS';
export const FAILED_GET_ORDER_NOTIFICATIONS = 'FAILED_GET_ORDER_NOTIFICATIONS';


// MORE
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const ERASE_MESSAGE = 'ERASE_MESSAGE';

export const LOADING_SPINNER = 'LOADING_SPINNER';
export const CLOSE_LOADING_SPINNER = 'CLOSE_LOADING_SPINNER';
export const PROCESSING = 'PROCESSING';
export const FETCHING = 'FETCHING';

// GET OPTIONS
export const GET_SHOP_CATEGORY_OPTIONS = 'GET_SHOP_CATEGORY_OPTIONS';
export const FAILED_GET_SHOP_CATEGORY_OPTIONS = 'FAILED_GET_SHOP_CATEGORY_OPTIONS';
export const GET_PRODUCT_CATEGORY_OPTIONS = 'GET_PRODUCT_CATEGORY_OPTIONS';
export const FAILED_GET_PRODUCT_CATEGORY_OPTIONS = 'FAILED_GET_PRODUCT_CATEGORY_OPTIONS';
export const GET_LABEL_OPTIONS = 'GET_LABEL_OPTIONS';
export const FAILED_GET_LABEL_OPTIONS = 'FAILED_GET_LABEL_OPTIONS';
export const GET_UNIT_OPTIONS = 'GET_UNIT_OPTIONS';
export const FAILED_GET_UNIT_OPTIONS = 'FAILED_GET_UNIT_OPTIONS';
export const GET_SHOP_OPTIONS = 'GET_SHOP_OPTIONS';
export const FAILED_GET_SHOP_OPTIONS = 'FAILED_GET_SHOP_OPTIONS';

// GET CGU
export const GET_CGU = 'GET_CGU';
export const FAILED_GET_CGU = 'FAILED_GET_CGU';

export const DRAWER_OPEN = 'DRAWER_OPEN';
export const DRAWER_CLOSE = 'DRAWER_CLOSE';


// SHOP ACTIVITY
export const GET_SHOP_ACTIVITIES = 'GET_SHOP_ACTIVITIES';
export const FAILED_GET_SHOP_ACTIVITIES = 'FAILED_GET_SHOP_ACTIVITIES';
export const GET_SHOP_ACTIVITY = 'GET_SHOP_ACTIVITY';
export const FAILED_GET_SHOP_ACTIVITY = 'FAILED_GET_SHOP_ACTIVITY';
export const ADD_SHOP_ACTIVITY = 'ADD_SHOP_ACTIVITY';
export const FAILED_ADD_SHOP_ACTIVITY = 'FAILED_ADD_SHOP_ACTIVITY';
export const DELETE_SHOP_ACTIVITY = 'DELETE_SHOP_ACTIVITY';
export const FAILED_DELETE_SHOP_ACTIVITY = 'FAILED_DELETE_SHOP_ACTIVITY';
export const CLOSE_ALERT_SHOP_ACTIVITY = 'CLOSE_ALERT_SHOP_ACTIVITY';
export const GET_EMPTY_SHOP_ACTIVITY = 'GET_EMPTY_SHOP_ACTIVITY';