import { connect } from "react-redux";

import Subscription from "../components/Subscription";

import { getShop, addSubscription } from '../actions/subscriptionActions';

const mapStateToProps = state => ({
    user: state.auth,
    subscriptionRed: state.subscriptionRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShop: (dispatch) =>
        dispatch(getShop(dispatch)),
    addSubscription: (shop_id, dispatch) =>
        dispatch(addSubscription(shop_id, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
