import * as types from "../types/actionTypes";

const initialState = {
    shopDeliveryOptions: [],
    shopDeliveryOption: {},
    shop: {},
    errors: {},
    productCategoryOptions: {},
    labelOptions: {},
    processing: false,
    success_add: null,
    success_delete: null,
    success_edit: null,
    fetching: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SHOP_DELIVERY_OPTIONS:
            return {
                ...state,
                shopDeliveryOptions: action.payload,
                errors: {},
                fetching: false,
                shopDeliveryOption: {},
            };
        case types.GET_SHOP_DELIVERY_OPTION:
            return {
                ...state,
                success_add: null,
                processing: false,
                shopDeliveryOption: action.payload,
                errors: {},
                fetching: false,
            };
        case types.GET_EMPTY_SHOP_DELIVERY_OPTION:
            return {
                ...state,
                shopDeliveryOption: {},
                errors: {}
            };
        case types.ADD_SHOP_DELIVERY_OPTION:
            return {
                ...state,
                errors: {},
                processing: false,
                success_add: true,
            };
        case types.FAILED_ADD_SHOP_DELIVERY_OPTION:
            return {
                ...state,
                errors: action.payload,
                processing: false
            };
        case types.PROCESSING:
            return {
                ...state,
                processing: true
            };
        case types.CLOSE_ALERT_SHOP_DELIVERY_OPTION:
            return {
                ...state,
                success_add: null,
                success_delete: null,
                success_edit: null,
            };
        case types.FETCHING:
            return {
                ...state,
                fetching: true
            };
        case types.GET_SHOP:
            return {
                ...state,
                shop: action.payload[0],
                errors: false,
            };
        default:
            return state;
  }
};