import * as types from "../../types/actionTypes";

const initialState = {
    loading: false,
    successGetToken: null,
    errorsGetToken: {},
    successPasswordReset: null,
    errorsPasswordReset: {},
};

function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case types.CLEAN_RESET:
            return {
                ...initialState,
            };
        case types.GET_TOKEN_RESET:
            return {
                ...state,
                successGetToken: true,
                errorsGetToken: {},
                errorsPasswordReset: {},
                loading: false,
            };
        case types.FAILED_GET_TOKEN_RESET:
            return {
                ...state,
                errorsGetToken: action.payload,
                errorsPasswordReset: {},
                loading: false,
            };
        case types.PASSWORD_RESET:
            return {
                ...state,
                successPasswordReset: true,
                errorsPasswordReset: {},
                loading: false,
            };
        case types.FAILED_PASSWORD_RESET:
            return {
                ...state,
                errorsPasswordReset: action.payload,
                loading: false,
            };
        case types.LOADING_RESET:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export default resetPasswordReducer;
