import * as types from "../types/actionTypes";

const initialState = {
    orders: [],
    order: {},
    errors: {},
    errorsDelivered: {},
    success_validate: null,
    success_delivered: null,
    processing: false,
    loading: false,
    contactCustomerId: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ORDERS:
            return {
                ...state,
                orders: action.payload,
                errors: false,
                contactCustomerId: null
            };
        case types.GET_ORDER:
            return {
                ...state,
                order: action.payload,
                processing: false,
                loading: false,
                success_validate: null,
                success_delivered: null,
                errorsDelivered: false,
                errors: false,
                contactCustomerId: null
            };
        case types.VALIDATE_ORDER:
            return {
                ...state,
                processing: false,
                success_validate: true,
                errors: false,
                loading: false,
            };
        case types.DELIVERED_ORDER:
            return {
                ...state,
                processing: false,
                success_delivered: true,
                errorsDelivered: false,
                loading: false,
            };
        case types.FAILED_DELIVERED_ORDER:
            return {
                ...state,
                processing: false,
                loading: false,
                success_delivered: false,
                errorsDelivered: action.payload
            };
        case types.CONTACT_ORDER_CUSTOMER:
            return {
                ...state,
                contactCustomerId: action.payload[0].id
            };
        case types.PROCESSING:
            return {
                ...state,
                processing: true
            };
        case types.LOADING_ORDER:
            return {
                ...state,
                loading: true
            };
        default:
          return state;
  }
};