import { connect } from "react-redux";

import Register from "../../components/auth/Register";
import {
    signUpAction,
    authenticateAction,
    closeAlert,
    getCGU
} from "../../actions/auth/authActions.js";

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    signUpAction: (data, dispatch) => dispatch(signUpAction(data, dispatch)),
    closeAlert: (dispatch) =>
        dispatch(closeAlert(dispatch)),
    getCGU: (dispatch) =>
        dispatch(getCGU(dispatch)),
    authenticateAction: (userData, dispatch, location, push) =>
        dispatch(authenticateAction(userData, dispatch, location, push)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
