import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Backdrop from '@mui/material/Backdrop';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';


import logo_login from '../../assets/logo.png';
import gif_loading from '../../assets/gif_loading.gif';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.boutigou.com/"  target="_blank" rel="noopener">
                Boutigou
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function formatCGUText(text) {
  const sections = text.split(/\r\n\r\n/).filter(Boolean);

  return sections.map((section, index) => {
    const [title, ...content] = section.split(/\r\n/);

    return (
      <div key={index}>
        <Typography variant="h4" gutterBottom>{title}</Typography>
        {content.map((paragraph, pIndex) => (
          <Typography key={pIndex} variant="body1" paragraph>{'  '}{paragraph}</Typography>
        ))}
      </div>
    );
  });
}

export default function SignUp(props) {

    const [open, setOpen] = React.useState(false);
    const [username, setUsername] = React.useState(null);
    const [first_name, setFirstName] = React.useState(null);
    const [last_name, setLastName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordConfirm, setPasswordConfirm] = React.useState(null);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
    const [accept_terms, setAcceptTerms] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = () => {
        const data = {
            'username': username,
            'first_name': first_name,
            'last_name': last_name,
            'email': email,
            'password': password,
            'passwordConfirm': passwordConfirm,
            'accept_terms': accept_terms
        };
        props.signUpAction(data, props.dispatch);
    };

    React.useEffect(() => {
        props.getCGU(props.dispatch)
    }, [])

    React.useEffect(() => {
        if (props.auth.validate !== null) {
            props.history.push('/validate')
        }
    }, [props.auth.validate])

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <CssBaseline />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.auth.processingSignUp}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="Loading..."
                    src={gif_loading}
                />
            </Backdrop>
            <Box
                sx={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: 10
                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: 133,
                        mb: 4
                    }}
                    alt="The house from the offer."
                    src={logo_login}
                />
                <Typography component="h1" variant="h5">
                    Créer mon compte
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="username"
                                label="Nom d'utilisateur"
                                name="username"
                                autoComplete="username"
                                value={username || ''}
                                onChange={(e) => setUsername(e.target.value)}
                                helperText={props.auth.errors.username ? props.auth.errors.username.message : null}
                                error={props.auth.errors.username ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="first_name"
                                required
                                fullWidth
                                id="first_name"
                                label="Prénom"
                                autoFocus
                                value={first_name || ''}
                                onChange={(e) => setFirstName(e.target.value)}
                                helperText={props.auth.errors.first_name ? props.auth.errors.first_name.message : null}
                                error={props.auth.errors.first_name ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="last_name"
                                label="Nom"
                                name="last_name"
                                autoComplete="family-name"
                                value={last_name || ''}
                                onChange={(e) => setLastName(e.target.value)}
                                helperText={props.auth.errors.last_name ? props.auth.errors.last_name.message : null}
                                error={props.auth.errors.last_name ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Mail"
                                name="email"
                                autoComplete="email"
                                value={email || ''}
                                onChange={(e) => setEmail(e.target.value)}
                                helperText={props.auth.errors.email ? props.auth.errors.email.message : null}
                                error={props.auth.errors.email ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                autoComplete="new-password"
                                value={password || ''}
                                onChange={(e) => setPassword(e.target.value)}
                                helperText={props.auth.errors.password ? props.auth.errors.password.message : null}
                                error={props.auth.errors.password ? true : false}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => setShowPassword(!showPassword)}
                                          onMouseDown={() => setShowPassword(!showPassword)}
                                        >
                                          {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="passwordConfirm"
                                label="Confirmer le mot de passe"
                                type={showPasswordConfirm ? "text" : "password"}
                                id="password"
                                autoComplete="confirm-password"
                                value={passwordConfirm || ''}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                                helperText={props.auth.errors.passwordConfirm ? props.auth.errors.passwordConfirm.message : null}
                                error={props.auth.errors.passwordConfirm ? true : false}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                          onMouseDown={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                        >
                                          {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={props.auth.errors.accept_terms ? true : false}>
                            <FormControlLabel
                                control={<Checkbox
                                            value={accept_terms}
                                            color="primary"
                                            checked={accept_terms}
                                            onChange={(e) => setAcceptTerms(e.target.checked)} />
                                        }
                                label={ <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => setOpen(true)}
                                        >
                                            J'accepte les conditions générales.
                                        </Link>
                                        }
                            />
                            {props.auth.errors.accept_terms ? (<FormHelperText>{props.auth.errors.accept_terms.message}</FormHelperText>) : null}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        onClick={() => handleSubmit() }
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Valider
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Déjà un compte? Connectez-vous
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{
                position: 'fixed',
                bottom: 0, }}
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="m">
              <DialogTitle variant="h3">Conditions Générales d'Utilisation pour les Producteurs</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {props.auth.cgu ? formatCGUText(props.auth.cgu.content || '') : null}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Fermer
                </Button>
              </DialogActions>
            </Dialog>


        </Grid>
    );
}