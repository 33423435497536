import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';


function formatCGUText(text) {
  const sections = text.split(/\r\n\r\n/).filter(Boolean);

  return sections.map((section, index) => {
    const [title, ...content] = section.split(/\r\n/);

    return (
      <div key={index}>
        <Typography variant="h4" gutterBottom>{title}</Typography>
        {content.map((paragraph, pIndex) => (
          <Typography key={pIndex} variant="body1" paragraph>{'  '}{paragraph}</Typography>
        ))}
      </div>
    );
  });
}

export default function Terms(props) {

    React.useEffect(() => {
        props.getCGU(props.dispatch)
    }, [])

    return (
        <Box sx={{ pt: '100px', pb: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                mb: 1,
                width: '90%',
                mx: 'auto'
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Conditions Générales d'Utilisation</Typography>
                </Breadcrumbs>
                <Container maxWidth={false} disableGutters sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    {formatCGUText(props.terms.cgu.content || '')}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

