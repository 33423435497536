import * as types from "../types/actionTypes";

const initialState = {
    products: [],
    product: {},
    errors: {},
    productCategoryOptions: {},
    labelOptions: {},
    productUnitOptions: {},
    processing: false,
    success_add: null,
    success_delete: null,
    success_edit: null,
    fetching: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                errors: {},
                fetching: false,
                product: {},
            };
        case types.GET_PRODUCT:
            return {
                ...state,
                success_add: null,
                processing: false,
                product: action.payload,
                errors: {},
                fetching: false,
            };
        case types.GET_EMPTY_PRODUCT:
            return {
                ...state,
                success_add: null,
                product: {},
                errors: {},
                processing: false,
                fetching: false,
            };
        case types.ADD_PRODUCT:
            return {
                ...state,
                errors: {},
                processing: false,
                success_add: true,
            };
        case types.FAILED_ADD_PRODUCT:
            return {
                ...state,
                errors: action.payload,
                processing: false
            };
        case types.GET_PRODUCT_CATEGORY_OPTIONS:
            var options = {};
            action.payload.map((c) => options[c.id] = c.name)
            return {
                ...state,
                productCategoryOptions: options,
                errors: {}
            };
        case types.GET_LABEL_OPTIONS:
            var options = {};
            action.payload.map((l) => options[l.id] = l.name)
            return {
                ...state,
                labelOptions: options,
                errors: {}
            };
        case types.GET_UNIT_OPTIONS:
            var options = {};
            action.payload.map((l) => options[l.id] = l.name)
            return {
                ...state,
                productUnitOptions: options,
                errors: {}
            };
        case types.PROCESSING:
            return {
                ...state,
                processing: true
            };
        case types.CLOSE_ALERT_PRODUCT:
            return {
                ...state,
                success_add: null,
                success_delete: null,
                success_edit: null,
            };
        case types.FETCHING:
            return {
                ...state,
                fetching: true
            };
        default:
            return state;
  }
};