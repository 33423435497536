import React, { useRef } from "react";

import Button from '@mui/material/Button';

function FileInput({ onImageSelected }) {

    const inputRef = useRef();

    const MAX_FILE_SIZE = 1024 * 1024 * 3 // 3MB

    const handleOnChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files[0].size > MAX_FILE_SIZE) {
                alert('Le fichier est trop lourd, la limite est de 500 kO.')
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = function (e) {
                    onImageSelected(reader.result);
                };
            }
        }
    };

    const onChooseImg = () => {
        inputRef.current.click();
    };

    return (
        <div>
            <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleOnChange}
                style={{ display: "none" }}
            />

            <Button variant="contained" onClick={onChooseImg}>
                Choisir une image
            </Button>
        </div>
    );
}

export default FileInput;