import { connect } from "react-redux";

import OrderPage from "../components/OrderPage";

import { getOrder, validateOrder, deliveredOrder, contactOrderCustomer, closeAlertOrder } from '../actions/orderActions';

import { getOrderNotifications } from '../actions/headerActions';

const mapStateToProps = state => ({
    orderRed: state.orderRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getOrder: (order_id, dispatch) =>
        dispatch(getOrder(order_id, dispatch)),
    validateOrder: (item, dispatch) =>
        dispatch(validateOrder(item, dispatch)),
    deliveredOrder: (item, dispatch) =>
        dispatch(deliveredOrder(item, dispatch)),
    contactOrderCustomer: (item, dispatch) =>
        dispatch(contactOrderCustomer(item, dispatch)),
    closeAlertOrder: (dispatch) =>
        dispatch(closeAlertOrder(dispatch)),
    getOrderNotifications: (dispatch) =>
        dispatch(getOrderNotifications(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
