import { connect } from "react-redux";

import ShopPagePaymentMethod from "../components/ShopPagePaymentMethod";

import { getShopPaymentMethodOptions, addShopPaymentMethod } from '../actions/shopActions';

const mapStateToProps = state => ({
    user: state.auth,
    shopRed: state.shopRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getShopPaymentMethodOptions: (dispatch) =>
        dispatch(getShopPaymentMethodOptions(dispatch)),
    addShopPaymentMethod: (item, dispatch) =>
        dispatch(addShopPaymentMethod(item, dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPagePaymentMethod);
