import * as React from 'react';
import CurrencyTextField from "../utils/CurrencyTextField";
/*import CurrencyTextField from '@lupus-ai/mui-currency-textfield';*/
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Modal from '@mui/material/Modal';
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Backdrop from '@mui/material/Backdrop';
import gif_loading from '../assets/gif_loading.gif';

export default function ProductPage(props) {

    const isInitialMount = React.useRef(true);
    const [picture, setPicture] = React.useState('');
    const [filePicture, setFilePicture] = React.useState();
    const [currentPicturePage, setCurrentPicturePage] = React.useState("choose-picture-img");
    const [name, setName] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [price, setPrice] = React.useState(null);
    const [stock, setStock] = React.useState(null);
    const [archived, setArchived] = React.useState();
    const [discounted, setDiscounted] = React.useState();
    const [variablePrice, setVariablePrice] = React.useState();
    const [category, setCategory] = React.useState(null);
    const [unit, setUnit] = React.useState(null);
    const [labels, setLabels] = React.useState([]);

    React.useEffect(() => {
        if (isInitialMount.current) {
            props.getProductUnitOptions(props.dispatch);
            props.getProductCategoryOptions(props.dispatch);
            props.getLabelOptions(props.dispatch);
            props.getProduct(props.match.params.product_id, props.dispatch);
            isInitialMount.current = false;
        };
    }, [props.productRed.product]);

    React.useEffect(() => {
        if (props.productRed.success_add === true) {
            props.history.push('/product')
        }
    }, [props.productRed.success_add]);

    React.useEffect(() => {
        if (JSON.stringify(props.productRed.product) != JSON.stringify({})) {
            setPicture(props.productRed.product.picture);
            if (props.match.params.product_id !== 'add') {
                setLabels((props.productRed.product.labels || []).map((l) => l.id));
            }
            if (props.match.params.product_id !== 'add') {
                setCategory(props.productRed.product.category ? props.productRed.product.category.id : '');
            }
            if (props.match.params.product_id !== 'add') {
                setUnit(props.productRed.product.unit ? props.productRed.product.unit.id : '');
            }
            setName(props.productRed.product.name);
            setDescription(props.productRed.product.description);
            setPrice(parseFloat(props.productRed.product.price).toFixed(2)/100);
            setStock(props.productRed.product.stock);
            setArchived(props.productRed.product.archived);
            setDiscounted(props.productRed.product.discounted);
            setVariablePrice(props.productRed.product.variable_price)
        }
    }, [props.productRed.product]);

    

    const handleProduct = () => {
        const item = {
            'name': name,
            'description': description,
            'stock': stock,
            'category': category,
            'unit': unit,
            'archived': archived,
            'discounted': discounted,
            'variable_price': variablePrice,
            'labels': JSON.stringify(labels)
        };
        if (price) {
            item['price'] = parseInt(price*100, 10);
        };
        if (filePicture) {
            item['picture'] = filePicture;
        };
        if (props.productRed.product.id) {
            item['id'] = props.productRed.product.id;
        };
        props.addProduct(item, props.dispatch)
    }

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleUnitChange = (event) => {
        setUnit(event.target.value);
    };

    const handleLabelsChange = (event) => {
        const { target: { value }, } = event;
        setLabels(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const onImagePictureSelected = (selectedImg) => {
        setPicture(selectedImg);
        setCurrentPicturePage("crop-picture-img");
    };

    const onCropPictureDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = picture;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );
            const dataURL = canvasEle.toDataURL("image/png");
            setPicture(dataURL);
            setFilePicture(dataURL);
            setCurrentPicturePage("img-picture-cropped");
        };
    };

    const onCropPictureCancel = () => {
        setCurrentPicturePage("choose-picture-img");
        setPicture(props.productRed.product.picture);
    };

    return (
        isInitialMount.current ?
            (<Box sx={{ width: '90%', mx: 'auto', pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
            (<Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <Box sx={{ width: '90%', mx: 'auto' }} backgroundColor={props.productRed.processing ? 'grey.200' : null}>
                <Box sx={{
                    pb: 1
                }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="none" variant="h4" color="inherit" href="/product" style={{ textDecoration: 'none' }}>
                            Produits
                        </Link>
                        <Typography variant="h4" color="text.primary">{props.match.params.product_id !== 'add' ? props.productRed.product.name : 'Nouveau produit'}</Typography>
                    </Breadcrumbs>
                    </Box>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
                        open={props.productRed.processing}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 250,
                                mb: 4
                            }}
                            alt="Loading..."
                            src={gif_loading}
                        />
                    </Backdrop>
                <Box sx={{
                    paddingTop: 8,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                >
                    <Grid container direction="row">
                        <Grid item xs={12} pb={2}>
                            <Paper elevation={4} style={{ padding: "16px" }}>
                                <FormControl component="fieldset" variant="standard" p={10}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Switch checked={archived} onChange={() => setArchived(!archived)} name="archived" />
                                      }
                                      label={"Cochez cette case s'il s'agit d'un produit non disponible à la vente actuellement. Les consommateurs ne le verront pas et ne pourront pas le commander."}
                                    />
                                  </FormGroup>
                                </FormControl>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} pb={2}>
                            <Paper elevation={4} style={{ padding: "16px" }}>
                                <FormControl component="fieldset" variant="standard" p={10}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Switch checked={discounted} onChange={() => setDiscounted(!discounted)} name="discounted" />
                                      }
                                      label="Cochez cette case s'il sagit d'un produit déclassé, cela l'indiquera au consommateurs. Cela peut être utile dans le cas de produit antigaspi."
                                    />
                                  </FormGroup>
                                </FormControl>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} pb={2}>
                            <Paper elevation={4} style={{ padding: "16px" }}>
                                <FormControl component="fieldset" variant="standard" p={10} error={props.productRed.errors.variable_price ? true : false}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Switch checked={variablePrice} onChange={() => setVariablePrice(!variablePrice)} name="variablePrice" />
                                      }
                                      label={"Cochez cette case sir le produit a un prix variable ou libre. Cela peut être utile pour des ventes en vrac ou bien des prix selon l'appréciation du consommateur (dans ce cas, uniquement le paiement sur place est possible). Veuillez cependant préciser le prix conseillé."}
                                    />
                                  </FormGroup>
                                  {props.productRed.errors.variable_price ? (<FormHelperText>{props.productRed.errors.variable_price.message}</FormHelperText>) : null}
                                </FormControl>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    pr: 5,
                                    pb: '20px'
                                }}
                            >
                                <img src={(picture || '').replace('http://django', '')} width='100%' />
                                <FileInput setImage={setPicture} onImageSelected={onImagePictureSelected} />
                                {props.productRed.errors.picture ? (<FormHelperText error={true}>{props.productRed.errors.picture.message}</FormHelperText>) : null}
                            </Box>
                        </Grid>
                        <Grid item xs={9}> 
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="name"
                                        label="Nom"
                                        name="name"
                                        value={name || ''}
                                        onChange={(e) => setName(e.target.value)}
                                        disabled={props.productRed.product.processing}
                                        helperText={props.productRed.errors.name ? props.productRed.errors.name.message : null}
                                        error={props.productRed.errors.name ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        name="description"
                                        onChange={(e) => setDescription(e.target.value)}
                                        multiline
                                        value={description || ''}
                                        disabled={props.productRed.product.processing}
                                        helperText={props.productRed.errors.description ? props.productRed.errors.description.message : null}
                                        error={props.productRed.errors.description ? true : false}
                                    />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <Grid item xs={9} sm={4}>
                                        <CurrencyTextField
                                            required
                                            fullWidth
                                            id="price"
                                            label="Prix"
                                            name="price"
                                            value={price || ''}
                                            onChange={(event, value)=> {setPrice(value)}}
                                            helperText={props.productRed.errors.price ? props.productRed.errors.price.message : null}
                                            error={props.productRed.errors.price ? true : false}
                                        />
                                        </Grid>
                                        <Grid item xs={9} sm={4}>
                                            <FormControl fullWidth error={props.productRed.errors.unit ? true : false}>
                                                <InputLabel id="unit">Unité</InputLabel>
                                                <Select
                                                    labelId="unit"
                                                    id="unitid"
                                                    label="Unité"
                                                    value={unit || ''}
                                                    onChange={handleUnitChange}
                                                    fullWidth
                                                    error={props.productRed.errors.unit}
                                                    disabled={props.productRed.product.processing}
                                                >
                                                    {Object.entries(props.productRed.productUnitOptions).map(([key, value]) =>
                                                        (<MenuItem key={key} value={key}>{value}</MenuItem>)
                                                    )}
                                                </Select>
                                                {props.productRed.errors.unit ? (<FormHelperText>{props.productRed.errors.unit.message}</FormHelperText>) : null}
                                            </FormControl>
                                        </Grid>
                                    <Grid item xs={9} sm={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="stock"
                                            type="number"
                                            label="Stock"
                                            name="stock"
                                            value={stock === 0 || stock ? stock : ''}
                                            onChange={(e) => setStock(e.target.value)}
                                            disabled={props.productRed.product.processing}
                                            helperText={props.productRed.errors.stock ? props.productRed.errors.stock.message : null}
                                            error={props.productRed.errors.stock ? true : false}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth error={props.productRed.errors.category ? true : false}>
                                                <InputLabel id="category-label">Catégorie</InputLabel>
                                                <Select
                                                    labelId="category-label"
                                                    id="category"
                                                    label="Catégorie"
                                                    value={category || ''}
                                                    onChange={handleCategoryChange}
                                                    fullWidth
                                                    error={props.productRed.errors.category}
                                                    disabled={props.productRed.product.processing}
                                                >
                                                    {Object.entries(props.productRed.productCategoryOptions).sort((a, b) => a[1].localeCompare(b[1])).map(([key, value]) =>
                                                        (<MenuItem key={key} value={key}>{value}</MenuItem>)
                                                ) }
                                                    </Select>
                                                    {props.productRed.errors.category ? (<FormHelperText>{props.productRed.errors.category.message}</FormHelperText>) : null}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                            <InputLabel id="label-label">Labels</InputLabel>
                                            <Select
                                                labelId="label-label"
                                                id="label"
                                                label="Labels"
                                                multiple
                                                fullWidth
                                                value={labels ?? []}
                                                onChange={handleLabelsChange}
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(labels) => labels.map((s) => props.productRed.labelOptions[s]).join(', ')}
                                                MenuProps={MenuProps}
                                                error={props.productRed.errors.labels}
                                                disabled={props.productRed.product.processing}
                                            >
                                                {Object.entries(props.productRed.labelOptions).sort((a, b) => a[1].localeCompare(b[1])).map(([key, value]) => (
                                                    <MenuItem key={value} value={parseInt(key)}>
                                                        <Checkbox checked={labels.includes(parseInt(key))} />
                                                        <ListItemText primary={value} />
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                                {props.productRed.errors.labels ? (<FormHelperText>{props.productRed.errors.labels.message}</FormHelperText>) : null}
                                            </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            href='/product'
                            variant="outlined"
                            disabled={props.productRed.product.processing}
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={handleProduct}
                            disabled={props.productRed.product.processing}
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Modal
                open={currentPicturePage === "crop-picture-img"}
            >
                <ImageCropper
                    image={picture}
                    onCropDone={onCropPictureDone}
                    onCropCancel={onCropPictureCancel}
                    aspectRatio={1 / 1}
                    // cropSize={{ width: 500, height: 500 }}
                />
            </Modal>
        </Box>)
            
    );
}

