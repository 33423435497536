import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import gif_loading from '../assets/gif_loading.gif';
import { frFR } from '@mui/x-date-pickers/locales'
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
moment.locale('fr');
import dayjs from 'dayjs';

export default function ShopActivityDetail(props) {

    const isInitialMount = React.useRef(true);
    const [open, setOpen] = React.useState(null);
    const [title, setTitle] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [address_secondary, setAddress_secondary] = React.useState(null);
    const [city, setCity] = React.useState(null);
    const [zipcode, setZipcode] = React.useState(null);
    const [country, setCountry] = React.useState(null);
    const [date_from, setDate_from] = React.useState(null);
    const [date_to, setDate_to] = React.useState(null);
    const [link, setLink] = React.useState(null);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getShopActivity(props.match.params.activity_id, props.dispatch);
        };
    }, []);

    React.useEffect(() => {
        if (props.shopActivityRed.success_add === true) {
            props.history.push('/activity')
        }
    }, [props.shopActivityRed.success_add]);

    React.useEffect(() => {
        if (props.shopActivityRed.success_delete === true) {
            props.history.push('/activity')
        }
    }, [props.shopActivityRed.success_delete]);

    React.useEffect(() => {
        if (JSON.stringify(props.shopActivityRed.activity) != JSON.stringify({})) {
            setTitle(props.shopActivityRed.activity.title); 
            setDescription(props.shopActivityRed.activity.description);
            setAddress(props.shopActivityRed.activity.address);
            setAddress_secondary(props.shopActivityRed.activity.address_secondary);
            setCity(props.shopActivityRed.activity.city);
            setZipcode(props.shopActivityRed.activity.zipcode);
            setCountry(props.shopActivityRed.activity.country);
            setDate_from(props.shopActivityRed.activity.date_from);
            setDate_to(props.shopActivityRed.activity.date_to);
            setLink(props.shopActivityRed.activity.link);
        }
    }, [props.shopActivityRed.activity]);

    const handleActivity = () => {
        const item = {
            'id': props.shopActivityRed.activity.id,
            'title': title,
            'description': description,
            'address': address,
            'address_secondary': address_secondary,
            'city': city,
            'zipcode': zipcode,
            'country': country,
            'date_from': date_from,
            'date_to': date_to,
            'link': link
        };
        props.addShopActivity(item, props.dispatch)
    }

    return (
        isInitialMount.current ?
            (<Box sx={{ pl: '50px', pt: '100px', pr: '50px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
            (<Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <Box sx={{
                    width: '90%', mx: 'auto', mb: 1
                }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link style={{ textDecoration: 'none' }} variant="h4" color="inherit" href="/review">
                            Activité
                        </Link>
                        <Typography variant="h4" color="text.primary">{props.match.params.activity_id != 'add' ? props.shopActivityRed.activity.title : 'Ajout'}</Typography>
                    </Breadcrumbs>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
                        open={props.shopActivityRed.processing}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 250,
                                mb: 4
                            }}
                            alt="Loading..."
                            src={gif_loading}
                        />
                    </Backdrop>
                    <Grid container spacing={2} pt={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="title"
                                label="Titre"
                                name="title"
                                value={title || ''}
                                onChange={(e) => setTitle(e.target.value)}
                                disabled={props.shopActivityRed.activity.processing}
                                helperText={props.shopActivityRed.errors.title ? props.shopActivityRed.errors.title.message : null}
                                error={props.shopActivityRed.errors.title ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="description"
                                label="Description"
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                                multiline
                                value={description || ''}
                                disabled={props.shopActivityRed.activity.processing}
                                helperText={props.shopActivityRed.errors.description ? props.shopActivityRed.errors.description.message : null}
                                error={props.shopActivityRed.errors.description ? true : false}
                            />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <Grid item xs={12} sm={6}>
                                <MobileDateTimePicker
                                    label="Début"
                                    fullWidth
                                    value={date_from}
                                    onChange={(e) => setDate_from(e)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                  />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MobileDateTimePicker
                                    label="Fin"
                                    fullWidth
                                    value={date_to}
                                    onChange={(e) => setDate_to(e)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                  />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="link"
                                label="Lien"
                                name="link"
                                onChange={(e) => setLink(e.target.value)}
                                multiline
                                value={link || ''}
                                disabled={props.shopActivityRed.activity.processing}
                                helperText={props.shopActivityRed.errors.link ? props.shopActivityRed.errors.link.message : null}
                                error={props.shopActivityRed.errors.link ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="address"
                                value={address || ''}
                                onChange={(e) => setAddress(e.target.value)}
                                label="Adresse"
                                name="address"
                                helperText={props.shopActivityRed.errors.address ? props.shopActivityRed.errors.address.message : null}
                                error={props.shopActivityRed.errors.address ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="address_secondary"
                                value={address_secondary || ''}
                                onChange={(e) => setAddress_secondary(e.target.value)}
                                label="Complement"
                                name="address_secondary"
                                helperText={props.shopActivityRed.errors.address_secondary ? props.shopActivityRed.errors.address_secondary.message : null}
                                error={props.shopActivityRed.errors.address_secondary ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="zipcode"
                                value={zipcode || ''}
                                onChange={(e) => setZipcode(e.target.value)}
                                required
                                fullWidth
                                id="zipcode"
                                label="Code postal"
                                helperText={props.shopActivityRed.errors.zipcode ? props.shopActivityRed.errors.zipcode.message : null}
                                error={props.shopActivityRed.errors.zipcode ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                id="city"
                                label="Ville"
                                name="city"
                                value={city || ''}
                                onChange={(e) => setCity(e.target.value)}
                                helperText={props.shopActivityRed.errors.city ? props.shopActivityRed.errors.city.message : null}
                                error={props.shopActivityRed.errors.city ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                id="country"
                                label="Pays"
                                name="country"
                                value={country || ''}
                                onChange={(e) => setCountry(e.target.value)}
                                helperText={props.shopActivityRed.errors.country ? props.shopActivityRed.errors.country.message : null}
                                error={props.shopActivityRed.errors.country ? true : false}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => setOpen(true) }
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Supprimer
                        </Button>
                        <Button
                            href='/activity'
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Retour
                        </Button>
                        <Button
                            onClick={() => handleActivity() }
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Valider
                        </Button>
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    minWidth: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    borderRadius: 4,
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        Voulez-vous vraiment supprimer cette activité?
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => setOpen(false)}
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Fermer
                        </Button>
                        <Button
                            onClick={() => {setOpen(false); props.deleteShopActivity(props.match.params.activity_id, props.dispatch)} }
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>)
            
    );
}

