import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import MuiTable from '../utils/MuiTable';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ShopActivity(props) {

    const isInitialMount = React.useRef(true);
 
    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getShopActivities(props.dispatch);
        }
    }, []);


    const handleCloseAlertShopActivity = () => {
        props.closeAlertShopActivity(props.dispatch)
    }

 
    const columns = [
        {
            name: "title",
            label: "Titre",
        },
        {
            name: "description",
            label: "Description",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return `${value.substring(0, 250)}...` 
                },
            }
        },
        
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                download: false,
                setCellProps: () => ({ align: "right" }),
                customHeadRender: () => <th key={8} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <IconButton aria-label="edit" onClick={() => { handleCloseAlertShopActivity(); props.history.push(`/activity/${value}`) }} color="primary">
                        <MoreVertIcon />
                    </IconButton>;
                }
            }
        },]
    

    const options = {
        
    };

    return (
        <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1, pb: 4
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Activités</Typography>
                </Breadcrumbs>
                <Snackbar
                    autoHideDuration={6000}
                    onClose={handleCloseAlertShopActivity}
                    open={props.shopActivityRed.success_add}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertShopActivity}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertShopActivity} severity="success" sx={{ width: '100%', color: '#fff' }}>
                        {props.match.params.shop_activity_id !== 'add' ?
                            "L'activité a bien été mis à jour." :
                            "L'activité  a bien été créé."
                        }
                    </Alert>
                </Snackbar>
                <Snackbar
                    autoHideDuration={6000}
                    onClose={handleCloseAlertShopActivity}
                    open={props.shopActivityRed.success_delete}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertShopActivity}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertShopActivity} severity="success" sx={{ width: '100%', color: '#fff' }}>
                        L'activité a bien été supprimée.
                    </Alert>
                </Snackbar>
                <Box sx={{
                        display: 'flex', justifyContent: 'flex-end', mb: 4, width: '100%', mx: 'auto',
                }}>                
                    <Button
                        onClick={() => { handleCloseAlertShopActivity(); props.history.push(`/activity/add`) }}
                        variant="contained"
                        sx={{ mt: 3, mr: 0,  }}
                    >
                        Ajouter une activité
                    </Button>
                </Box>
                <Paper>
                    <MuiTable
                        data={props.shopActivityRed.activities}
                        columns={columns}
                        options={options}
                        filename={'BOUTIGOU-Activités.csv'}
                    />
                </Paper>
            </Box>
        </Box>
    );
}

