import { connect } from "react-redux";

import Header from "../components/Header";

import {
    getConversationNotifications,
    getReviewNotifications,
    getOrderNotifications,
    handleDrawerState,
    getShop
} from '../actions/headerActions';

import { logoutAction } from '../actions/auth/authActions';

const mapStateToProps = state => ({
    authenticated: state.auth.authenticated,
    user: state.auth,
    headerRed: state.headerRed
});

const mapDispatchToProps = dispatch => ({
    logoutAction: (dispatch, push) =>
        dispatch(logoutAction(dispatch, push)),
    getConversationNotifications: (dispatch) =>
        dispatch(getConversationNotifications(dispatch)),
    getOrderNotifications: (dispatch) =>
        dispatch(getOrderNotifications(dispatch)),
    getReviewNotifications: (dispatch) =>
        dispatch(getReviewNotifications(dispatch)),
    handleDrawerState: (state, dispatch) =>
        dispatch(handleDrawerState(state, dispatch)),
    getShop: (dispatch) =>
        dispatch(getShop(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
