import * as types from "../types/actionTypes";

const initialState = {
    profile: {},
    errors: {},
    loading: false,
    success: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PROFILE:
            return {
                ...state,
                profile: action.payload[0],
                loading: false,
                errors: {},
            };
        case types.FAILED_GET_PROFILE:
            return {
                ...state,
                errors: action.payload,
                loading: false
            };
        case types.ADD_PROFILE:
            return {
                ...state,
                loading: false,
                errors: {},
                success: true
            };
        case types.FAILED_ADD_PROFILE:
            return {
                ...state,
                loading: false,
                errors: action.payload,
            };
        case types.LOADING_PROFILE:
            return {
                ...state,
                success: false,
                loading: true,
                errors: {},
            };
        case types.CLOSE_ALERT_PROFILE:
            return {
                ...state,
                success: false
            };
        default:
          return state;
  }
};