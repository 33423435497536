import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getConversations(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/conversation/`)
            .then((res) => { return dispatch({ type: types.GET_CONVERSATIONS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CONVERSATIONS, payload: error })})
    };
}

function getMessage(conversation_id, dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/message/?conversation__id=${conversation_id}`)
            .then((res) => { return dispatch({ type: types.GET_MESSAGE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_MESSAGE, payload: error.response.data }) })
    };
}

function addMessage(item, dispatch) {
    return async function () {
        axiosAPI
            .post(`${url}/message/`, item)
            .then((res) => { return dispatch({ type: types.ADD_MESSAGE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_ADD_MESSAGE, payload: error.response.data }) })
    };
}

function closeAlertMessage(dispatch){
    return dispatch({
        type: types.CLOSE_ALERT_MESSAGE
    })
}

export {
    getConversations,
    getMessage,
    addMessage,
    closeAlertMessage
};
