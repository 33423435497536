import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import gif_loading from '../assets/gif_loading.gif';
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";

export default function ShopPage(props) {

    const isInitialMount = React.useRef(true);

    
    const [name, setName] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [category, setCategory] = React.useState();
    
    const [cover, setCover] = React.useState('');
    const [fileCover, setFileCover] = React.useState();
    const [currentCoverPage, setCurrentCoverPage] = React.useState("choose-cover-img");

    const onImageCoverSelected = (selectedImg) => {
        setCover(selectedImg);
        setCurrentCoverPage("crop-cover-img");
    };

    const onCropCoverDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = cover;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );
            const dataURL = canvasEle.toDataURL("image/png");
            setCover(dataURL);
            setFileCover(dataURL);
            setCurrentCoverPage("img-cover-cropped");
        };
    };

    const onCropCoverCancel = () => {
        setCurrentCoverPage("choose-cover-img");
        setCover(props.shopRed.shop.cover);
    };

    const [profile, setProfile] = React.useState('');
    const [fileProfile, setFileProfile] = React.useState();
    const [currentProfilePage, setCurrentProfilePage] = React.useState("choose-profile-img");

    const onImageProfileSelected = (selectedImg) => {
        setProfile(selectedImg);
        setCurrentProfilePage("crop-profile-img");
    };

    const onCropProfileDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = profile;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );
            const dataURL = canvasEle.toDataURL("image/png");
            setProfile(dataURL);
            setFileProfile(dataURL);
            setCurrentProfilePage("img-profile-cropped");
        };        
    };

    const onCropProfileCancel = () => {
        setCurrentProfilePage("choose-profile-img");
        setProfile(props.shopRed.shop.profile);
    };


    React.useEffect(() => {
        if (isInitialMount.current) {
            props.getShopCategoryOptions(props.dispatch);
            setCover(props.shopRed.shop.cover);
            setProfile(props.shopRed.shop.profile);
            setName(props.shopRed.shop.name);
            setDescription(props.shopRed.shop.description);
            isInitialMount.current = false;
        }
    }, []);

    React.useEffect(() => {
        setCategory(props.shopRed.shop.category ? props.shopRed.shop.category.id : '');
    }, [props.shopRed.shop.category]);

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    React.useEffect(() => {
        if (props.shopRed.success_add === true) {
            props.history.push('/shop')
        }
    }, [props.shopRed.success_add]);

    const handleShop = () => {
        const item = {
            'id': props.shopRed.shop.id,
            'name': name,
            'description': description,
            'category': category
        };
        if (fileProfile) {
            item['profile'] = fileProfile;
        };
        if (fileCover) {
            item['cover'] = fileCover;
        };
        props.addShop(item, props.dispatch)
    }

    return (
        isInitialMount.current ?
            (<Box sx={{ width: '90%', pt: '100px', mx: 'auto' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
                <LinearProgress />
            </Box>) :
        (<Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', pb: '20px',
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                        <Link variant="h4" underline="hover" color="inherit" href="/shop" style={{ textDecoration: 'none' }}>
                        Boutique
                    </Link>
                        <Typography variant="h4" color="text.primary" >Edition</Typography>
                </Breadcrumbs>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
                    open={props.shopRed.processing}
                >
                    <Box
                        component="img"
                        sx={{
                            height: 250,
                            mb: 4
                        }}
                        alt="Loading..."
                        src={gif_loading}
                    />
                </Backdrop>
                <Container sx={{ mt: 4, pb: 4 }} maxWidth={false} noValidate={true}>
                    <Grid container>
                            <Box
                            sx={{
                                    px: 'auto',
                                    pb: '20px',
                                    width: '100%' 
                                }}
                            >
                                <img src={(cover || '').replace('http://django', '')} width='100%'  />
                                <FileInput setImage={setCover} onImageSelected={onImageCoverSelected} />
                            </Box>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={3}>
                            <Box
                                sx={{
                                    mr: 5,
                                    pb: '20px',
                                }}
                            >
                                    <img src={(profile || '').replace('http://django', '')} width='100%'/>
                                    <FileInput setImage={setProfile} onImageSelected={onImageProfileSelected} />
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="name"
                                        label="Nom"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        helperText={props.shopRed.errors.name ? props.shopRed.errors.name.message : null}
                                        error={props.shopRed.errors.name ? true : false}
                                    />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={props.shopRed.errors.category ? true : false}>
                                            <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
                                            <Select
                                                labelId="category"
                                                id="category"
                                                value={category}
                                                onChange={handleCategoryChange}
                                                fullWidth
                                                error={props.shopRed.errors.category}
                                                disabled={props.shopRed.shop.processing}
                                            >
                                                {Object.entries(props.shopRed.shopCategoryOptions).sort((a, b) => a[1].localeCompare(b[1])).map(([key, value]) =>
                                                    (<MenuItem key={key} value={key}>{value}</MenuItem>)
                                                )}
                                            </Select>
                                            {props.shopRed.errors.category ? (<FormHelperText>{props.shopRed.errors.category.message}</FormHelperText>) : null}
                                        </FormControl>
                                    </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        name="description"
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                        multiline
                                        helperText={props.shopRed.errors.description ? props.shopRed.errors.description.message : null}
                                        error={props.shopRed.errors.description ? true : false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            href='/shop'
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Retour
                        </Button>
                        <Button
                            type="submit"
                            onClick={handleShop}
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Valider
                        </Button>
                    </Box>
                </Container>
                </Box>
                <Modal
                    open={currentProfilePage === "crop-profile-img"}
                >
                    <ImageCropper
                        image={profile}
                        onCropDone={onCropProfileDone}
                        onCropCancel={onCropProfileCancel}
                        aspectRatio={1 / 1}
                        // cropSize={{ width: 500, height: 500 }}
                    />
                </Modal>
                <Modal
                    open={currentCoverPage === "crop-cover-img"}
                >
                    <ImageCropper
                        image={cover}
                        onCropDone={onCropCoverDone}
                        onCropCancel={onCropCoverCancel}
                        aspectRatio={3 / 1}
                        // cropSize={{ width: 600, height: 200 }}
                    />
                </Modal>
        </Box>)
    );
}

