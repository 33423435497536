import { connect } from "react-redux";

import BuildShop from "../components/BuildShop";

import { buildShopGetStripeKey, buildShopStep1, buildShopStep2, getShop } from '../actions/buildShopActions';

import { getShop as getShopHeader } from '../actions/headerActions';
import { whoAmI } from "../actions/auth/authActions";

import { getShopCategoryOptions } from '../actions/shopActions';

const mapStateToProps = state => ({
    user: state.auth,
    buildShopRed: state.buildShopRed,
    headerRed: state.headerRed
});

const mapDispatchToProps = dispatch => ({
    buildShopStep1: (item, dispatch) =>
        dispatch(buildShopStep1(item, dispatch)),
    buildShopStep2: (item, dispatch) =>
        dispatch(buildShopStep2(item, dispatch)),
    whoAmI: (token, dispatch) =>
        dispatch(whoAmI(token, dispatch)),
    getShopCategoryOptions: (dispatch) =>
        dispatch(getShopCategoryOptions(dispatch)),
    getShop: (dispatch) =>
        dispatch(getShop(dispatch)),
    getShopHeader: (dispatch) =>
        dispatch(getShopHeader(dispatch)),
    buildShopGetStripeKey: (dispatch) =>
        dispatch(buildShopGetStripeKey(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildShop);
