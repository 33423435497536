import * as types from "../../types/actionTypes";
import axios from "axios";
import axiosAPI from "../../components/api/axiosApi";

import { backendUrl } from "../backendUrl";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function whoAmI(token, dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/me/`)
            .then((res) => { return dispatch({ type: types.WHO_AM_I, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.UNAUTHENTICATED }) })
    };
}

function whoAmILogged(token) {
    return async function () {
        axiosAPI
            .get(`${url}/me/`)
            .then((res) => { return { type: types.WHO_AM_I, payload: res.data } })
            .catch((error) => { return { type: types.UNAUTHENTICATED } })
    };
}

function authenticateAction(userData, dispatch, location, push) {
    return async function () {
        let response = await fetch(`${url}/me/`, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + userData.access
            }
        });
        let responseJson = await response.json();
        try {
            localStorage.setItem("access_token", userData.access);
            localStorage.setItem("refresh_token", userData.refresh);
            dispatch({ type: types.AUTHENTICATED, payload: responseJson })
            if (location === "/login") {
                let responseShop = await fetch(`${url}/shop/`, {
                    method: "GET",
                    headers: {
                        "Authorization": "Bearer " + userData.access
                    }
                });
                let responseShopJson = await responseShop.json();
                dispatch({ type: types.USER_SHOP, payload: responseShopJson })
                if (responseShopJson.length === 0) {
                    push("/build_shop")
                } else {
                    if (responseShopJson[0].prestate === false) {
                        push("/build_shop")
                    } else {
                        push("/")
                    }
                };
                return dispatch({type: 'default'});
            }
        } catch (error) {
            return dispatch({ type: types.NOT_ALLOWED })
        }
    };
}

function loginAction(data, dispatch) {
    dispatch({
        type: types.CLEAN_AUTH
    });
    var data_strip = { username: data.username.trim(), password: data.password.trim() };
    return async function () {
        let response = await fetch(`${url}/token/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data_strip)
        });
        let responseJson = await response.json();
        return responseJson
    };
}

function signUpAction(data, dispatch) {
    dispatch({
        type: types.PROCESSING_SIGN_UP
    });
    return async function () {
        axios
            .post(`${url}/register/`, data)
            .then((res) => { return dispatch({ type: types.SIGN_UP, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_SIGN_UP, payload: error.response.data }) })
    };
}

function needValidateAction(data, dispatch) {
    return dispatch({ type: types.NEED_VALIDATE, payload: data })
}

function postValidateAction(data, dispatch) {
    dispatch({ type: types.PROCESSING_VALIDATE })
    return async function () {
        axios
            .post(`${url}/validate/`, data)
            .then((res) => { return dispatch({ type: types.VALIDATE, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_VALIDATE, payload: error.response.data }) })
    };
}

function resendValidateAction(data, dispatch) {
    dispatch({ type: types.PROCESSING_VALIDATE })
    return async function () {
        axios
            .post(`${url}/validate_token_new_request/`, data)
            .then((res) => { return dispatch({ type: types.VALIDATE_RESEND, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_VALIDATE_RESEND, payload: error.response.data }) })
    };
}

// JWT tokens are not stored in our DB
function logoutAction(dispatch, push) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");
    window.location.href = '/login';
    dispatch({ type: types.UNAUTHENTICATED });
    return dispatch({ type: 'default' });
}

function closeAlert(dispatch) {
    return dispatch({ type: types.CLOSE_ALERT });
}

function getCGU(dispatch) {
    return async function () {
        axios
            .get(`${url}/cgu/`)
            .then((res) => { return dispatch({ type: types.GET_CGU, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CGU, payload: error.response.data }) })
    };
}

export {
    loginAction,
    signUpAction,
    needValidateAction,
    authenticateAction,
    logoutAction,
    whoAmI,
    whoAmILogged,
    postValidateAction,
    closeAlert,
    resendValidateAction,
    getCGU
};
