import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import MUIDataTable from "mui-datatables";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiTable from '../utils/MuiTable';
import MuiAlert from '@mui/material/Alert';

import img_nodata from '../assets/nodata.png';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ShopDeliveryOption(props) {

    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getShopDeliveryOptions(props.dispatch);
        }
    }, []);

    const handleCloseAlertShopDeliveryOption = () => {
        props.closeAlertShopDeliveryOption(props.dispatch)
    }

    const columns = [
        {
            name: "name",
            label: "Nom",
        },
        {
            name: "address",
            label: "Adresse",
        },
        {
            name: "address_secondary",
            label: "Complément",
        },
        {
            name: "city",
            label: "Ville",
        },
        {
            name: "zipcode",
            label: "Code postal",
        },
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                download: false,
                setCellProps: () => ({ align: "right" }),
                customHeadRender: () => <th key={8} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <IconButton aria-label="delete" onClick={() => { handleCloseAlertShopDeliveryOption(); props.history.push(`/delivery_option/${value}`) }} color="primary">
                        <MoreVertIcon />
                    </IconButton>;
                }
            }
        },]


    const options = {

    };

    return (
        <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Points de collecte</Typography>
                </Breadcrumbs>
                <Snackbar
                    autoHideDuration={6000}
                    onClose={handleCloseAlertShopDeliveryOption}
                    open={props.shopDeliveryOptionRed.success_add}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertShopDeliveryOption}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertShopDeliveryOption} severity="success" sx={{ width: '100%', color: '#fff' }}>
                        Votre modification a bien été prise en compte.
                    </Alert>
                </Snackbar>
                <Box sx={{
                    display: 'flex', justifyContent: 'flex-end', mb: 4, width: '100%', mx: 'auto',
                }}>
                    <Button
                        onClick={() => { handleCloseAlertShopDeliveryOption(); props.history.push(`/delivery_option/add`) }}
                        variant="contained"
                        sx={{ mt: 3, mr: 0, }}
                    >
                        Ajouter un point de collecte
                    </Button>
                </Box>
                {props.shopDeliveryOptionRed.shopDeliveryOptions.length === 0 ? (
                    <Box>
                        <Box 
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: 250,
                                    mb: 4,
                                    mx: 'auto',
                                }}
                                alt="No data."
                                src={img_nodata}
                            />
                        </Box>
                        <Typography
                            sx={{textAlign: 'center' }}
                        >
                            Vous n'avez pas définis de point de collecte
                        </Typography>
                    </Box>) : (
                        <Paper>
                    <MuiTable
                        data={props.shopDeliveryOptionRed.shopDeliveryOptions}
                        columns={columns}
                        filename={'BOUTIGOU-Points-de-collecte.csv'}
                    />
                    </Paper>
                    )
                     
                    }
            </Box>
        </Box>
    );
}

