import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getShop(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP, payload: error.response.data })})
    };
}

function getShopOnboarding(dispatch) {
    dispatch({ type: types.PROCESSING });
    return async function () {
        axiosAPI
            .get(`${url}/shop_onboarding/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_ONBOARDING, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_ONBOARDING, payload: error.response.data }) })
    };
}

function requestShopDeployment(item, dispatch) {
    dispatch({ type: types.PROCESSING });
    return async function () {
        axiosAPI
            .patch(`${url}/shop_request_deployment/${item.id}/`, item)
            .then((res) => { return dispatch({ type: types.REQUEST_SHOP_DEPLOYMENT, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_REQUEST_SHOP_DEPLOYMENT, payload: error.response.data }) })
    };
}

function getShopEvents(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_event/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_EVENTS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_EVENTS, payload: error.response.data }) })
    };
}

function getShopHome(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/shop_home/`)
            .then((res) => { return dispatch({ type: types.GET_SHOP_HOME, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_SHOP_HOME, payload: error.response.data }) })
    };
}

export {
    getShop,
    getShopOnboarding,
    requestShopDeployment,
    getShopEvents,
    getShopHome
};
