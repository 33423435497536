import { connect } from "react-redux";

import Login from "../../components/auth/Login";
import {
    loginAction,
    authenticateAction,
    needValidateAction,
    closeAlert
} from "../../actions/auth/authActions.js";

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginAction: (data, dispatch) => dispatch(loginAction(data, dispatch)),
    closeAlert: (dispatch) =>
        dispatch(closeAlert(dispatch)),
    needValidateAction: (data, dispatch) =>
        dispatch(needValidateAction(data, dispatch)),
    authenticateAction: (userData, dispatch, location, push) =>
        dispatch(authenticateAction(userData, dispatch, location, push)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
