import { connect } from "react-redux";

import ProductPage from "../components/ProductPage";

import { getProduct, addProduct, getProductCategoryOptions, getLabelOptions, getProductUnitOptions } from '../actions/productActions';

const mapStateToProps = state => ({
    user: state.auth,
    productRed: state.productRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getProduct: (id, dispatch) =>
        dispatch(getProduct(id, dispatch)),
    addProduct: (item, dispatch) =>
        dispatch(addProduct(item, dispatch)),
    getProductCategoryOptions: (dispatch) =>
        dispatch(getProductCategoryOptions(dispatch)),
    getLabelOptions: (dispatch) =>
        dispatch(getLabelOptions(dispatch)),
    getProductUnitOptions: (dispatch) =>
        dispatch(getProductUnitOptions(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
