import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from 'react-redux';
import logo_login from '../../assets/logo.png';
import gif_loading from '../../assets/gif_loading.gif';

export default function Validate(props) {

    const dispatch = useDispatch()
    const [step, setStep] = React.useState(1);
    const [email, setEmail] = React.useState(null);
    const [token, setToken] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState(null);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

    React.useEffect(() => {
        setStep(1)
        dispatch({ type: 'CLEAN_RESET' })
    }, []);

    React.useEffect(() => {
        if (props.passwordReset.successGetToken === true) {
            setStep(2)
        }
    }, [props.passwordReset.successGetToken]);

    React.useEffect(() => {
        if (props.passwordReset.successPasswordReset === true) {
            dispatch({ type: 'CLEAN_RESET' })
            window.location.href = '/login'
        }
    }, [props.passwordReset.successPasswordReset]);

    const sendResetPasswordEmail = () => {
        const data = { 'email': email };
        props.getTokenReset(data, props.dispatch);
    }

    const resetPasswordEmail = () => {
        const data = { 'email': email, 'password': password, 'confirmPassword': confirmPassword, 'token': token };
        props.resetPassword(data, props.dispatch);
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
            maxWidth="xs"
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.passwordReset.loading}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="gif_loading"
                    src={gif_loading}
                />
            </Backdrop>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: 133,
                        mb: 4
                    }}
                    alt="The house from the offer."
                    src={logo_login}
                />
                { step === 1 ?
                <React.Fragment>
                    <Typography component="h1" variant="h5" sx={{ flexGrow: 1, }} align='center' >
                        Renseignez votre email
                        </Typography>
                        <Typography component="h3" variant="h5" sx={{ flexGrow: 1, }} align='center' >
                            Vous recevrez un courriel si un compte producteur existe.
                        </Typography>
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Adresse mail"
                                    name="email"
                                    autoComplete="email"
                                    value={email || ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={props.passwordReset.errorsGetToken.email ? true : false}
                                    helperText={props.passwordReset.errorsGetToken.email ? props.passwordReset.errorsGetToken.email.message : null}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                href='/login'
                                variant="outlined"
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Retour
                            </Button>
                            <Button
                                onClick={() => sendResetPasswordEmail() }
                                variant="contained"
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Recevoir mon code
                            </Button>
                        </Box>
                    </Box>
                </React.Fragment>
                    : null}
                {step === 2 ?
                    <React.Fragment>
                        < Typography component="h1" variant="h5" sx={{ flexGrow: 1, }} align='center' >
                            Renseignez votre email
                        </Typography>
                        <Box sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="token"
                                        label="Code"
                                        name="token"
                                        autoComplete="token"
                                        value={token || ''}
                                        onChange={(e) => setToken(e.target.value)}
                                        error={props.passwordReset.errorsPasswordReset.token || props.passwordReset.errorsPasswordReset.null ? true : false}
                                        helperText={props.passwordReset.errorsPasswordReset.token ? props.passwordReset.errorsPasswordReset.token.message : props.passwordReset.errorsPasswordReset.null ? props.passwordReset.errorsPasswordReset.null.message : null}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="password"
                                        label="Mot de passe"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        autoComplete="password"
                                        value={password || ''}
                                        onChange={(e) => setPassword(e.target.value)}
                                        error={props.passwordReset.errorsPasswordReset.password ? true : false}
                                        helperText={props.passwordReset.errorsPasswordReset.password ? props.passwordReset.errorsPasswordReset.password.message : null}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => setShowPassword(!showPassword)}
                                                  onMouseDown={() => setShowPassword(!showPassword)}
                                                >
                                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="confirmPassword"
                                        label="Confirmez le mot de passe"
                                        name="confirmPassword"
                                        type={showPasswordConfirm ? "text" : "password"}
                                        autoComplete="confirmPassword"
                                        value={confirmPassword || ''}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        error={props.passwordReset.errorsPasswordReset.confirmPassword ? true : false}
                                        helperText={props.passwordReset.errorsPasswordReset.confirmPassword ? props.passwordReset.errorsPasswordReset.confirmPassword.message : null}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                                  onMouseDown={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                                >
                                                  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    fullWidth
                                    href='/login'
                                    variant="outlined"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Retour
                                </Button>
                                <Button
                                    fullWidth
                                    onClick={() => resetPasswordEmail()}
                                    variant="contained"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    Valider
                                </Button>
                            </Box>
                        </Box>
                    </React.Fragment>
                    : null}
                </Box>
        </Grid>
    );
}