import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Breadcrumbs(theme) {
  return {
      MuiBreadcrumbs: {
          styleOverrides: {
              li: {
                  color: theme.palette.primary.main,
                  '&:hover': {
                      color: theme.palette.grey[400],
                  },
              
              },
          }
      },
  };
}
