import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { whoAmILogged } from './actions/auth/authActions';
import rootReducer from "./reducers/";

const middlewares = [thunk];

// Log only in development
if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

let persistor = persistStore(store);

export {
  store,
  persistor,
};


//Email / Username login
let auth_token;
if (navigator.cookieEnabled) {
  auth_token = localStorage.getItem("access_token");
}
