import { connect } from "react-redux";

import HomePage from "../components/HomePage";

import {
    getShop,
    getShopOnboarding,
    requestShopDeployment,
    getShopEvents,
    getShopHome} from '../actions/homePageActions';

const mapStateToProps = state => ({
    user: state.auth.user,
    homePageRed: state.homePageRed,
    isDrawerOpen: state.headerRed.isDrawerOpen,
    headerRed: state.headerRed
});

const mapDispatchToProps = dispatch => ({
    getShop: (dispatch) =>
        dispatch(getShop(dispatch)),
    getShopOnboarding: (dispatch) =>
        dispatch(getShopOnboarding(dispatch)),
    requestShopDeployment: (item, dispatch) =>
        dispatch(requestShopDeployment(item, dispatch)),
    getShopEvents: (dispatch) =>
        dispatch(getShopEvents(dispatch)),
    getShopHome: (dispatch) =>
        dispatch(getShopHome(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
