import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getOrders(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/order/`)
            .then((res) => { return dispatch({ type: types.GET_ORDERS, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_ORDERS, payload: error })})
    };
}

function getOrder(order_id, dispatch) {
    dispatch({ type: types.PROCESSING });
    return async function () {
        axiosAPI
            .get(`${url}/order/details/?order_id=${order_id}`)
            .then((res) => { return dispatch({ type: types.GET_ORDER, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_ORDER, payload: error }) })
    };
}

function validateOrder(item, dispatch) {
    dispatch({ type: types.LOADING_ORDER });
    return async function () {
        axiosAPI
            .patch(`${url}/order_validate/${item.id}/`, item)
            .then((res) => { return dispatch({ type: types.VALIDATE_ORDER, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_VALIDATE_ORDER, payload: error.response.data }) })
    };
}

function deliveredOrder(item, dispatch) {
    dispatch({ type: types.LOADING_ORDER });
    return async function () {
        axiosAPI
            .patch(`${url}/order_delivered/${item.id}/`, item)
            .then((res) => { return dispatch({ type: types.DELIVERED_ORDER, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_DELIVERED_ORDER, payload: error.response.data }) })
    };
}

function contactOrderCustomer(item, dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/order_contact_customer/?user_id=${item}`)
            .then((res) => { return dispatch({ type: types.CONTACT_ORDER_CUSTOMER, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_CONTACT_ORDER_CUSTOMER, payload: error.response.data }) })
    };
}

function closeAlertOrder(dispatch) {
    return dispatch({
        type: types.CLOSE_ALERT_ORDER
    })
}

export {
    getOrders,
    getOrder,
    validateOrder,
    deliveredOrder,
    contactOrderCustomer,
    closeAlertOrder
};
