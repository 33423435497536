import { connect } from "react-redux";

import ReviewPage from "../components/ReviewPage";

import { getReview, addReview, closeAlertReview } from '../actions/reviewActions';

const mapStateToProps = state => ({
    user: state.auth,
    reviewRed: state.reviewRed,
    isDrawerOpen: state.headerRed.isDrawerOpen
});

const mapDispatchToProps = dispatch => ({
    getReview: (id, dispatch) =>
        dispatch(getReview(id, dispatch)),
    addReview: (item, dispatch) =>
        dispatch(addReview(item, dispatch)),
    closeAlertReview: (dispatch) =>
        dispatch(closeAlertReview(dispatch)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPage);
