import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import MuiTable from '../utils/MuiTable';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Product(props) {

    const isInitialMount = React.useRef(true);
    const [selectedLabels, setSelectedLabels] = React.useState([]);

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            props.getProducts(props.dispatch);
        }
    }, []);


    const handleCloseAlertProduct = () => {
        props.closeAlertProduct(props.dispatch)
    }

    const handleSelectLabels = (e) => {
        setSelectedLabels(e)
    }
 
    const columns = [
        {
            name: "name",
            label: "Nom",
        },
        {
            name: "description",
            label: "Description",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return `${value.substring(0, 250)}...` 
                },
            }
        },
        {
            name: "stock",
            label: "Stock",
        },
        {
            name: "price",
            label: "Prix",
            options: {
                setCellProps: value => {
                    return {
                        style: {
                            whiteSpace: 'nowrap'
                        },
                    };
                },
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return `${value/100} €`;
                },
                toStringOverride: (rawDataValue) => {
                    return rawDataValue/100
                }
            }
        },
        {
            name: "category",
            label: "Catégorie",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value.name;
                },
                toStringOverride: (rawDataValue) => {
                    return rawDataValue.name
                }
            }
        },
        {
            name: "labels",
            label: "Labels",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<>{value.map((it) => (<Chip key={it.id}  label={it.name} sx={{ mx: '1px', padding: '1px'}} />))}</>);
                },
                toStringOverride: (rawDataValue) => {
                    return rawDataValue.map(l => l.name).join('-')
                }
            }
        },
        {
            name: "unit",
            label: "Unité",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value.name;
                },
                toStringOverride: (rawDataValue) => {
                    return rawDataValue.name
                }
            }
        },
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                download: false,
                setCellProps: () => ({ align: "right" }),
                customHeadRender: () => <th key={8} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <IconButton aria-label="edit" onClick={() => { handleCloseAlertProduct(); props.history.push(`/product/${value}`) }} color="primary">
                        <MoreVertIcon />
                    </IconButton>;
                }
            }
        },]
    

    const options = {
        
    };

    return (
        <Box sx={{ pt: '100px' }} marginLeft={props.isDrawerOpen ? '240px' : '36px'}>
            <Box sx={{
                width: '90%', mx: 'auto', mb: 1, pb: 4
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography variant="h4" color="text.primary">Produits</Typography>
                </Breadcrumbs>
                <Snackbar
                    autoHideDuration={6000}
                    onClose={handleCloseAlertProduct}
                    open={props.productRed.success_add}
                    key={'top' + 'center'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleCloseAlertProduct}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleCloseAlertProduct} severity="success" sx={{ width: '100%', color: '#fff' }}>
                        {props.match.params.product_id !== 'add' ?
                            'Le produit a bien été mis à jour.' :
                            'Le produit a bien été créé.'
                        }
                    </Alert>
                </Snackbar>
                <Box sx={{
                        display: 'flex', justifyContent: 'flex-end', mb: 4, width: '100%', mx: 'auto',
                }}>                
                    <Button
                        onClick={() => { handleCloseAlertProduct(); props.history.push(`/product/add`) }}
                        variant="contained"
                        sx={{ mt: 3, mr: 0,  }}
                    >
                        Ajouter un produit
                    </Button>
                </Box>
                <Paper>
                    <MuiTable
                        data={props.productRed.products}
                        columns={columns}
                        options={options}
                        filename={'BOUTIGOU-Produits.csv'}
                    />
                </Paper>
            </Box>
        </Box>
    );
}

