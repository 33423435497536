import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import logo_login from '../assets/logo.png';
import gif_loading from '../assets/gif_loading.gif';
import { useSelector, useDispatch } from "react-redux";
import { getShop } from '../actions/headerActions';
import {loadStripe} from '@stripe/stripe-js';

const steps = ['Votre boutique', 'Votre adresse', 'Valider'];

export default function BuildSHop(props) {

    const isInitialMount = React.useRef(true);
    const isInitialMountStep1 = React.useRef(true);
    const isInitialMountStep2 = React.useRef(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [category, setCategory] = React.useState('');
    const dispatch = useDispatch();

    React.useEffect(() => {
        props.buildShopGetStripeKey(props.dispatch)
        if (isInitialMount.current) {
            props.getShopCategoryOptions(props.dispatch);
            props.getShop(props.dispatch);
            isInitialMount.current = false;
        }
    }, []);

    React.useEffect(() => {
        if (props.buildShopRed.shop.id) {
            if (props.buildShopRed.shop.address !== null) {
                props.getShopHeader(props.dispatch)
            } else {
                handleNext()
            }
        }
    }, [props.buildShopRed.shop.id]);

    React.useEffect(() => {
        if (isInitialMountStep1.current) {
            isInitialMountStep1.current = false;
        } else {
            if (props.buildShopRed.successStep1 === true) {
                handleNext()
            }
        }
    }, [props.buildShopRed.step1]);

    React.useEffect(() => {
        if (isInitialMountStep2.current) {
            isInitialMountStep2.current = false;
        } else {
            if (props.buildShopRed.successStep2 === true) {
                handleNext()
            }
        }
    }, [props.buildShopRed.step2]);

    React.useEffect(() => {
        if ((typeof (props.headerRed.shop.prestate) !== 'undefined') && props.headerRed.shop.prestate == true) {
            window.location.href = '/'
        }
    }, [props.headerRed.shop])

    const handleEndBuild = () => {
        dispatch(getShop(props.dispatch))
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };


    const handleBuildShop = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const stripe = await loadStripe(props.buildShopRed.stripeKey);
        const accountResult = await stripe.createToken('account', {
            business_type: 'individual',
            company: {
                name: data.get('name'),
            },
            tos_shown_and_accepted: true,
        })
        
        props.buildShopStep1({ name: data.get('name'), siren: data.get('siren'), category: category, stripe_token:  accountResult.token.id}, props.dispatch)
    }

    const handleBuildShopDetails = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        props.buildShopStep2({
            address: data.get('address'),
            address_secondary: data.get('address_secondary'),
            city: data.get('city'),
            zipcode: data.get('zipcode'),
            country: data.get('country'),
            id: props.buildShopRed.shop.id,
            prestate: true,
        }, props.dispatch)
    }
 
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: "center",
            height: '100vh'
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.buildShopRed.loading}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="The house from the offer."
                    src={gif_loading}
                />
            </Backdrop>
            <Paper elevation={3} style={{padding: 20, width: '70%'}}>
            <Typography variant="h4" gutterBottom>
                Plus que quelques étapes...
            </Typography>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit">
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === 0 ?
                    (<Box component="form" onSubmit={handleBuildShop} noValidate sx={{ mt: 1 }}>
                            <Grid container item spacing={2}>
                                <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Nom de la boutique"
                            name="name"
                            helperText={props.buildShopRed.errorsStep1.name ? props.buildShopRed.errorsStep1.name.message : null}
                            error={props.buildShopRed.errorsStep1.name ? true : false}
                            autoFocus
                                    />
                                </Grid>
                                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="siren"
                            name="siren"
                            label="SIRET"
                            helperText={props.buildShopRed.errorsStep1.siren ? props.buildShopRed.errorsStep1.siren.message : null}
                            error={props.buildShopRed.errorsStep1.siren ? true : false}
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required error={props.buildShopRed.errorsStep1.category ? true : false}>
                            <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
                            <Select
                                labelId="category"
                                id="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                fullWidth
                                required
                                error={props.buildShopRed.errorsStep1.category}
                            >
                            {Object.entries(props.buildShopRed.shopCategoryOptions).sort((a, b) => a[1].localeCompare(b[1])).map(([key, value]) =>
                                    (<MenuItem key={key} value={key}>{value}</MenuItem>)
                                )}
                            </Select>
                        {props.buildShopRed.errorsStep1.category ? (<FormHelperText>{props.buildShopRed.errorsStep1.category.message}</FormHelperText>) : null}
                                    </FormControl>
                                </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Suivant
                        </Button>
                    </Box>)
                    : null}

                {activeStep === 1 ?
                    (<Box component="form" noValidate onSubmit={handleBuildShopDetails} sx={{ mt: 3 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="address"
                                    label="Adresse de la boutique"
                                    name="address"
                                    helperText={props.buildShopRed.errorsStep2.address ? props.buildShopRed.errorsStep2.address.message : null}
                                    error={props.buildShopRed.errorsStep2.address ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="address_secondary"
                                    label="Complement"
                                    name="address_secondary"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="zipcode"
                                    required
                                    fullWidth
                                    id="zipcode"
                                    label="Code postal"
                                    helperText={props.buildShopRed.errorsStep2.zipcode ? props.buildShopRed.errorsStep2.zipcode.message : null}
                                    error={props.buildShopRed.errorsStep2.zipcode ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="city"
                                    label="Ville"
                                    name="city"
                                    helperText={props.buildShopRed.errorsStep2.city ? props.buildShopRed.errorsStep2.city.message : null}
                                    error={props.buildShopRed.errorsStep2.city ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="country"
                                    label="Pays"
                                    name="country"
                                    helperText={props.buildShopRed.errorsStep2.country ? props.buildShopRed.errorsStep2.country.message : null}
                                    error={props.buildShopRed.errorsStep2.country ? true : false}
                                />
                            </Grid>  
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Suivant
                        </Button>
                    </Box>
                    )
                    : null}

                {activeStep === 2 ?
                    (<Box noValidate sx={{ mt: 3 }}>
                        <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                            Felicitations
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                                onClick={() => handleEndBuild()}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Suivant
                        </Button>
                    </Box>)
                    : null}
                </div>
            </Paper>
        </Box>
    );
}