import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import logo_login from '../../assets/logo.png';
import gif_loading from '../../assets/gif_loading.gif';

export default function Validate(props) {
    const [code, setCode] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [step, setStep] = React.useState(1)

    React.useEffect(() => {
        if (props.auth.validated === true) {
            props.whoAmI(localStorage.getItem("access_token"), props.dispatch);
            props.history.push('/build_shop')
        }
    }, [props.auth.validated])

    React.useEffect(() => {
        if ((props.auth.processingValidate === false) && (!props.auth.validate_errors.email) && (step === 2)) {
            setStep(1);
            setCode(null);
            setEmail(null)
        }
    }, [props.auth.processingValidate])

    const handleSubmit = () => {
        if (step === 1) {
            const data = {
                'token': props.auth.validate.token,
                'code': code
            };
            props.postValidateAction(data, props.dispatch);
        } else if (step === 2) {
            const data = {
                'email': email
            };
            props.resendValidateAction(data, props.dispatch);
        }
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
            maxWidth="xs"
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.auth.processingValidate}
            >
                <Box
                    component="img"
                    sx={{
                        height: 250,
                        mb: 4
                    }}
                    alt="The house from the offer."
                    src={gif_loading}
                />
            </Backdrop>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: 133,
                        mb: 4
                    }}
                    alt="The house from the offer."
                    src={logo_login}
                />
                <Typography component="h1" variant="h5" sx={{ flexGrow: 1, }} align='center' >
                    {step === 1 ? 'Renseignez le code reçu par mail pour valider votre compte' : "Renseignez l'email associé à votre compte"}
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        {step === 1 ?
                            <TextField
                                required
                                fullWidth
                                id="code"
                                label="Code de validation"
                                name="code"
                                autoComplete="code"
                                value={code || ''}
                                onChange={(e) => setCode(e.target.value)}
                                helperText={props.auth.validate_errors.code ? props.auth.validate_errors.code.message : null}
                                error={props.auth.validate_errors.code ? true : false}
                            /> : 
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                value={email || ''}
                                onChange={(e) => setEmail(e.target.value)}
                                helperText={props.auth.validate_errors.email ? props.auth.validate_errors.email.message : null}
                                error={props.auth.validate_errors.email ? true : false}
                            />
                        }
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                        <Button
                            fullWidth
                            href='/login'
                            variant="outlined"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Retour
                        </Button>
                        <Button
                            fullWidth
                            onClick={() => handleSubmit() }
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                        >
                            {step === 1 ? 'Valider' : 'Renvoyer le code' }
                        </Button>
                    </Box>
                    <Grid container justifyContent="center">
                        { step === 1 ?
                        <Grid item>
                            <Link onClick={() => setStep(2)} variant="body2" component="button">
                                Renvoyer le code? Cliquez ici
                            </Link>
                        </Grid> : null }
                    </Grid>
                </Box>
            </Box>
        </Grid>
    );
}