import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import passwordResetReducer from "./auth/passwordResetReducer";
import buildShopReducer from "./buildShopReducer";
import homePageReducer from "./homePageReducer";
import shopReducer from "./shopReducer";
import shopDeliveryOptionReducer from "./shopDeliveryOptionReducer";
import shopPartnerReducer from "./shopPartnerReducer";
import productReducer from "./productReducer";
import reviewReducer from "./reviewReducer";
import messageReducer from "./messageReducer";
import subscriptionReducer from "./subscriptionReducer";
import accountReducer from "./accountReducer";
import headerReducer from "./headerReducer";
import orderReducer from "./orderReducer";
import profileReducer from "./profileReducer";
import termsReducer from "./termsReducer";
import shopActivityReducer from "./shopActivityReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    passwordReset: passwordResetReducer,
    buildShopRed: buildShopReducer,
    homePageRed: homePageReducer,
    shopRed: shopReducer,
    shopDeliveryOptionRed: shopDeliveryOptionReducer,
    shopPartnerRed: shopPartnerReducer,
    productRed: productReducer,
    reviewRed: reviewReducer,
    messageRed: messageReducer,
    subscriptionRed: subscriptionReducer,
    accountRed: accountReducer,
    headerRed: headerReducer,
    orderRed: orderReducer,
    profileRed: profileReducer,
    terms: termsReducer,
    shopActivity: shopActivityReducer
});

export default rootReducer;
