import * as types from "../types/actionTypes";
import { backendUrl } from "./backendUrl";

import axiosAPI from "../components/api/axiosApi";

let url = process.env.REACT_APP_DEV_URL || backendUrl;

function getCGU(dispatch) {
    return async function () {
        axiosAPI
            .get(`${url}/cgu/`)
            .then((res) => { return dispatch({ type: types.GET_CGU, payload: res.data }) })
            .catch((error) => { return dispatch({ type: types.FAILED_GET_CGU, payload: error.response.data }) })
    };
}

export {
    getCGU
};
